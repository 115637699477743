$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-game-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 50px 0;
  position: relative;

  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 12px;
    margin-top: 16px;

    & > * {
      margin: 0 8px;
    }
  }

  &__btn {
    position: absolute;
    top: 4px;
    right: 20px;
    height: 40px;
    display: flex;
    align-items: center;

    .rtl-lang & {
      right: unset;
      left: 20px;
    }
  }

  .link-back {
    position: absolute;
    top: 0;
    left: 20px;

    .rtl-lang & {
      left: unset;
      right: 20px;
    }
  }

  @include breakpoint( $bp1 ){
    &__tags {
      margin-top: 40px;
      padding: 0 10px;
      & > * {
        margin: 0 10px;
      }
    }
  }

}
