$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-address{
    font-family: var(--font-family-main);
    margin-top: 8px;
    @include text(14px, 20px, 400, $black);
    text-align: center;

    @include breakpoint($bp1){
        margin-top: 12px;
    }
}
