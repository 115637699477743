$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-tab {

  position: relative;

  font-family: var(--font-family-main);
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(134, 138, 146, 0.5);
  width: 108px;
  display: block;
  text-align: center;
  height: 56px;
  padding: 16px 4px 12px;

  @include ellipsis;

  @include hover(){
    color: $grey;
  }

  &.is-active {
    color: $white;
    background: #2D2D32;
    border-radius: 12px 12px 0 0;
  }

}
