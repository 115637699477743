$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile {
  &__bonus-tab {
    display: flex;
    align-items: center;

    span {
      @include ellipsis;
      @include text(9px, 12px, bold, $black);

      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $yellow;
      text-align: center;
      flex-shrink: 0;
      margin-left: 8px;

      .rtl-lang & {
        margin-left: unset;
        margin-right: 8px;
      }
    }
  }
}
