$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-to-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $orange;
  box-shadow: inset -1px -2px 6px rgba($yellow, 0.6), inset 1px 2px 10px rgba($yellow, 0.8);
  position: relative;
  display: none;

  &.is-active {
    display: inline-block;
  }

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: var(--icon-arrow-up);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    filter: brightness(2);
  }
}
