$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-bet{
    background-color: $lightGrey;
    padding: 0 20px 16px;
    border-radius: 12px;
    overflow: hidden;
    &.has-bets{
        border-radius: 0;
        margin: 0 -8px;
        width: 100vw;
    }
    &__title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .profile-title{
            margin-right: 8px;
        }
        a{
            flex-shrink: 0;
        }
    }

    .layout-horiz{
        @include shadow-before-after(20px, 100%, 246, 246, 244);
        &::before{  left: -20px;}
        &::after{right: -20px;}
    }
    .layout-horiz &__scroll {
        margin: -12px -20px  0;
        max-width: initial;
        width: calc(100% + 40px);
        padding: 12px 16px;
    }

    @include breakpoint($bp1){
        &.has-bets{
            border-radius: 12px;
            margin: 0;
            width: 100%;
        }
    }
}

