$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-featured{
    width: 182px;
    height: 66px;
    overflow: hidden;
    padding-left: 90px;

    background-size: 66px;
    background-position: left 16px center; 
    background-repeat: no-repeat; 
    &:not(:last-child){
        margin-right: 2px;
    }
    span{
        transition: .2s ease;
        height: inherit;
        display: inline-flex;
        align-items: center;
        @include text(14px, 28px, 700, $white);
        white-space: normal
    }
    @include hover{
        span{
            color: $yellow;
        }
    }
}