$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-bonus {
  width: 280px;
  height: 187px;

  border: 4px solid $yellow;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.is-new {
    border-color: $red;
  }

  &__cover {
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: 0;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background-image: linear-gradient(180deg, #2D2D32 0%, rgba(45, 45, 50, 0.6) 100%), url($cdn + '/fezbet/bonuses/casino.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(4px);
    &.is-sport {
      background-image: linear-gradient(180deg, #2D2D32 0%, rgba(45, 45, 50, 0.6) 100%), url($cdn + '/fezbet/bonuses/sport.jpg');
    }
  }

  &__label {
    background-color: $yellow;
    transform: rotate(-45deg);
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    left: -34px;
    top: 14px;
    padding: 0 30px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.is-new {
      background-color: $red;
      color: $white;
    }

    .rtl-lang & {
      transform: rotate(45deg);
      left: unset;
      right: -34px;
    }
  }

  &__icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;

    &.is-coin {
      background-size: contain;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    }
  }

  &__amount {
    position: relative;
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    color: $yellow;
    margin: 0 0 16px;
    text-align: center;
  }

  &__name {
    position: relative;
    line-height: 24px;
    color: $white;

    a {
      color: $yellow;
      text-decoration: underline;
      @include hover(){
        opacity: .85;
      }
    }
  }
}

.autobonus-btns {
  max-width: 280px;
  margin-bottom: 36px;
  padding-top: 18px;
  text-align: center;

  &__wrap {
    margin-top: 12px;
    text-align: center;
    @include text(14px, 20px, normal, $grey);
  }

  &__btn {
    min-height: 40px;
    height: auto;
    padding-top: 9px;
    padding-bottom: 9px;
    line-height: 122%;
    white-space: initial;
    text-decoration: none;
  }

  &__link {
    display: block;
    margin-top: 6px;
    text-decoration: underline;
    color: $grey;
  }
}

.popup__content {
  .popup__item {
    .normal-title {
      display: none;
    }

    &:first-child {
      .normal-title {
        display: block;
      }
    }

    &:not(:last-child) {
      &:after {
        display: block;
        width: 100%;
        height: 20px;
        margin: 10px 0 14px;
        color: #2D2D32;
        content: '+';
        text-align: center;
        font-weight: 700;
        font-size: 30px;
      }

      .autobonus-btns {
        margin-bottom: 0;
      }

      .autobonus-btns__wrap {
        display: none;
      }
    }
  }
}
