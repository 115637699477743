$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.modal-body-trustly {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100%;
  background: $lightGrey;
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  color: $black; 

  &__title {
    background-color: $yellow;
    @include text(24px, 24px, bold, $black);
    font-family: var(--font-family-main);
    text-align: center;
    padding: 8px 20px;
  }
  &__description {
    text-align: center;
    padding: 0 20px;
  }
  &__form {
    padding: 16px 20px 32px;
    .form-item {
      margin-bottom: 20px;
    }
    .btn {
      margin: 16px auto 0;
      display: block;
    }
  }
  @include breakpoint( $bp1) {
    &__title {
      padding: 10px 20px;
      font-size: 32px;
      line-height: 44px;
    }
    &__description {
      padding: 0 130px;
    }
    &__form {
      padding: 16px 130px 32px;
    }
  }
  @include breakpoint( $bp2 ){    
    width: 676px;
    margin: 40px auto;
    border-radius: 20px;
    &__title {
      border-radius: 20px 20px 0px 0px;
      padding: 10px 48px;
    }
    &__description {
      padding: 0 48px;
    }
    &__form {
      padding: 16px 48px 40px;
      .form-radio {
        width: 50%;
      }
    }
    &__form-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
    }
  }
}
