$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-empty{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    text-align: center;
    padding: 0 25px 22px;
    background-repeat: no-repeat;

    @include breakpoint($bp1) {
        height: 100%;
    }

    &__title.profile-title{
        color: #fff;
    }
    &__text{
        margin: 4px 0 16px;
        @include text(24px, 32px, 700, $yellow);
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-family: var(--font-family-main);
        &.is-big{
            @include text(36px, 44px);
        }
    }

    &.is-biggest-win{
        background-image: url( $cdn + '/fezbet/elements/latest_win.jpg');
        background-position: bottom center;
        background-size: cover;
        margin-bottom: 12px;
    }

    &.is-latest-win{
        background-image: url($cdn + '/fezbet/elements/latest_win.jpg');
        background-size: cover;
        background-position: center;
        height: auto;
    }

    &.is-promos{
        background-image: url($cdn + '/fezbet/bonuses/promo.png'), linear-gradient(90deg,rgba(146,66,21,.7) 0,rgba(0,0,0,.7) 100%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: inset 0 0 0 4px rgba(255, 220, 38, 0.5);
        border-radius: 12px;
        padding: 0 16px 40px;
    }
}
