$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-category{
    height: 52px;
    width: 100%;
    background-size: 32px;
    background-position: left 16px center; 
    background-repeat: no-repeat; 

    padding: 0 40px 0 56px;
    @include text(15px, 52px, 700, $grey);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include ellipsis;
    transition: .2s ease;
    span{
        display: inline-block;
        @include ellipsis;
        color: #ffffff; 
    }

    &::before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 14px;
        width: 20px;
        height: 20px;
        background-image: var(--icon-arrow);
        background-size: contain;
        background-repeat: no-repeat; 
        filter: $filterGrey;
        transition: .2s ease; 
        transform: rotate(90deg);
    }

    @include hover(){
        color: $yellow;
        span{  color: $yellow; }
        &::before{   filter: $filterYellow; }    
    }
}