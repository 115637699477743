$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.table-row {

  @extend .table-title;

  color: $black !important;
  padding: 14px 5px !important;
  margin: 0 4px !important;
  border-radius: 8px;
  position: relative;
  flex-wrap: wrap;

  &:nth-child( 2n ){
    background-color: $white;
  }

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    right: 8px;
    top: calc(50% - 8px);
    background: var(--icon-arrow-dark) no-repeat center;
    background-size: 16px;
    filter: $filterGrey;
    transition: background-image .3s;
  }

  &:lang(ar) {
    &::after {
      right: unset;
      left: 8px;
    }
  }

  &.is-opened {
    &:after {
      background-image: var(--icon-arrow);
    }
  }

  @include hover(){
    opacity: .85;
  }

  @include breakpoint( $bp1 ){
    margin: 0 12px !important;
  }

}
