$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-pays {
    padding-top: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    margin: 0 auto 16px;
    width: 100%;
    max-width: 280px;

    @include breakpoint($bp1) {
        padding-top: 12px;
        max-width: 100%;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 20px;
    }

    &._crypto {
        display: block;

        @include breakpoint($bp1) {
            max-width: 320px;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $lightGrey;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05), inset 0 8px 10px rgba(255, 255, 255, 0.8), inset 0 -6px 8px rgba(195, 195, 195, 0.5), inset 0 1px 1px rgba(195, 195, 195, 0.2);
        border-radius: 12px;
        padding: 12px;
        text-align: center;
        text-decoration: none;
        transition: .2s ease;

        @include hover() {
            box-shadow: none;
        }

        img {
            display: inline-block;
            width: 110px;
            height: 30px;
            @include text(10px, 30px, 400, $black);
            @include ellipsis;
        }

        &-data {
            letter-spacing: 0.02em;
            text-align: left;

            .rtl-lang & {
                text-align: right;
            }
        }

        &-name {
            @include text(14px, 16px, 700, $black);
            margin-bottom: 8px;
        }

        &-descr {
            list-style: none;
            padding: 0;

            li {
                @include text(12px, 16px, 400, $grey);
            }
        }
    
        &._crypto {
            justify-content: flex-start;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                flex-shrink: 0;
                height: auto;
                margin-right: 12px;

                .rtl-lang & {
                    margin-right: unset;
                    margin-left: 12px;
                }
            }
        }
    }

    &__nopay {
        padding: 100px 0;
        max-width: 465px;
        text-align: center;
        margin: 0 auto;

        &-title {
            @include text(24px, 120%, 700, $black);
            text-transform: uppercase;
            margin-bottom: 12px;
        }

        &-text {
            @include text(14px, 20px, 400, $black);
            margin-bottom: 12px;
        }

        &-btns {
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn {
                width: 225px;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__back {
        position: absolute;
        top: 0;
        left: 20px;
        z-index: 1;

        @include breakpoint($bp1) {
            top: 12px;
        }
    }
}
