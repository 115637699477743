$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-item {
    display: block;
    position: relative;
    z-index: 1;
    width: 120px;
    height: 180px;
    box-shadow: 0 2px 4px rgba(25, 25, 29, 0.3);
    border-radius: 12px;
    overflow: hidden;
    transition: 0.2s ease;
    margin:  0 4px;
    background-color: rgb(57, 56, 62);

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include text(14px, 80px, 700, #fff);
    }

    &.icon-new__before::before{
        content: '';
        display: block;
        position: absolute;
        left: 4px;
        z-index: 2;
        bottom: 4px;
        width: 21px;
        height: 21px;
        background-size: 15px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $red;
        box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.3), inset 0 -2px 3px rgba(60, 18, 5, 0.4);
        border-radius: 50%;
    }
    &.icon-dropAndWins__before::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        width: 52px;
        height: 47px;
        top: 0;
        left: 0;
        background-image: var(--icon-drops-wins);
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.icon-exclusive__before::before{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 61px;
        height: 61px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 2;
    }

    @include hover(){
        opacity: 0.5;
    }
    .is-grid & {
        width: 100%;
        height: 100%;
        margin: 0;
        padding-bottom: 150%;
        img{
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .layout-horiz & {
        &:last-child {
            margin-right: 16px;

            .rtl-lang & {
                margin-right: 4px;
                margin-left: 16px;
            }
        }

        &:first-child {
            .rtl-lang & {
                margin-right: 16px;
            }
        }
    }
    .widget-tournament & {
        width: 104px;
        height: 146px;
        margin-right: 12px;
    }
    @include breakpoint($bp2) {
        .layout-horiz & {
            &:last-child { margin-right: 20px; }
        }
    }
}
