$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-orange {
  @extend .btn-base; 
    
  background-image: linear-gradient(180deg, #FFC440 0%, #DD251A 81.77%);
  --shadow: inset 0px 6px 8px rgba(255, 246, 215, 0.6), inset 0px -6px 10px rgba(40, 13, 2, 0.5);
  color: $white;
  text-shadow: 0px 1px 1px rgba(123, 1, 1, 0.79);
  &:not([disabled]):not(.is-disabled){
    @include hover(){
      color: rgba(255, 255, 255,.5);
      background-image: linear-gradient(180deg, #E64E24 0%, #F9A93A 100%);
      --shadow: none;
      text-shadow: none;
    }
  }
}