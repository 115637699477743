$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.santa-banner-wrapper {
  padding: 0 14px;
  margin: 16px 0;
  display: block;
}

.santa-banner {
  height: 200px;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border-radius: 12px;
  border: 2px solid rgba(207, 30, 30, 0.70);
  box-shadow: 0px -8px 10px 0px rgba(207, 30, 30, 0.40) inset, 0px 8px 10px 0px rgba(255, 255, 255, 0.30) inset;
  background: url("/joxi/fezbet/santa-slope/santa-promo-banner-bg-mob.webp");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  padding: 16px;
  z-index: 20;

  @include breakpoint($bp1) {
    height: 170px;
    align-items: flex-end;
    padding: 20px;
    padding-right: 30px;
    justify-content: center;
  }

  @include breakpoint($bp2) {
    height: 200px;
    padding-right: 70px;
  }

  @include breakpoint($bp3) {
    padding-right: 100px;
  }

  &__images {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__gift-blue,
  &__gift-pink,
  &__gift-purpule {
    position: absolute;
  }

  &__santa-image {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 282px;
    height: 105px;
    background-image: url("/joxi/fezbet/santa-slope/santa-promo-mob-1.webp");
    background-size: contain;

    @include breakpoint($bp1) {
      mask-image: unset;
      width: 338px;
      height: 292px;
      inset-inline-start: -125px;
      top: 0;
      transform: unset;
      background-image: url("/joxi/fezbet/santa-slope/santa-promo-mob-2.webp");
    }

    @include breakpoint($bp2) {
      top: 4px;
      background-image: url("/joxi/fezbet/santa-slope/santa-promo-mob-3.webp");
      width: 448px;
      height: 298px;
      inset-inline-start: -117px;
    }

    @include breakpoint($bp3) {
      background-image: url("/joxi/fezbet/santa-slope/santa-promo-mob-4.webp");
      width: 585px;
      height: 339px;
      inset-inline-start: -54px;
      top: -21px;
    }
  }

  &__cta {
    margin-top: 12px;
    min-width: 150px;

    @include breakpoint($bp1) {
      margin-top: 16px;
    }

    @include breakpoint($bp2) {
      min-width: 178px;
    }

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: block;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include breakpoint($bp1) {
    }
  }

  &__title {
    color: $yellow;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 2px;

    @include breakpoint($bp1) {
      font-size: 24px;
    }

    @include breakpoint($bp2) {
      font-size: 28px;
    }

    @include breakpoint($bp3) {
      font-size: 30px;
    }
  }

  &__rules {
    font-size: 22px;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;

    @include breakpoint($bp1) {
      font-size: 30px;
    }

    @include breakpoint($bp2) {
      font-size: 38px;
    }

    @include breakpoint($bp3) {
      font-size: 40px;
    }
  }


  &:lang(ar) {
    @include breakpoint($bp1) {
      .santa-banner__santa-image {
        transform: scaleX(-1);
      }
    }
  }
}

