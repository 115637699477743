$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-events {
    padding-top: 14px;

    .is-euro2024-open & {
        padding-top: 8px;
    }

    .rtl-lang & {
        direction: ltr;
    }

    &__item {
        width: 260px;
        height: 120px;
        background: $white;
        border-radius: 16px;
        padding: 8px 12px 12px;
        margin-right: 12px;
        position: relative;
        z-index: 3;
        &.is-live {
            &:after {
                content: '';
                position: absolute;
                top: -4px;
                right: -4px;
                width: 28px;
                height: 28px;
                background-color: $red;
                background-image: var(--icon-sport-live-i), var(--icon-sport-live);
                background-repeat: no-repeat;
                background-position: center 6px,center 15px;
                box-shadow: inset 0px 4.125px 5.5px rgba(255, 255, 255, 0.3), inset 0px -4.125px 5.5px rgba(60, 18, 5, 0.4);
                border-radius: 50%;
            }
        }
        &:first-child {
            margin-left: 14px;
        }
    }
    &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
    }
    &__logo {
        width: 40px;
        height: 40px;
        background-color: $lightGrey;
        border: 1px solid $grey;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: center;        
    }
    &__timing {
        display: flex;
        align-items: center;
        justify-content: center;     
        margin-bottom: 2px;
    }
    &__time {
        @include text(20px, 24px, bold, $black);
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        @include ellipsis;
        margin-right: 8px;
        max-width: 50px;
        .is-live & {
            color: $red;
        }
    }
    &__date {
        @include text(12px, 20px, normal, $grey);
        letter-spacing: 0.03em;
        @include ellipsis;
        max-width: 90px;
    }
    &__champ {
        @include text(11px, 12px, normal, $grey);
        @include ellipsis;
        max-width: 140px;
    }
    &__teams {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include text(12px, 16px, bold, $black);
        letter-spacing: 0.03em;
        margin-bottom: 8px;
    }
    &__team-val {
        @include text(12px, 16px, bold, $red);
        letter-spacing: 0.03em;
        &:nth-child(1) {
            color: $green;
        }
    }
    &__team-name {        
        @include ellipsis;
        max-width: calc(100% - 20px);
    }
    &__rate {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__coef {
        background: $lightGrey;
        border-radius: 8px;
        width: 76px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include text(12px, 20px, normal);
        cursor:pointer;
        & span { margin-right:10px; }        
        &:nth-child(1) {
            border: 1px solid $green;
            color: $green;
            @include hover() { box-shadow: inset 0 0 0 1px $green; }
        }
          &:nth-child(2) {
            border: 1px solid $grey;
            color: $grey;
            span {
                font-size: 9px;
            }
            @include hover() { box-shadow: inset 0 0 0 1px $grey; }
        }
          &:nth-child(3) {
            border: 1px solid $red;
            color: $red;
            @include hover() { box-shadow: inset 0 0 0 1px $red; }
        }
    }

    @include breakpoint($bp1) {
        &__item:last-child {
            .is-euro2024-open & {
                margin-right: 24px;
            }
        }

        &__item:first-child {
            .is-euro2024-open & {
                margin-left: 24px;
            }
        }
    }
}
