$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.screen {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 $toolbarHeight;
  position: relative;
  z-index: 1;
  transition: transform .3s;

  linda-footer {
    margin-top: auto;
  }

  body.has-left-menu & {
    transform: translateX($menuWidth);
  }

  body.rtl-lang.has-left-menu & {
    transform: translateX(-$menuWidth);
  }

  body.rtl-lang.has-right-menu & {
    transform: translateX($menuWidth);
  }

  body.has-right-menu & {
    transform: translateX(-$menuWidth);
  }
}
