$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.verification-upload {
    margin: 12px 0;
    background-color: rgba(140, 135, 179, 0.08);
    border: 1px dashed #8c87b3;
    text-align: center;
    padding: 14px;
    display: flex; 
    justify-content: center;

    &__content {
        padding-left: 56px; 
        background-image: url('/img/upload.svg');
        background-size: 48px;
        background-repeat: no-repeat;
        background-position: left center;
    }

    &__label {
        margin-bottom: 6px;
        display: block;
        color: #8c87b3;
        @include text(18px, 24px, 400, #3586ff);
        text-decoration: underline;
    }

    &__file-types {
        @include text(14px, 20px, 400, #8c87b3);
    }

    &.is-disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}
