$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-form {
  background: $darkGrey;
  border-radius: 20px;
  padding: 20px 16px 0;
  min-height: 500px;
  background-image: url($cdn + '/fezbet/main/reg.png');
  background-size: 280px auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  &.is-trustly { padding: 18px 12px 0; }
  &__title {
    @include text(32px, 100%, bold, $yellow);
    font-family: var(--font-family-main);
    text-align: center;
    text-shadow: 0px 4px 15px rgba(40, 39, 42, 0.1);
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;    
  }
  &__icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-top: 16px;
  }
  &__icon {
    width: 88px;
    height: 24px;
    margin: 0 4px 8px;
    img{object-fit: contain;}
  }
  @include breakpoint($bp1) {
    padding: 20px 28px 24px;
    min-height: auto;
    display: flex;
    justify-content: flex-end;
    background-size: 553px auto;
    background-position: left -361px bottom;
    &.is-trustly { padding: 20px 28px 18px; }
    &__wrap {      
      width: 280px;
    }
    &__form {
      width: 280px;
      margin: 0 auto;
    }
  }
  @include breakpoint($bp2) {
    background-position: left -189px bottom;
    &__wrap {      
      width: 326px;
    }
  }
  @include breakpoint($bp3) {
    background-position: left -59px bottom;
    &__wrap {      
      width: 456px;
    }
    &__icons {
      width: 296px;
      margin: 16px auto 0;
    }
  }
}
