$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-link{
    display: block;
    margin: 0 auto;
    height: 48px;
    width: fit-content;
    max-width: 100%;
    @include ellipsis;
    @include text(14px, 48px, 400, $black);
    text-decoration: underline;
    transition: .2s ease;
    @include hover(){
        color: $grey;
    }
}