$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-random-game{
    position: absolute;
    z-index: -2;
    width: 200px;
    height: 300px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}
