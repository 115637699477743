$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-text {
    @include text(14px, 20px, 400, $black);
    text-align: center; 
    letter-spacing: 0.03em;

    &._crypto {
        margin: 20px 0 16px;
    }

    &.is-lim {
        margin-top: 12px;
        font-weight: 700;
        color: $grey;

        span {
            display: inline-flex;
        }
    }

    &.has-icon {
        max-width: 280px;
        display: inline-block;
        padding-left: 22px;
        background-image: var(--icon-withdraw-card);
        background-size: 20px;
        background-position: top left;
        background-repeat: no-repeat;
    }

    .is-red {
        color: $red;
    }

    &__payment-cop {
        color: #868a92;
        font-weight: 700;
        letter-spacing: .03em;
        padding-top: 16px;
        text-align: center;
    }
}
