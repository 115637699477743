$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
:host {
  display: block;
  height: 100%;
}

.profile-bonus {

  border-radius: 12px;
  background-color: $lightGrey;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__box {

    width: 100%;
    min-height: 182px;

    border: 4px solid $yellow;
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    flex-grow: 1;

    &.is-new {
      border-color: $red;
    }

  }

  &__cover {
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: 0;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background-image: linear-gradient(180deg, #2D2D32 0%, rgba(45, 45, 50, 0.6) 100%), url($cdn + '/fezbet/bonuses/casino.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(4px);
    &.is-sport {
      background-image: linear-gradient(180deg, #2D2D32 0%, rgba(45, 45, 50, 0.6) 100%), url($cdn + '/fezbet/bonuses/sport.jpg');
    }
  }

  &__label {
    background-color: $yellow;
    color: $black;
    transform: rotate(-45deg);
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    left: -34px;
    top: 14px;
    padding: 0 30px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;

    .rtl-lang & {
      transform: rotate(45deg);
      left: unset;
      right: -34px;
    }

    [lang="it"] & {
      line-height: 1.25;
      width: 136px;
    }

    &.is-new {
      background-color: $red;
      color: $white;
    }
  }

  &__icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px;

    &.is-coin {
      background-size: contain;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    }

    .rtl-lang & {
      right: unset;
      left: 16px;
    }
  }

  &__amount {

    position: relative;
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: $yellow;
    margin: 28px 0 8px;
    text-align: center;
    padding: 0 20px;

    &.is-coin {
      text-transform: capitalize;
    }

    ._arabic {
      display: grid;
      grid-template-columns: auto auto;

      &._ltr {
        direction: ltr;
      }
      
    }

  }

  &__name {
    text-transform: capitalize;
    position: relative;
    line-height: 24px;
    color: $white;
    margin-bottom: 14px;

    a {
      color: $yellow;
      text-decoration: underline;
      @include hover(){
        opacity: .85;
      }
    }

  }

  &__btns {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  &__body {

    margin-top: 4px;

    & > div {
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      padding: 10px 0 11px;
      font-size: 12px;
      span {
        color: $black;
      }
      strong {
        color: $grey;

        &._rollover {
          display: flex;
          font-weight: 400;
        }
      }
    }

  }

  @include breakpoint( $bp1 ){
    .has-dashboard-bonus & {
      display: flex;
      &__box {
        width: calc(50% - 6px);
        margin-right: 12px;
      }
      &__body {
        width: calc(50% - 6px);
      }
    }
  }
  @include breakpoint( $bp3 ){
    .has-dashboard-bonus & {
      display: block;
      &__box {
        width: 100%;
        margin-right: 0;
      }
      &__body {
        width: 100%;
      }
    }
  }

}
