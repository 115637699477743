$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu {
  width: $menuWidth;
  height: 100%;
  background-color: $darkGrey;
  padding-bottom: $toolbarHeight;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-$menuWidth);
  z-index: 98;
  transition: transform .3s;

  .rtl-lang & {
    left: unset;
    right: 0;
    transform: translateX($menuWidth);
  }

  &.is-right {
    left: auto;
    right: 0;
    transform: translateX($menuWidth);

    .rtl-lang & {
      left: 0;
      right: unset;
      transform: translateX(-$menuWidth);
    }

    .has-right-menu.rtl-lang & {
      transform: translateX(0);
    }
  }
}

.has-left-menu :not(.is-right) {
  transform: translateX(0);
}

.has-right-menu .menu.is-right {
  transform: translateX(0);

  .rtl-lang & {
    transform: translateX(0);
  }
}
