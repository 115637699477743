$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-providers {
    @include shadow-before-after();

    position: relative;
    border-bottom: 1px solid $black;
    padding: 8px 0 4px;

    &__list {
        height: 156px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    &__item {
        @include text(14px, 52px, 400, $white);
        @include ellipsis;

        width: 180px;
        height: 52px;
        position: relative;
        padding-left: 57px;
        background-size: 32px;
        background-position: 17px center;
        background-repeat: no-repeat;
        transition: .2s;

        @include hover() {
            color: $yellow;
        }

        &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            right: 1px;
            top: 0;      
        }

        &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            right: 0;
            top: 0;
        }

        .rtl-lang & {
            padding-left: unset;
            padding-right: 57px;
            background-position: right 16px center;
        }

        &:nth-of-type(3n+1) {
            &:after {
                background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
            }
            &:before {        
                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
            }
        }
        &:nth-of-type(3n+2) {
            &:after {
                background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
            }
            &:before {        
                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
            }
        }
        &:nth-of-type(3n+3) {
            &:after {
                background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
            }
            &:before {        
                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
            }
        }

       
            // background-image: linear-gradient(to left, #000, #333 50%, #eee 75%, #333 75%);
            // background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%),
            // linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
            // background-size: 1px 100%;
            // background-position: right 180px; 
            // background-repeat: repeat-x;
        
    }
}
