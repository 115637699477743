$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.icon-fav{
    @include hover(){
        background-image: var(--icon-fav-tap);
    }
    &.is-added{
        background-image: var(--icon-fav-rmv);
        @include hover(){
            background-image: var(--icon-fav-rmv-tap);
        }
    }
}