$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-paynplay-widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $darkGrey;
    padding: 9px 0 16px 0;

    &__title-wrap {
        display: flex;
        padding: 0 40px;
        justify-content: space-between;
        width: 100%;
    }

    &__title {
        @include text(16px, 24px, bold, $white);
        text-align: center;
        margin: auto;
        margin-bottom: 11px;
        display: flex;
    }
    
    &__image {
        width: 20px;
        height: 20px;
        background: var(--icon-zimpler);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 32px;
    }

    &__button {
        width: fit-content;
        max-width: 170px;
        margin-top: 19px;
    }

    @include breakpoint($bp1) {
        padding: 12px 0 19px 0;
        &__form {
            margin-bottom: 0;
            flex-direction: row;
        }
        &__title {
            margin-bottom: 12px;
        }
        &__button {
            margin-top: 0;
            margin-left: 20px;
        }
    }

    @include breakpoint($bp2) {
        flex-direction: row;
        padding: 12px 21px;
        &__title-wrap {
            padding: 0;
            width: fit-content;
        }
        &__title {
            margin-bottom: 0;
        }
        &__image {
            margin-right: 12px;
        }
        &__form {
            padding: 0 21px 0 12px;
        }
        &__button {
            margin-left: 12px;
        }
    }
}