$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.modal-fade {

  @extend .popup-fade;

}

.linda-has-modal .modal-fade {
  z-index: 10000;
}
