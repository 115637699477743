$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-deposit {
    padding: 4px 24px;
    .btn-orange,
    .btn-stroke {
        width: 100%;
    }
    .btn {
        margin-bottom: 12px;
    }
}