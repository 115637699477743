$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.providers {
  &__container {
    display: flex!important;
    flex-direction: column;
    flex-wrap: wrap;
    height: 156px;
  }
  &__item {
    display: block!important;
    width: 182px;
    height: 52px;
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: left 16px center;
    position: relative;
    padding: 0 10px 0 56px;
    @include text(14px, 52px, normal, $white);
    @include ellipsis;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      right: 1px;
      top: 0;      
    }
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      right: 0px;
      top: 0;
    }
    &:nth-of-type(3n+1) {
      &:after {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
      }
      &:before {        
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
      }
    }
    &:nth-of-type(3n+2) {
      &:after {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
      }
      &:before {        
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
      }
    }
    &:nth-of-type(3n+3) {
      &:after {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      }
      &:before {        
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
    @include hover() { color: $yellow; }
  }
}