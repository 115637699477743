$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-title{
    font-family: var(--font-family-main);
    @include text(16px, 20px, 700, $black);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    overflow: hidden;
    padding: 16px 0 12px;

    .is-promos & {
        @include text(20px, 24px);
    }
}
