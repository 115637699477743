$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-item {
    @include text(16px, 20px, normal, $white);
    @include ellipsis;

    position: relative;
    display: block;
    height: 50px;
    padding: 14px 44px 16px 60px;
    font-family: var(--font-family-main);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
    background-position: left 16px bottom 1px, left 16px bottom, right 16px top 16px;
    background-repeat: no-repeat;
    background-size: calc(100% - 32px) 1px, calc(100% - 32px) 1px, 16px 16px;
    transition: all .2s;

    &.has-child {
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%), var(--icon-arrow-down);
        transition: all .2s;

        .rtl-lang & {
            background-position: left 16px bottom 1px, left 16px bottom, left 16px top 16px;
        }
    }

    &.is-opened {
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%), var(--icon-arrow-up);
        color: $yellow;
        transition: all .2s;
        & + .menu-categories__list {
            display: block;
        }
    }

    &.is-active {
        color: $yellow;
        & + .menu-categories__list {
            display: block;
        }
        &.has-child {
            background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%), var(--icon-arrow-up);
            transition: all .2s;
        }
    }

    &::before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: var(--icon-menu-casino_1); 
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%); 
        transition: background-image .3s;
    }

    .rtl-lang & {
        padding: 14px 60px 16px 44px;

        &::before {
            left: unset;
            right: 24px;
        }
    }

    @include hover() {
        width: calc(100% + 12px);
        padding: 14px 36px 16px 60px;
        background-position: left 16px bottom 1px, left 16px bottom, right 32px top 16px;
        background-size: calc(100% - 48px) 1px, calc(100% - 48px) 1px, 16px 16px;
        transform: translateX(-12px);
        transition: transform .2s;

        .rtl-lang & {
            transform: translateX(12px);
            padding: 14px 60px 16px 36px;
            background-position: left 16px bottom 1px, left 16px bottom, left 32px top 16px;
        }

        &::after {
            content: '';
            width: 12px;
            height: 50px;
            background-color: $black;
            position: absolute; 
            top: 0;   
            right: 0;        
        }
    }

    &.euro2024-sidebar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;

        &::before {
            background-image: url($cdn + '/fezbet/tournaments/icon-euro2024.svg');
        }
    }

    .euro2024-sidebar-name {
        font-size: 12px;
        line-height: 170%;
    }

    &__count {
        display: block;
        position:absolute;
        top: 12px;
        right: 20px;
        background-color: #FFDC26;
        height: 24px;
        font-weight: 700;
        font-size: 16px;
        border-radius: 12px;
        line-height: 24px;
        color: #19191D;
        padding:0 8px;
        letter-spacing: -1px;
    }

    &__ferrari {
        height: 74px;

        &-logo {
            display: block;
            width: 74px;
            height: 25px;
            background-image: var(--icon-menu-ferrari_logo);
            background-repeat: no-repeat;
        }

        &-text {
            font-weight: 500;
        }
    }
}

$menuIcons: 'menu-crab',
    'menu-ferrari',
    'menu-casino',
    'menu-continueplaying',
    'menu-feature',
    'menu-live',
    'menu-livecasino',
    'menu-promotions',
    'menu-providers',
    'menu-sports',
    'menu-top',
    'menu-tournament',
    'menu-vip',
    'menu-virtual',
    'menu-shop',
    'menu-halloween';

@each $icon in $menuIcons {
    .menu-item.ico-#{$icon}:before {
        background-image: var(--icon-#{$icon}_1);   
        transition: background-image .3s;
    
    }
    .menu-item.is-opened.ico-#{$icon}:before,
    .menu-item.is-active.ico-#{$icon}:before {
        background-image: var(--icon-#{$icon}_2);
        transition: background-image .3s;
    }
}
