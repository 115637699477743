$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-pre-box {
  position: relative;
  background-color: $darkGrey;
  border-radius: 12px;
  width: 280px;
  height: 210px;
  padding: 0 12px 0 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint( $bp1 ) {
    width: 320px;
    height: 240px;
    padding: 0 12px 0 172px;
  }

  @include breakpoint( $bp2 ) {
    width: 400px;
    height: 300px;
    padding: 0 12px 0 212px;
  }

  .rtl-lang & {
    width: 320px;
    padding: 0 172px 0 12px;

    .btn-stroke {
      text-overflow: unset;
    }

    @include breakpoint( $bp2 ) {
      width: 400px;
      padding: 0 212px 0 12px;
    }
  }

  img {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;

    .rtl-lang & {
      left: unset;
      right: 0;
    }
  }

  &.icon-new__before::before{
    content: '';
    position: absolute;
    z-index: 2;
    display: block;
    left: 4px;
    top: 4px;
    width: 26px;
    height: 26px;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $red;
    box-shadow: inset 0px 2px 3px rgba(255, 255, 255, 0.3), inset 0px -2px 3px rgba(60, 18, 5, 0.4);
    border-radius: 50%;
  }

  &.icon-dropAndWins__before::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    display: block;
    width: 52px;
    height: 47px;
    top: 0;
    left: 0;
    background-image: var(--icon-drops-wins);
    background-repeat: no-repeat;
    background-size: contain;
    border-top-left-radius: 12px;
  }

  &.icon-exclusive__before::before{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 61px;
    height: 61px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
  }

  .btn {
    width: 100%;
  }
}
