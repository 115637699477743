$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.accordion {
    p {
        color: $white;
    }
    section {
        padding: 16px 40px 16px 20px;
        position: relative;

        .rtl-lang & {
            padding: 16px 20px 16px 40px;
        }

        h2 {
            margin-bottom: 0;
            padding-right: 20px;

            &::after {
                content: '';
                background-image: var(--icon-arrow-down);
                background-size: cover;
                background-repeat: no-repeat;
                width: 24px;
                height: 24px;
                position: absolute;
                right: 12px;
                top: 16px;
                transition: .3s;
            }

            .rtl-lang & {
                padding-right: 0;
                padding-left: 20px;

                &::after {
                    right: unset;
                    left: 12px;
                }
            }

            & ~ * {
                display: none;
            }

            &.open {
                margin-bottom: 12px;
            }

            &.open ~ * {
                display: block;
            }

            &.open ~ ul {
                list-style: disc;
                padding-left: 24px;

                .rtl-lang & {
                    padding-left: unset;
                    padding-right: 24px;
                }

                li {
                    @include text(14px, 20px, normal, #28272A);
                    letter-spacing: 0.03em;
                }
            }

            &.open::after {
                transform: rotate(180deg);
            }

            & ~ div {
                overflow-x: auto;
            }
        }

        p {
            color: $black;
            padding-bottom: 4px;
        }

        table {
            display: table;
            position: relative;
            margin: 20px 0;
            width: 100%;

            tr {
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-image: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.6) 50%,rgba(0,0,0,0) 100%);
                border-image-slice: 1;
            }
        }

        thead {
            tr {
                th, td {
                    font-weight: bold;
                    color: $grey;
                    text-align: center;
                    padding: 6px 10px;
                }
            }
        }

        tbody {
            tr {
                td {
                    @include text(12px, 120%, normal, $black);
                    text-align: center;
                    padding: 6px 10px;
                }
            }
        }
    }

    .cookie_link {
        font-size: 14px;
        font-weight: 400;
        color: $yellow;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
