$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.opt-out-modal {
    display: flex;
    flex-direction: column;

    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 40px auto;

    &__wrap {
        padding: 40px 60px;
        border-radius: 20px;
        background: $lightGrey;
        box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
        color: $black;
        text-align: center;
    }
    &__title {
      @include text(32px, 40px, 700, $darkGrey);
      font-family: $font-roboto;
    }
    &__text {
      @include text(14px, 20px, null, $darkGrey);
      letter-spacing: 0.42px;
    }
    &__btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 24px 24px 0;
    }
    &__btn {
        text-decoration: none;
    }
    &__link {
      @include text(14px, 20px, null, $grey);
      text-decoration-line: underline;
    }
  }