$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-dashed {

  font-weight: normal;
  font-size: 14px;
  line-height: 48px;
  color: $grey;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    border-bottom: 1px dashed $grey;
    position: absolute;
    bottom: 12px;
    left: 0;
  }

  @include hover(){
    color: $black;
    &:after {
      border-color: $black;
    }
  }

}
