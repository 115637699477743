$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-underline {
    @include text(16px, 24px, normal, $white);
    text-decoration-line: underline;
    &.is-yellow {
        color: $yellow;
    }
    &.is-small {
        font-size: 12px;
        line-height: 16px;
    }
    @include hover() {
        opacity: .5;
    }
}