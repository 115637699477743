$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 70px;
  background: #2D2D32;
  box-shadow: 0 0 12px rgba(25, 25, 29, .8);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: $toolbarHeight;

  .rtl-lang & {
    padding: 10px 70px 10px 20px;
  }

  &.is-on-game-page {
    justify-content: center;
    padding: 10px 20px;

    & > *:last-child {
      margin-left: 24px;

      &:lang(ar) {
        margin-left: 0;
        margin-right: 24px;
      }
    }
  }
}
