$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.notification-list {

  position:fixed;
  top: 8px;
  right: 8px;
  width: calc(100% - 16px);
  max-width: 320px;
  z-index: 999999999;

}
