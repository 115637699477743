$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.table-description {

  display: none;

  width: 100%;

  .is-opened & {
    display: block;
  }

  span {
    display: block;
    margin-top: 8px;
    padding: 0 4px;
    &:first-child {
      margin-top: 14px;
    }
  }

  color: $grey;
  strong {
    color: $black;
  }

}
