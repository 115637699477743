$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider {
    position: relative;
    height: 306px;
    padding: 0 20px;
    border-top: 1px solid $darkGrey;    
    background-color: $black;
    margin-bottom: -1px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background-position: left -33px top 236px, right 15px top 180px, left 110px top 60px, right 28px top 55px, center top 21px;
        background-repeat: no-repeat;
        background-size: 60px, 30px, 16px, 128px, 360px auto;
        background-image:
                url( $cdn + '/fezbet/elements/circle.png'),
                url( $cdn + '/fezbet/elements/circle.png'),
                url( $cdn + '/fezbet/elements/circle.png'),
                url( $cdn + '/fezbet/elements/circle.png'),
                url( $cdn + '/fezbet/mobile/bg.jpg');
    }

    .rtl-lang & {
        &::before {
            transform: scale(-1, 1);
        }
    }

    body.pay-n-play & {
        &::before {
            background-position: left -33px top 188px, right 15px top 132px, left 110px top 12px, right 28px top 7px, center top -27px;
            background-size: 60px, 0, 16px, 128px, 360px auto;
        }
    }

    .slick-list {
        overflow: inherit;
    }

    @include breakpoint($bp1) { 
        padding: 0 32px;
        height: 350px;

        background-position: 
            left -28px top 288px,    
            right 15px top 210px,
            left 220px top 66px,
            right 32px top 66px,
            right -16px top 31px;
        background-size:
            60px, 
            30px,
            16px,
            210px, 
            402px auto;

        body.pay-n-play & {
            background-position: 
                left -28px top 240px,    
                right 15px top 162px,
                left 220px top 18px,
                right 32px top 18px,
                right -16px top -17px;
            background-size:
                60px, 
                30px,
                16px,
                210px, 
                402px auto;
        }
    }
    @include breakpoint($bp2) {
        height: 423px;

        background-position: 
            left -28px top 322px,  
            right 15px top 227px,
            left 334px top 66px,
            right 47px top 67px,
            right -46px top 15px;
        background-size:
            60px, 
            30px,
            16px,
            285px, 
            541px auto;


        body.pay-n-play & {
            background-position: 
                left -28px top 274px,    
                right 15px top 179px,
                left 334px top 18px,
                right 47px top 17px,
                right -46px top -33px;
            background-size:
                60px, 
                30px,
                16px,
                285px, 
                541px auto;
        }
    }
    @include breakpoint($bp3) {
        padding: 0 80px;

        background-position: 
            left -28px top 362px,  
            right 15px top 247px,
            left 398px top 66px,
            right 111px top 67px,
            right 18px top 15px;

        body.pay-n-play & {
            background-position: 
            left -28px top 314px,  
            right 15px top 199px,
            left 398px top 18px,
            right 111px top 17px,
            right 18px top -33px;
        }
    }
}
