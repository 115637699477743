$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-close-alt {
  &:after {
    content: '';
    background: var(--icon-close) center no-repeat;
    background-size: 48px;
    filter: $filterBlack;
    width: 100%;
    height: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: $grey;
  padding: 8px;
  background-clip: content-box;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  .rtl-lang & {
    right: unset;
    left: 0;
  }

  @include hover() {
    background-color: rgba(134, 138, 146, 0.6);
  }

  @include breakpoint( $bp2 ) {
    top: 20px;
    right: 20px;
  }
}
