$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-red{
    @extend .btn-base; 
    
    --bgc: #{$red};
    --shadow: inset 0px 8px 10px rgba(255, 255, 255, 0.6), inset 0px -8px 10px #9F1007;
    color: #ffffff;
    &:not([disabled]):not(.is-disabled){
        @include hover(){
            color: rgba(255, 255, 255,.6);
            --bgc: #{$red};
            --shadow: inset 0px -8px 10px rgba(255, 255, 255, 0.2), inset 0px 8px 10px #9F1007;
        }
    }
}
