$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop-exchange {
  &__items {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 20px;
    padding: 0 16px;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
    }

    @include breakpoint($bp3) {
      min-height: 125px;
    }
  }
}
