$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-bottom: 16px;
  font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;

  &__banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      width: 390px;
      height: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
      content: "";
    }

    &::before {
      left: -326px;
      background-image: url($cdn + '/fezbet/shop/left-bg-cherry-mobile.webp');
      background-position: left;

      @include breakpoint($bp1) {
        left: -242px;
      }

      @include breakpoint($bp2) {
        left: -155px;
      }

      @include breakpoint($bp3) {
        left: -30px;
      }
    }

    &::after {
      right: -326px;
      background-image: url($cdn + '/fezbet/shop/right-bg-ball-mobile.webp');
      background-position: right;

      @include breakpoint($bp1) {
        right: -242px;
      }

      @include breakpoint($bp2) {
        right: -155px;
      }

      @include breakpoint($bp3) {
        right: -30px;
      }
    }

    &-title {
      margin-bottom: 4px;
      color: $yellow;
      font-size: 40px;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
    }

    &-coins {
      display: flex;
      align-items: center;
      margin: 4px 0;
      color: $white;
      font-size: 24px;
      font-weight: 400;
      line-height: 120%;

      &-item {
        display: flex;
        align-items: center;

        &::after {
          content: "";
          position: relative;
          width: 28px;
          height: 28px;
          background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin: 0 8px;
        }
      }

      &-val {
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    }

    &-how-to-link {
      margin-top: 4px;
      color: $grey;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-decoration: none;
      display: flex;
      align-items: center;

      &::after {
        content: '';
        width: 16px;
        height: 16px;
        position: relative;
        background-image: var(--icon-arrow-right);
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 4px;
      }

      .rtl-lang & {
        &::after {
          margin-left: unset;
          margin-right: 4px;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__divider {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 4px;

    .line1 {
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 50%, rgba(255, 255, 255, 0.00) 100%);
    }

    .line2 {
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 50%, rgba(0, 0, 0, 0.00) 100%);
    }
  }

  &__exchange {
    padding: 16px 0;

    &-text {
      width: 80%;
      margin: 0 auto 16px;
      color: $grey;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__crab {
    padding: 0 16px;
  }

  &__how-to {
    margin: 16px auto 20px;
    padding: 0 16px;
    max-width: 600px;
  }

  &__subtitle {
    color: $grey;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    line-height: 114%;
    text-align: center;
  }

  &__how-to-items {
    padding-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }

  &__how-to-title {
    color: $white;
    text-transform: uppercase;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  &__how-to-text {
    color: $white;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
  }

  &__how-to-item {
    position: relative;
    display: flex;
    text-decoration: none;

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__how-to-icon {
    display: flex;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &._deposit {
      background-image: url($cdn + '/fezbet/shop/how-to-earn-deposit.webp');
    }

    &._bets {
      background-image: url($cdn + '/fezbet/shop/how-to-earn-bets.webp');
    }

    &._tournaments {
      background-image: url($cdn + '/fezbet/shop/how-to-earn-tournament.webp');
    }
  }

  &__freespin-subtitle {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    margin-top: 8px;
  }

  &__free-spins {
    padding: 16px;
  }
}
