$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-box{
    padding: 8px 0 8px;
    border-bottom: 1px solid $black;

    &__games {
        margin: 20px 0 4px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 14px;
        margin-bottom: 16px;
        height: 36px;

        .btn-stroke:lang(el) {
            font-size: 12px;
        }
    }

    &__title {
        @include text(16px, 36px, bold, $white);

        letter-spacing: 0.02em;
        text-transform: uppercase;

        &:lang(el) {
            font-size: 12px;
            width: 60%;
        }

        &.has-bg {
            padding-left: 52px;
            background-size: 36px 36px;
            background-repeat: no-repeat;
            background-position: left center;
            height: 100%;

            .rtl-lang & {
                padding-left: unset;
                padding-right: 52px;
                background-position: right center;
            }
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 1fr;
        grid-gap: 8px;
        padding: 0 14px;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        .game-item {
            margin: 0;

            img {
                margin: 0;
            }
        }
    }

    &__load:not(.is-hidden){
        grid-column-end: 1;
        grid-column-start: -1;
        margin-bottom: -36px;
    }

    &__load.is-hidden{
        display: none;
    }

    &__scroll {
        margin-top: 8px;
        padding: 0 8px;
        height: 180px;

        &:after, &:before {
            content: '';
            width: 120px;
            height: 180px;
            border-radius: 12px;
            background-color: rgba(255,255,255,.1);
            position: absolute;
            top: 0;
            left: 12px;
            z-index: 0;
        }
        &:after {
            left: 140px;
        }

    }
    .layout-horiz{
        @include shadow-before-after();
    }

    .has-no-line &{
        border-bottom: none;
    }

    &.is-grid{
        padding: 0 12px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        border-bottom: none;
        .layout-homepage__games & {
            padding: 0;
        }
        
        @include breakpoint($bp1){
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint($bp2){
            grid-template-columns: repeat(4, 1fr);
        }

        @include breakpoint($bp3){
            grid-template-columns: repeat(6, 1fr);
        }
    }
}
