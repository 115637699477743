$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-top {    
    &.has-2-col {
        .tournament-top__head {
            grid-template-columns: 32px 1fr;
        }
    }
    &__title {
        &::before {
          background-size: 0, 1px 20px, 1px 20px;
        }
    }
    &__head {
        display: grid;
        grid-template-columns: 32px repeat(2, 64px) 1fr;
        grid-column-gap: 12px;
        padding: 0 12px;
        @include text(12px, 24px, normal, $grey);
        margin-bottom: 8px;
        @include breakpoint($bp2) {
            grid-template-columns: repeat(3, 32px) 1fr;
        }
    }
    &__list {
      &.is-height-calc-max {
        height: calc($leaderboardHeightCalc * 11);
        overflow: scroll;
      }
      &.is-height-calc-min {
        height: calc($leaderboardHeightCalc * 10);
        overflow: scroll;
      }
    }
    &__head-i {
        text-align: right;
        @include ellipsis;
        &:first-child {
            text-align: center;
        }
    }

    &__item {
      &.is-current-user-ferrari {
        background-color: #ffdc26;
        border-radius: 8px;
        position: sticky;
        bottom: 0;
        top: 0;
        margin-top: -8px;
      }
        @extend .tournament-top__head;

        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: left bottom, left bottom 1px;        
        &:last-of-type {
            background-image: none;
        }
        &.is-current-user {
            background-color: rgba(255, 220, 38, .2);
            border-radius: 8px;
        }
    }
    &__item-i {
        text-align: right;
        margin: 6px 0 15px;
        @include text(13px, 24px, normal, $white);
        @include ellipsis;

        &:nth-child(1) {
            margin: 4px 0 0;
            height: 32px;
            text-align: center;
            background-size: 32px;
            background-position: left top;
            background-repeat: no-repeat;

        }
        .is-current-user & {
            color: #FFDC26;
        }

        &._user-name {
          text-align: start;
        }
    }
    @include breakpoint($bp3) {
        &__head { grid-template-columns: 32px 52px 52px 1fr}
    }
}
