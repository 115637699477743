$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-random {
    display: none;
    background: rgba(255, 220, 38, 0.3);
    border-radius: 22px;
    @include text(12px, 36px, 700, #fff);
    height: 36px;
    width: 149px;
    padding: 0  48px 0 16px;
    background-image: var(--icon-random-game);
    background-size: 28px;
    background-position: right 12px center; 
    background-repeat: no-repeat; 
    transition: .2s ease;
    margin-left: auto;
    margin-bottom: 6px;
    align-self: center;

    .rtl-lang & {
        margin-left: 0;
        margin-right: auto;
        padding: 0  16px 0 48px;
        background-position: 12px center;
    }

    @include hover() {
        color: $yellow;
    }

    @include breakpoint($bp1) {
        display: block;
    }
}
