$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.icon{
    display: inline-block;
    width: 48px;
    height: 48px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.2s ease background-image;

    &.is-yellow {
        filter: $filterYellow;
    }
    &.is-white {
        filter: $filterWhite;
    }
}
