$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;

.btn-stroke{
    @extend .btn-base;

    line-height:  calc(var(--height) - 2px);
    --bgc: transparent;
    color: $yellow;
    font-size: 16px;
    border-style: solid;
    border-width: 1px;
    border-image-source: var(--icon-stroke);
    border-image-slice: 18;
    border-image-width: 20px;
    border-radius: 40px;
    &:not([disabled]):not(.is-disabled){
        @include hover(){
            color: $black;
            background-color: $yellow;
            border-image: none;
        }
    }
}
