$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-trustly-paynply{
    text-align: center;    
    &__list{
        list-style-type: none;
        counter-reset: num;
        font-size: 16px;
        line-height: 24px; 
        text-align: left;  
        padding-top: 4px; 
    }    
    &__item{
        position: relative;
        padding-left: 32px;
        &:before{
            content: counter(num) '. ';
            counter-increment: num;   
            font-weight: bold;
            min-width: 28px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }    
    &__text{
        line-height: 48px;
        margin-bottom: 24px;
        a{
            text-decoration: underline;        
            @include hover(){
                opacity: .85;
            }        
        }        
    }
    @include breakpoint( $bp1 ){
        padding: 0 40px;
    }
}