$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.seo{
    --headers-height: 66px;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 20px 0 8px;

    .rtl-lang & {
        display: none;
    }

    h1, h2, h3, h4, h5, h6 {
        display: block;
        font-family: var(--font-family-main);
        @include text(16px, 20px, 700, #fff);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-bottom: 8px;
        transition: .3s ease min-height;
    }

    section {
        position: relative;
        @include text(14px, 20px, 400, #fff);
        background: none;
        padding: 12px 0 0 52px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;

        &::before {
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            left: -4px;
            top: -10px;
            background-image: url($cdn + "/fezbet/elements/small_circle.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left top;
            filter: blur(4px);
        }

        > div {
            order: 1;
            max-height: var(--headers-height);
            overflow: hidden;
            transition: .4s ease max-height;
        }

        > *:last-child {
            padding-bottom: 4px;
        }
    }

    img, .icon {
        position: absolute;
        left: 16px;
        top: 11px;
        width: 21px;
        height: 21px;
        object-fit: contain;
        box-shadow: 0px 10px 15px rgba(40, 39, 42, 0.15);
    }

    button {
        position: relative;
        display: inline-block;
        padding: 0 2px;
        @include ellipsis;
        @include text(14px, 44px, bold, $yellow);
        padding-right: 18px;

        order: 2;
        background-color: transparent;
        border: none;
        text-align: left;
        width: max-content;

        &::after{
            content: '';
            position: absolute;
            width: 16px;
            height: 100%;
            right: 0;
            top: 0;
            background-image: var(--icon-arrow-down-y);
            background-size: 16px;
            background-position: right center;
            background-repeat: no-repeat;
            transition: .3s ease;
            filter: $filterYellow;
        }

        &.is-open::after {
            transform: rotate(180deg);
        }

        span {
            pointer-events: none;
        }

        span:nth-child(2) {
            display: none;
        }
    }

    p, li, strong {
        @include text(14px, 20px);
        color: $white;
        padding-bottom: 8px;
    }

    p {
        font-family: $font;
        color: $white;
    }

    a {
        @include text(14px, inherit, 400, $yellow);
        text-decoration: underline;
    }

    strong {
        font-weight: 700;
    }

    ul, ol {
        margin: 0;
        padding: 0;
    }

    ul {
        list-style: disc;
        list-style-position: inside;
    }

    ol {
        list-style: none;
        counter-reset: num-counter;

        li {
            position: relative;
            padding-left: 32px;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                font-weight: 700;
                width: 24px;
            }

            ol {
                margin-top: 8px;
                counter-reset: num-counter2;
            }

            ol li {
                counter-increment: num-counter2;

                &::before {
                    content: counter(num-counter)"."counter(num-counter2, decimal ) ".";
                }
            }
        }

        & > li {
            counter-increment: num-counter;

            &::before {
                content: counter(num-counter, decimal )".";
            }
        }
    }

    button.is-open {
        span:nth-child(1){  display: none;}
        span:nth-child(2){  display: block;}
        & + div {
            max-height: 2000px !important;
        }
    }

    @include breakpoint($bp1) {
        --headers-height: 43px;
    }

    @include breakpoint($bp2) {
        max-width: 600px;
    }
}
