$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn {
    @extend .btn-base;

    --bgc: #{$yellow};
    --shadow:  inset 0px 10px 12px rgba(255, 255, 255, 0.8), inset 0px -10px 12px #E88E0F;
    &:not([disabled]):not(.is-disabled){
        @include hover(){
            --bgc: #EEA315;
            --shadow:  inset 0px -10px 12px rgba(255, 255, 255, 0.4), inset 0px 10px 12px rgba(232, 142, 15, 0.8);
        }
    }

    .rtl-lang & .not-reverted {
        direction: ltr;
        display: inline-flex;
    }
}
