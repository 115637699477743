$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-arrow {
    @include ellipsis;
    @include text(14px, 48px, bold, black);

    display: inline-block;
    padding-inline-start: 2px;
    padding-inline-end: 16px;
    filter: $filterYellow;
    position: relative;

    &::before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        inset-inline-end: 0;
        top: 50%;
        translate: 0 -50%;
        background-image: var(--icon-arrow-right-y);
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:lang(eu) {
        max-width: 120px;
    }

    &:lang(ar)::before {
        scale: -1 1;
    }

    @include hover() {
        opacity: .5;
    }

    &.has-arrow-down {
        transition: background-image .3s;

        &::before {
            background-image: var(--icon-arrow-down-y);
        }
    }

    &.is-black {
        filter: $filterBlack;

        &::before {
            background-image: var(--icon-arrow-right-black);
        }
    }
}
