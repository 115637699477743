$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cash-crab-wrap{
  height: 100vh;
  overflow: auto;
  width: 100%;
  background: #048ff7;
  position: relative;
  z-index: 1;
  & .crab-area-73aos {
    min-height: 100%;
    height: auto !important;
    display: flex;
  }
}