$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-back {
    @include text(16px, 48px, 400, $black);

    position: relative;
    display: inline-block;
    height: 48px;
    font-family: var(--font-family-main);
    text-transform: uppercase;
    padding-inline-start: 20px;
    background-image: unset;
    background-color: transparent;

    &::before {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        top: 50%;
        width: 16px;
        height: 16px;
        translate: 0 -50%;
        background-image: var(--icon-arrow-back);
        background-position: center;
        background-repeat: no-repeat;
    }

    &:lang(ar) {
        &::before {
            rotate: 0.5turn;
        }
    }

    @include hover() {
        color: #000000;
        filter: $filterGrey;
    }

    &.icon-arrow-back {
        background-image: unset;
    }

    &.is-not-black {
        filter: $filterWhite;

        @include hover() {
            filter: $filterYellow;
        }
    }
}
