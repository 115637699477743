$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-icon-favourite {

  background-image: var(--icon-addfavourite);

  width: 48px;
  height: 48px;

  padding: 6px;
  background-color: rgba(255, 220, 38, 0.1);
  border-radius: 14px;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-position: center;

  &.is-favourite {
    background-color: rgba(255, 220, 38, 0.3);
  }

  @include hover(){
    background-color: rgba(255, 220, 38, 0.3);
  }

}
