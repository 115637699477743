$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-title {
  @include text(16px, 48px, bold, $white);
  @include ellipsis;

  max-width: calc(100% - 14px);
  width: fit-content;
  font-family: var(--font-family-main);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 16px 0 22px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background-image: var(--icon-arrow-white), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
    background-size: 16px, 1px 20px, 1px 20px;
    background-position: right center, left 9px center, left 8px center;
    background-repeat: no-repeat;
  }

  .rtl-lang & {
    &::before {
      transform: scale(-1, 1);
    }
  }

  @include hover(){
    color: $yellow;
  }
}
