$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-exchanger {
  background-color: $lightGrey;
  border-radius: 12px;
  padding: 0 16px 16px;
  color: $black;

  strong {
    @include ellipsis;
  }

  &__min-text {
    font-weight: bold;
    color: $grey;
    margin-top: 8px;
  }

  &__amount {
    width: 100%;
    border-radius: 12px;
    background-color: $white;
    line-height: 48px;
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 24px;
    color: $black;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__level {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 8px;
    width: calc(50% - 4px);
    height: 96px;
    border-radius: 12px;
    background-color: $yellow;
    img {
      width: 80px;
      height: 80px;
      position: absolute;
      bottom: -12px;
      right: -12px;
    }
    strong {
      display: block;
      font-family: var(--font-family-main);
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px;
    }
    p {
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 4px;
      width: 88px;
    }
    span {
      font-family: var(--font-family-main);
      font-weight: bold;
      font-size: 32px;
      line-height: 28px;
    }
    &.is-next {
      margin-left: 8px;
      background-color: $white;
      p, span {
        color: $grey;
      }
    }
  }

  @include breakpoint( $bp1 ){
    &__level {
      width: calc(50% - 6px);
      &.is-next {
        margin-left: 12px;
      }
      strong {
        margin-bottom: 12px;
      }
      img {
        width: 108px;
        height: 108px;
        right: 7px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        width: 140px;
        white-space: nowrap;
      }
    }
  }

}
