$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.toolbar-balance {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: $grey;

  span {
    display: block;
    direction: ltr;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $white;

    strong {
      color: $yellow;
    }
  }

  @include hover() {
    opacity: .85;
  }
}
