$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-leagues {
  position: relative;
  padding: 16px 12px 14px 12px;
  background: $white;
  border-radius: 12px;
  z-index: 1;
  &__title {
    @include text(16px, 20px, 700, $black);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 12px;
  }
  &__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px 12px;
  }
  &__list {
    display: contents;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 14px;
    min-height: 70px;
    background-image:linear-gradient(180deg, #ffd43e 0%, #ff4e4e 100%);
    border-radius: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: 0.3s linear;
    aspect-ratio: 1.8/1;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: var(--league-icon);
    }
    @include hover {
      opacity: 0.8;
      transform: translateY(3px);
      transition: 0.3s linear;
    }
  }
  @include breakpoint ($bp1) {
    padding: 22px 16px 34px;
    width: calc(100% - 40px);
    border-radius: 12px 0px 12px 12px;
    &__title {
      margin-bottom: 16px;
    }
    &__wrap {
      grid-template-columns: repeat(3, 1fr);
    }
    &__item {
      background-size: cover;
      margin: initial;
    }
  }
  @include breakpoint ($bp2) {
    padding: 22px 16px 24px;
  }
  @include breakpoint ($bp3) {
    width: calc(100% - 80px);
    &__wrap {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
