$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    background-color: $darkGrey;

    position: absolute;
    bottom: 56px;
    left: 0;
    z-index: 999;
    &:after {
        content: '';
        width: 100%;
        height: 16px;
        background-image: linear-gradient(180deg, rgba(45, 45, 50, 0) 0%, #2D2D32 100%);
        position: absolute;
        left: 0;
        top: -16px;
        z-index: 3;
    }
}