$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.info-not-found {
    padding: 20px 0 40px 0;
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 280px;
        margin: 0 auto;
    }
    &__title {
        @include text(24px, 36px, bold, $white);
        display: block;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 24px;
    }
    &__number {
        @include text(120px, 120px, bold, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        text-align: center;
        background: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
        -webkit-background-clip: text;
        margin-bottom: 24px;
    }
    &__subtitle {
        @include text(14px, 20px, normal, $lightGrey);
        text-align: center;
        letter-spacing: 0.03em;
        margin-bottom: 24px;
    }
    &__btn {
        min-width: 185px;
        max-width: 100%;
    }

    @include breakpoint($bp1) {
        padding: 80px 0 100px 0;
    }

    @include breakpoint($bp2) {
        padding: 146px 0 166px 0;
    }
}
