$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-title {
    @include text(14px, 52px, 700, $grey);
    @include ellipsis;

    position: relative;
    height: 52px;
    width: 100%;
    background-size: 32px;
    background-position: 16px center;
    background-repeat: no-repeat;
    padding: 0 40px 0 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s;

    .rtl-lang & {
        padding: 0 56px 0 40px;
        background-position: right 16px center;
    }

    h1 {
        @include text(14px, 52px, 700, $grey);
        @include ellipsis;

        display: inline-block;
        color: #fff;
    }

    span {
        color: $white;
    }

    @include hover() {
        color: #ffffff;

        h1 {
            color: $yellow;
        }

        span {
            color: $yellow;
        }
    }

    &.is-active, &.is-link {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: 14px;
            width: 20px;
            height: 20px;
            background-image: var(--icon-arrow);
            background-size: contain;
            background-repeat: no-repeat;
            filter: $filterGrey;
            transition: .2s ease transform;
        }

        .rtl-lang & {
            &::before {
                right: unset;
                left: 14px;
            }
        }
    }

    &.is-active {
        background-size: 40px;
        font-size: 15px;
        padding-left: 64px;

        .rtl-lang & {
            padding-left: 40px;
            padding-right: 64px;
        }

        h1 {
            color: $yellow;
        }

        &::before {
            transform: rotate(180deg);
        }

        @include hover() {
            &::before {
                filter: invert(1);
            }
        }

        .is-opened-category &, &.is-open {
            &::before{
                transform: rotate(0deg);
            }
        }
    }

    &.is-link {
        &::before {
            transform: rotate(90deg);
        }

        .rtl-lang & {
            &::before {
                transform: rotate(-90deg);
            }
        }

        &.has-no-icon {
            padding-left: 16px;
            background: none;

            .rtl-lang & {
                padding-left: 40px;
                padding-right: 16px;
            }
        }

        @include hover() {
            color: $yellow;

            &::before {
                filter: $filterYellow;
            }
        }
    }
    &.is-continue-playing {
        @include text(15px, 28px, bold, $white);

        background-color: $darkGrey;
        border-radius: 12px 12px 0 0;
        padding-left: 12px;
    }
}
