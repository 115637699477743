$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-label {
    background: $yellow;
    border-radius: 8px;
    padding: 0 12px;
    min-width: 59px;
    max-width: 90px;
    text-align: center;
    &__text {
        @include text(14px, 20px, bold, $black);
        @include ellipsis;
        font-family: var(--font-family-main);
    }
}
