$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
body.linda-has-user {
    .popup-promo-banner__text {
        font-size:20px;
        line-height: 28px;
        color: #ffffff;
        margin-top: 130px;
    }

    .popup-promo-banner__text2 {
        font-size: 32px;
        line-height: 34px;
        color: $yellow;

        &:lang(ar),
        &:lang(pt),
        &:lang(eu) {
            font-size: 28px;
        }
    }

    .popup-registration-1__title,
    .popup-registration-1__text,
    .popup-steps {
        animation-name: registration;
        animation-duration: .8s;
        animation-fill-mode: forwards;
    }

    .popup-promo-banner {
        animation-name: reg-bnr;
        animation-duration: .8s;
        animation-fill-mode: forwards;
        transition-timing-function: ease-in;
    }

    .popup-promo-banner__title {
        animation-name: reg-top;
        animation-duration: .7s;
        animation-delay: .6s;
        animation-fill-mode: forwards;
        transition-timing-function: cubic-bezier(0.29, 0.37, 0.78, 0.46);
    }

    .popup-promo-banner__desc-container {
        animation-name: reg-desc;
        animation-duration: .6s;
        animation-delay: .6s;
        animation-fill-mode: forwards;
        transition-timing-function: cubic-bezier(0.5, 0.7, 1.0, 0.3);
    }

    .popup-promo-banner .link-arrow {
        animation-name: reg-link;
        animation-duration: .6s;
        animation-fill-mode: forwards;
    }

    .popup-promo-banner .btn {
        animation-name: reg-btn;
        animation-duration: .4s;
        animation-delay: .6s;
        animation-fill-mode: forwards;
    }

    .popup-promo-banner__img {
        animation-name: reg-img;
        animation-duration: .6s;
        animation-delay: .3s;
        animation-fill-mode: forwards;
    }
}
@keyframes registration {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}
@keyframes reg-bnr {
    0% {
        transform: translateY(0);
        height: 128px;
        opacity: .4;
        z-index: 3;
    }
    80% {
        transform: translateY(-34px);
        height: 400px;
    }
    100% {
        transform: translateY(-14px);
        height: 400px;
        overflow: initial;
        background: linear-gradient(90deg, rgba(146, 66, 21, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
        opacity: 1;
    }
}

@keyframes reg-top {
    0% {
        opacity: 0;
        height: 0;
    }
    50% {
        opacity: 1;
        height: 48px;
    }
    100% {
        height: 48px;
        color: $black;
        opacity: 1;
    }
  }

  @keyframes reg-desc {
    0% {
        opacity: 0;
        height: 0;
    }
    50% {
        opacity: 1;
        height: 84px;
    }
    100% {
        opacity: 1;
        height: 84px;
        color: $white;
    }
}

@keyframes reg-link {
    0% {
        left: 16px;
    }
    100% {
        left: 50%;
        transform: translateX(-50%);
        bottom: 17px;
    }
}

@keyframes reg-btn {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: 40px;
        opacity: 1;
    }
}

@keyframes reg-img {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        z-index: 1;
        transform: translate(12px, -130px) scale(1.19);
    }
}
