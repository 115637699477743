$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-horiz-promo {

  @extend .layout-horiz;

  margin: 0 -8px;
  width: calc(100% + 16px) !important;

  & > * {
    padding: 0 16px;
  }

  .promo-item {
    width: 280px;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    position: relative;

    & + .promo-item {
      margin-inline-start: 12px;
    }

    &:lang(de),
    &:lang(at),
    &:lang(fr-CA),
    &:lang(fi),
    &:lang(hu),
    &:lang(pt-BR),
    &:lang(es-CL),
    &:lang(es),
    &:lang(pl) {
      width: 280px;
      height: 434px;

      .promo-item__title {
        font-size: 20px;
      }

      .btn {
        padding: 0 14px;
        font-size: 16px;
      }

      .link-arrow {
        font-size: 12px;
      }
    }
  }

  .promo-item__subtitle:lang(hu) {
    font-size: 30px;
  }

  @include breakpoint($bp1) {
    margin: 0 -20px;
    width: calc(100% + 40px) !important;
  }

  @include breakpoint($bp2) {
    margin: 0;
    width: 100% !important;
  }

}
