$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-icon-contact {
    height: 48px;
    padding: 12px 0 12px 40px;

    background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(45, 45, 50, 0) 100%), var(--icon-live-chat);
    background-repeat: no-repeat;
    background-size: 36px 36px, 24px 24px;
    background-position: left center, 6px center;

    @include text(18px, 24px, normal, $yellow);
    font-family: var(--font-family-main);
    @include ellipsis;

    @include hover() {
        background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 220, 38, 0.2) 55.73%, rgba(45, 45, 50, 0) 100%), var(--icon-live-chat);
    }

    &:lang(ar) {
        padding: 12px 40px 12px 0;
        background-position: right center, 95% center;
    }
}
