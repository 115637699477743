$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-form {
    .form-item {
        max-width: 280px;
        margin: 0 auto 20px;
    }

    &__guide {
      font-family: 'Montserrat', -apple-systemm, "Segoe UI", sans-serif;
      border: 2px solid $middleGrey;
      background-color: $white;
      border-radius: 12px;
      color: #131E3C;
      font-weight: 400;
      font-size: 14px;
      max-width: 600px;
      margin: 0 auto 20px;
      padding: 20px;

      ol {
        list-style-position: inside;
        display: grid;
        row-gap: 5px;

        ::marker {
          color: #5B667C;
        }
      }
    }

    .payment-preloader {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: $lightGrey;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), inset 0 1px 1px rgba(195, 195, 195, 0.2), inset 0 -3px 6px rgba(195, 195, 195, 0.5), inset 0 6px 6px rgba(255, 255, 255, 0.8);
        border-radius: 12px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        &__text {
            @include text(14px, 20px, 400, $black);
            max-width: 240px;
            margin-bottom: 12px;
        }

        &__icon {
            width: 24px;
            height: 24px;
            animation: spin 2s linear infinite;
        }
    }

    &._preloader-payment {
        position: relative;
        pointer-events: none;

        .payment-preloader {
            display: flex;
        }
    }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
