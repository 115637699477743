$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-ball{
    display: none;
    width: 60px;
    height: 60px;
    position: absolute;
    z-index: 50;
    top: -30px;
    left: calc(50% - 30px);
    background-image: url($cdn + '/fezbet/elements/circle.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-size: 36px;
        background-position: center;
        background-repeat: no-repeat; 
    }

    @include breakpoint($bp1){
        display: block;
    }
}