$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-balance-history {

  background-color: $lightGrey;

  padding: 0 0 12px;

  width: calc(100% + 16px);
  margin: 0 -8px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 4px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 0 12px;

    span {
      font-size: 12px;
      line-height: 24px;
      color: #231F20;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        background: $green;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
        width: 16px;
        height: 16px;
        border: 3px solid $white;
        border-radius: 50%;
        margin-inline-start: 4px;
      }

      &:first-child:after {
        background-color: $red;
        margin-inline-end: 12px;
      }
    }
  }

  &__title {
    color: $black;
    white-space: nowrap;
    margin-top: 20px;
  }

  @include breakpoint( $bp1 ){
    width: 100%;
    margin: 0;
    border-radius: 12px;
  }

  .is-amount{
    &.is-real {
      color: $green;
    }

    &.is-bonus {
      color: $red;
    }
  }
}
