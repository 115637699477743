$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.coming-soon {
  position: relative;
  padding: 25px 0 10px;
  text-align: center;

  &--subtitle {
    font-size: 14px;
    text-transform: uppercase;
    color: $white;

    &-date {
      position: relative;
      display: inline-block;
      padding-left: 11px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $white;
      }
    }
  }

  &--title {
    font-size: 32px;
    line-height: 1;
    font-weight: 700;
    font-family: var(--font-family-main);
    margin-bottom: 5px;
    padding: 0 15px;

    @include breakpoint($bp1) {
      font-size: 42px;
      margin-bottom: 15px;
    }

    .text-mr {
      margin-left: 6px;
    }
  }

  &--img {
    padding-top: 38%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    min-height: 127px;
  }

  &--action {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .text-yellow {
    color: #FFDC26;
  }

  .btn-orange {
    margin-bottom: 5px;
  }
}
