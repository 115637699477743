$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.icon-exit{
    @include hover(){
        background-image: var(--icon-exit-tap);
    }

    &:lang(ar) {
        transform: rotate(180deg);
    }
}
