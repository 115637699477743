$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-games {
    background-color: $white;
    border-radius: 12px;
    padding: 16px 12px 12px;
    position: relative;
    z-index: 1;
    &__title {
        @include text(16px, 20px, bold, $black);
        font-family: var(--font-family-main);
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    &__wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        &.has-more {margin-bottom: 24px;}
    }
    &__link-more {
        position: absolute;
        bottom: -28px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;
    }
    @include breakpoint ($bp1) {
        width: calc(100% - 40px);
        padding: 16px 16px 12px;
        &__wrap {
            grid-template-columns: repeat(3, 1fr);
            &.has-more {margin-bottom: 28px;}
        }
    }
    @include breakpoint ($bp2) {
        

    }
    @include breakpoint ($bp3) {
        width: calc(100% - 81px);
        &__wrap {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
