$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-promotion {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: -60px;
}
.ferrari-promo {
  background-color: #19191d;
  &__banner {
    background-position: 50% calc(100% - 100px);
    background-repeat: no-repeat;
    background-size: 180%;
    background-color: #111115;
    min-height: 566px;
    width: 100%;
    padding: 32px 16px 16px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include breakpoint($bp1) {
      background-position: center;
      background-size: cover;
    }
  }
  .is-active-banner-head {
    background-image: url('/joxi/fezbet/ferrari-promo/Mobile-Active.jpg');
  }
  .is-inactive-banner-head {
    background-image: url('/joxi/fezbet/ferrari-promo/Mobile-Coming-soon.jpg');
  }

  &__btn {
    margin-top: 200px;
  }

  .show-right-tooltip .ferrari-tooltip.is-right {
    animation: fadeInOutRight 0.8s linear forwards;
    display: block;
    opacity: 1;

    &:lang(ar) {
      animation: fadeInOutRightArab 0.8s linear forwards;
    }
  }

  .show-center-tooltip .ferrari-tooltip.is-center {
    animation: fadeInOutCenter 0.8s linear forwards;
    display: block;
    opacity: 1;

    &:lang(ar) {
      animation: fadeInOutCenterArab 0.8s linear forwards;
    }
  }

  @keyframes fadeInOutCenter {
    0% {
      opacity: 0;
      transform: translate(83px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(83px, -30px);
    }
    40% {
      transform: translate(83px, -15px);
    }
    60% {
      transform: translate(83px, -25px);
    }
    80% {
      transform: translate(83px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(83px, -15px);
    }
  }

  @keyframes fadeInOutRight {
    0% {
      opacity: 0;
      transform: translate(150px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(150px, -30px);
    }
    40% {
      transform: translate(150px, -15px);
    }
    60% {
      transform: translate(150px, -25px);
    }
    80% {
      transform: translate(150px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(150px, -15px);
    }
  }

  @keyframes fadeInOutCenterArab {
    0% {
      opacity: 0;
      transform: translate(-88px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(-88px, -30px);
    }
    40% {
      transform: translate(-88px, -15px);
    }
    60% {
      transform: translate(-88px, -25px);
    }
    80% {
      transform: translate(-88px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(-88px, -15px);
    }
  }

  @keyframes fadeInOutRightArab {
    0% {
      opacity: 0;
      transform: translate(-150px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(-150px, -30px);
    }
    40% {
      transform: translate(-150px, -15px);
    }
    60% {
      transform: translate(-150px, -25px);
    }
    80% {
      transform: translate(-150px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(-150px, -15px);
    }
  }
}
