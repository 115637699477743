$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cookie-banner {
  position: fixed;
  bottom: 0;
  max-width: 1024px;
  width: 100%;
  z-index: 900;
  margin-bottom: 56px;

  &__wrap {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    @include text(12px, 16px, 400, #3F3F3F);
  }

  &__text {
    flex: 1 1 auto;
    font-size: 12px;
    line-height: 16px;
    color: #3F3F3F;

    a {
      color: currentColor;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include breakpoint($bp1) {
      padding-right: 12px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    min-width: 95px;
    flex-shrink: 0;
    padding: 6px 18px;
    background-color: #ffdc26;
    box-shadow: inset 0 10px 12px #fffc, inset 0 -10px 12px #e88e0f;
    text-decoration: none;
    @include text(14px, 24px, 700, #19191D);
  }
}
