$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.activation-rules {
  &__wrap {
    max-width: 280px;
    width: 100%;
  }
  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
  }
  &__wrapper {
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    padding: 8px 12px 12px;
    margin-top: 20px;
  }
  &__key {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #868A92;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__val {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #19191D;
    margin-top: 4px;
    margin-bottom: 0;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 44px;
    max-width: 200px;
    width: 100%;
    text-decoration: none;
    margin: 36px auto 0;
  }
  &__link {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    color: #19191D;
    text-transform: uppercase;
    margin-top: 30px;
    background: none;
    border: none;
  }
}