$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-contacts{
    font-family: var(--font-family-main);
    position: relative;
    display: inline-block;
    height: 32px;
    padding: 0 2px;
    @include ellipsis;
    @include text(14px, 32px, 400, $black);
    padding-left: 26px;
    background-size: 20px;
    background-position: left center;
    background-repeat: no-repeat;

    @include hover(){
        color: #000000;
        filter: $filterGrey;
    }
}
