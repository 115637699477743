$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-latest-win {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
    &__title {
        width: 100%;
        @include ellipsis;
        height: fit-content;
    }
    &__sum {
        @include text(20px, 24px, bold, $yellow);
        @include ellipsis;
        margin-bottom: 4px;
    }

    &__img {
        border-radius: 8px;
        width: 78px;
        height: 52px;
        flex-shrink: 0;
        background-color: green;
        background-size: cover;
        background-position: center;
    }
    &__list {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overscroll-behavior: none;
        &::-webkit-scrollbar {
            background: transparent;
        }
    }
    &__content {
        width: calc(100% - 86px);
        margin-right: 8px;
    }
    &__item {
        height: 78px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
    }
    &__text {
        @include text(14px, 20px, normal, $black);
        @include ellipsis;
    }
    &__link {
        @include text(14px, 20px, normal, $black);
        text-decoration: underline;
    }
    &__grad {
        width: calc(100% - 32px);
        height: 20px;
        position: absolute;
        bottom: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f6f6f4 100%);
    }
}
