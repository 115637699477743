$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.search-clear {
    @include text(16px, 24px, 400, #fff);

    position: absolute;
    z-index: 10;
    top: 6px;
    right: 56px;
    display: none;
    text-decoration: underline;

    .rtl-lang & {
        right: unset;
        left: 56px;
    }
}
