$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: #2D2D32;
  width: 100%;
  height: $headerHeight;
  transition: transform .3s,  opacity .3s;


  &__wrap {
    display: flex;
    align-items: center;

    & > *:last-child {
      display: flex;
      margin-left: 8px;

      .rtl-lang & {
        margin-left: unset;
        margin-right: 8px;
      }
    }
  }

  body.linda-has-search-mode & {
    transform: translateY(-100%);
    opacity: 0;
  }

  &__promo {
    $root: &;
    background: rgba(255, 220, 38, 0.1);
    flex-shrink: 0;
    display: none;
    align-items: center;
    text-decoration: none;
    font-family: $font-roboto;
    padding-inline: 4px 10px;
    min-height: 28px;
    border-radius:  35px;
    margin-right: 8px;
    margin-left: auto;

    @include breakpoint($bp1){
      display: flex;
    }

    @include hover {
      #{$root}-name {
        color: $yellow;
      }
    }

    &:lang(ar) {
      margin-right: auto;
      margin-left: 8px;
    }

    &-img {
      width: 35px;
      height: 29px;
      margin-right: -2px;
      position: relative;
      left: -6px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: -2px;
        left: auto;
        right: -6px;
      }
    }

    &-name {
      text-transform: uppercase;
      @include text(12px, normal, 400, $white);
      letter-spacing: 0.36px;
    }
  }
}
