$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.missed-data {
  background-image: url($cdn + '/fezbet/elements/pattern.png');
  background-size: 1000px auto;
  background-position: 10% -55%;
  font-size: 14px;
  line-height: 135%;
  color: $black;

  @include breakpoint(540px) {
    background-position: center top;
  }

  &.screen {
    padding: 0;
  }

  &__container {
    padding: 24px 14px;
    box-sizing: border-box;
    min-height: 100vh;
    display: grid;
    grid-template-rows: min-content auto;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
  }

  &__logo {
    display: block;
    width: 120px;
    margin: 0 auto;
  }

  &__head {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-family: var(--font-family-main);
    font-size: 24px;
    line-height: 115%;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__content {
    position: relative;
    max-width: 620px;
    width: 100%;
    margin: 0 auto;
  }

  &__terms {
    position: relative;
    max-height: 400px;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 115%;
    padding-right: 16px;
    overflow-y: auto;
    overflow-x: hidden;

    .rtl-lang & {
      padding-right: unset;
      padding-left: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__info-section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__info-inner {
    position: relative;
    display: block;
    max-height: 150px;
    overflow: hidden;

    &::after {
      content: "";
      width: 100%;
      height: 20px;
      background-image: linear-gradient(#2d2d3200 0%,#fff 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .is-open & {
      max-height: fit-content;

      &::after {
        content: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .form-checkbox {
    width: 100%;
    max-width: 407px;

    &:last-child {
      margin-bottom: 8px;
    }

    &__input {
      margin-top: 0;
    }

    .form-error {
      padding: 4px 0 0 28px;
    }

    &__text {
      margin-top: 0;
    }
  }

  &__btn {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__decline {
    color: $grey;
    font-size: 15px;
    line-height: 140%;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .form-check__text {
    color: $white;
  }

  h1, h2, p {
    margin: 0;
  }

  .info {
    padding: 0;

    h1, h2, p, section, table {
      display: block;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    table {
      max-width: 100%;
      overflow-x: auto;
    }

    td, th {
      padding: 4px;
    }

    section {
      background: none;
      border-radius: 0;
    }

    h1 {
      font-size: 18px;
      line-height: 120%;
      font-weight: 700;
    }

    h2 {
      font-size: 16px;
      line-height: 115%;
      font-weight: 700;
    }

    .text2 strong {
      font-weight: 400;
      color: #ffde00;
    }

    a {
      text-decoration: underline;
    }
  }
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  margin: 0 auto;
  color: $yellow;
  font-size: 16px;
  line-height: 125%;
  font-weight: 700;

  &__btn {
    margin-right: 3px;

    &_less {
      display: none;

      .is-open & {
        display: block;
      }
    }

    &_more {
      .is-open & {
        display: none;
      }
    }
  }

  &::after {
    content: '';
    background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.023 6.33245C6.20511 6.32811 6.37915 6.25501 6.51145 6.12731L11.1087 1.62595C11.249 1.48744 11.3298 1.29786 11.3332 1.09874C11.3367 0.899628 11.2626 0.707232 11.1273 0.563725C10.9919 0.420218 10.8062 0.337309 10.611 0.33317C10.4158 0.329032 10.227 0.404002 10.0859 0.541647L6.00002 4.5448L1.91416 0.541647C1.77306 0.404002 1.58421 0.329032 1.389 0.33317C1.19379 0.337309 1.00815 0.420218 0.872772 0.563725C0.737395 0.707231 0.66332 0.899627 0.666793 1.09874C0.670266 1.29786 0.750996 1.48744 0.891292 1.62595L5.48858 6.12731C5.55976 6.19611 5.64369 6.2497 5.73547 6.28493C5.82725 6.32015 5.92501 6.33631 6.023 6.33245Z' fill='%23FFDC26'/%3E%3C/svg%3E%0A");
    background-size: cover;
    background-repeat: no-repeat;
    width: 11px;
    height: 6px;
    position: relative;
    transition: transform .3s ease;
  }

  &.is-open::after {
    transform: rotate(180deg);
  }
}

.missed-data-popup {
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-family: var(--font-family-main);
    font-size: 32px;
    line-height: 125%;
    font-weight: 700;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__btn {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__decline {
    color: $grey;
    font-size: 15px;
    line-height: 140%;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.no-scroll {
  overflow: hidden;
}
