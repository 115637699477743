$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-checkbox {
    width: 100%;
    min-height: 36px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @include hover(){
        .form-checkbox__input {
          border-color: $black;
        }
    }
    &__input {
      margin-top: 8px;
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 1px solid $grey;
      border-radius: 4px;
      transition: .2s all;
      &::after {
        display: none;
        content: '';
        width: 100%;
        height: 100%;
        background-image: var(--icon-checked);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;
      }

      input:checked ~ & {
        &::after {
          display: block;
        }
      }
    }
    &__text {
        @include text(16px, 20px, 400, $black);
        margin: 9px 0 0 8px;
        width: calc(100% - 36px);
        min-height: 24px;
        word-break: break-word;
        text-align: left;

      .rtl-lang & {
        text-align: right;
        margin: 9px 8px 0 0;
      }
        .is-light &{ color: $white; }
    }
    a{
        color: $black;
        text-decoration: underline;
        @include hover(){
            text-decoration: none;
        }
    }
    input {
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
        
        &.ng-invalid.ng-touched ~ .form-checkbox__input,
        &.ng-invalid-required.ng-touched  ~ .form-checkbox__input{
            border-color: $colorError;
        }
    }
  }
