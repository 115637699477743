$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sidemenu {
    &__item {
        height: 50px;
        padding: 8px 40px 9px 72px;
        position: relative;

        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
        background-position: left 23px bottom 1px, left 23px bottom;
        background-repeat: no-repeat;
        background-size: calc(100% - 32px) 1px;

        @include text(15px, 32px, bold, $grey);
        @include ellipsis;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-family: var(--font-family-main);
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 46px;
            width: 6px;
            height: 6px;
            border: 1px solid $grey;
            border-radius: 50%;
            transform: translateY(-50%);
        }
    }

    /* Santa promotion */
    &__santa-promo {
        overflow: hidden;
        color: $white;
        font-size: 18px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid rgba(207, 30, 30, 0.60);
        background-image: url('/joxi/fezbet/santa-slope/promo-item-menu@2x.webp');
        background-size: cover;
        font-weight: 600;
        text-decoration: none;
        padding-inline-end: 12px;
        line-height: 100%;
        letter-spacing: 0.6px;
        font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 8px 16px 0px;

        &::before {
            content: '';
            display: block;
            background: url('/joxi/fezbet/santa-slope/santa-sidebar-image@2x.webp');
            background-size: contain;
            width: 62px;
            height: 52px;
            position: relative;
            bottom: -5px;
            inset-inline-start: -14px;
            margin-inline-end: -5px;
        }

        &::after {
            content: '';
            margin-inline-start: auto;
            width: 24px;
            height: 24px;
            background-color: rgba(255, 255, 255, 0.30);
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuMTY2MzcgMTAuMzg0OUM0LjI0NDA4IDEwLjQ2MTcgNC4zNDU2NCAxMC41IDQuNDQ3NCAxMC41QzQuNTQ5MTUgMTAuNSA0LjY1MDcxIDEwLjQ2MTcgNC43Mjg0MiAxMC4zODQ5TDguODgzMzkgNi4yNzc2NkM5LjAzODYxIDYuMTI0MjMgOS4wMzg2MSA1Ljg3NTUyIDguODgzMzkgNS43MjIxTDQuNzI4NDIgMS42MTUwN0M0LjU3MzIgMS40NjE2NCA0LjMyMTU5IDEuNDYxNjQgNC4xNjYzNyAxLjYxNTA3QzQuMDExMTUgMS43Njg1IDQuMDExMTUgMi4wMTcyMSA0LjE2NjM3IDIuMTcwNjRMOC4wNDAzMSA1Ljk5OTg4TDQuMTY2MzcgOS44MjkxMkM0LjAxMDk1IDkuOTgyNzQgNC4wMTA5NSAxMC4yMzEzIDQuMTY2MzcgMTAuMzg0OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
            background-size: 12px;
            background-position: center;
            background-repeat: no-repeat;
            padding: 6px;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.2);
        }

        &:lang(ar)::after,
        &:lang(ar)::before {
            transform: scaleX(-1);
        }
    }
}
