$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup__title {
  width: calc(100% - 26px);
  min-width: calc(100% - 26px);
}

.popup__wrap.shop-item-crab-congratulation-popup {
  max-width: 320px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 110px;
    //background-image: url('/img/shop-banner.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .popup__container {
    padding: 30px;
  }
}

.popup__content {
  padding-top: 10px;
}

.shop-crab-congratulation {
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: grey;
    margin-bottom: 4px;
  }

  &__banner {
    display: block;
    width: 222px;
    height: 157px;
    //background-image: url('/img/shop-logo-crab.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__btn {
    a {
      width: 104px;
      height: 32px;
      margin: 0 auto;
      color: #000000;
    }
  }
}
