$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-events {
  &__wrap {
    background: rgba(255, 220, 38, 0.1);
    padding: 12px 0;
  }
  &__item {
    width: 242px;
    height: 60px;
    display: inline-flex!important;   
    align-items: center; 
    padding: 0 18px 0 16px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%);
    background-size: 1px 100%;
    background-repeat: no-repeat;
    background-position: right 1px center, right center;
    &:last-child {background-image: none;}
  }
  &__icon {
    width: 90px;
    height: 60px;
    border-radius: 8px;
    margin-right: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    @include hover() {opacity: .85; }
  }
  &__text {
    max-width: 106px;
    p {
      @include text(16px, 28px, bold, $yellow);
      @include ellipsis;
      text-transform: uppercase;
      margin-bottom: 4px;
    }
    a { 
      display: flex;      
      span { 
        @include text(12px, 20px, normal, $white);
        @include ellipsis;
      }
      span:nth-child(2) {
        color: $yellow;
        text-decoration: underline;      
        margin-left: 4px;        
      }
      @include hover() {
        span:nth-child(2) {opacity: .85; }
      }
    }
  }
}