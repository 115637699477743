$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-item {
  background-color: $white;
  border-radius: 20px;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) 20px;

  .rtl-lang & {
    background-position: 8px 20px;
  }

  &.sport {
    background-image: var(--icon-promo-preview-sport);
  }

  &.casino {
    background-image: var(--icon-promo-preview-casino);
  }

  &.disable {
    background-image: var(--icon-icon-disable);
  }

  &.is-euro-promo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 14px 16px;
    min-width: 260px;
    min-height: 144px;
    background-size: 32px;
    background-position: top 12px right 12px;
    background-repeat: no-repeat;

    &:not(:last-child) {
      margin-inline-end: 12px;
    }
  }

  &__info-wrap {
    display: grid;
  }

  &__title {
    padding-inline-end: 20px;
    font-family: var(--font-family-main);
    @include text(20px, 28px, 700, $black);
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: normal;

    .is-euro-promo & {
      font-size: 20px;
    }
  }

  &__subtitle {
    margin: 10px 0;
    font-family: var(--font-family-main);
    @include text(28px, 32px, 700, $orange);
    text-transform: uppercase;
    white-space: normal;

    .is-euro-promo & {
      font-size: 36px;
      margin: 4px 0 14px;
    }

    &.is-future-promo {
      opacity: 0.2;
    }
  }

  .title-font {
    font-size: 28px;
  }

  .small-font {
    font-size: 40px;
    line-height: 40px;
  }

  &__text {
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #28272A;
    margin-bottom: 16px;
    white-space: normal;
    flex-grow: 1;
    height: auto;
    overflow: hidden;
  }

  &__btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: auto;

    &.is-future-promo {
      opacity: 0.2;
      pointer-events: none;
    }

    &.promo-page {
      position: relative;
      height: 40px;
      overflow: hidden;

      .btn {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
      }
    }

    .btn {
      .linda-has-remembered-login & {
        &.wasnt-logged {
          display: none;
        }

        &.was-logged {
          display: inline-block;
        }
      }

      &.was-logged {
        display: none;
      }
    }

    &:lang(el) {
      .is-h36.btn {
        padding: 0 10px;
        font-size: 12px;
      }
    }
  }

  .link-arrow {
    margin-inline-start: 12px;
  }

  button.holiday-button {
    filter: unset;
    background-color: unset;
  }

  .layout-horiz-promo & {
    padding: 20px 20px 12px;

    .btn {
      margin-bottom: 8px;
    }
  }

  &__summer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @include breakpoint($bp2) {
    &.is-euro-promo {
      padding: 32px 32px 26px 32px;
      min-width: 358px;
      min-height: 200px;
    }

    &__title {
      .is-euro-promo & {
        font-size: 24px;
      }
    }

    &__subtitle {
      .is-euro-promo & {
        font-size: 24px;
        margin: 14px 0 24px;
      }
    }
  }
}
