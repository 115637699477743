$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-container {
  width: 100%;
  height: 100%;
  @include breakpoint($bp1) {
    height: auto;
    display: flex;
    justify-content: center;
  }
}
.is-ferrari-popup-promo {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.ferrari-popup {
  font-family: 'Roboto Condensed', sans-serif;
  color: #19191d;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 52px 24px 60px 24px;
  flex-direction: column;
  align-items: center;
  background: #f6f6f4;
  @include breakpoint($bp2) {
    max-width: 573px;
    border-radius: 20px;
    height: auto;
    margin: 0 12px;
  }
  &__close {
    top: 17px;
    right: 17px;
    background: var(--icon-close) center no-repeat;
    width: 48px;
    height: 48px;
    z-index: 2;
    background-size: contain;
    position: absolute;
    @include hover {
      opacity: 0.7;
    }
  }
  &__title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;

    @include breakpoint($bp2) {
      width: 85%;
      margin: 0 auto;
    }
  }
  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 235px;
    width: 100%;
    max-width: 574px;
    margin: 20px 0 16px;

    @include breakpoint($bp2) {
      margin: 24px 0;
    }
  }
  &__text {
    text-align: center;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.03px;
    font-weight: 500;
    &.is-bold {
      font-weight: 700;
    }
    &.is-red {
      color: #ff1e60;
    }
  }
  &__btn {
    margin-top: 24px;
    padding: 0 44px;
    flex-shrink: 0;
    letter-spacing: 0;

    @include breakpoint($bp1) {
      letter-spacing: 0.54px;
    }
  }
}
