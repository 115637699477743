$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-container {
    margin-bottom: 8px;
    padding-bottom: 72px;
    height: 100%;
    overflow-x: hidden;
    position: relative;
    &.is-sport-tab .is-sport,
    &:not(.is-sport-tab) .is-casino {
        background-color: transparent;
        color: $white;
    }
    &.is-sport-tab .is-sport-categories,
    &:not(.is-sport-tab) .is-casino-categories {
        display: block;
    }
}