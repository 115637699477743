$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-sport {
  .rtl-lang & {
    direction: ltr;
  }

  .notification-a2hs {
    padding: 8px 16px;
  }

  &__frame:empty {
    min-height: 100vh;
  }
}
