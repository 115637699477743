$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-gamehall {

  background: #19191D;

  padding: 0 8px 0;

  @include breakpoint( $bp1 ){
    padding: 0 20px;
  }

}
