$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop-spin-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  background-color: $white;
  border-radius: 20px;

  &__info {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 12px;
    flex-grow: 1;
  }

  &__bg {
    width: 80px;
    height: 120px;
    border-radius: 8px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px 0 rgba(25, 25, 29, 0.5);
  }

  &__content {
    color: $black;
  }

  &__game {
    margin-bottom: 12px;
    text-transform: uppercase;
    line-height: 120%;
    font-size: 24px;
    font-weight: 700;
  }

  &__price {
    width: max-content;
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 14px;
    line-height: 120%;
    font-weight: 700;
  }

  &__price-value {
    direction: ltr;
    display: inline-flex;
  }

  &__btn {
    margin-top: 12px;
  }

  a {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 44px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }
}
