$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.bonus-change {
  &__title {
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }

  &__text {
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.42px;
  }

  &__item {
    &-box {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 182px;
      padding: 10px 0;
      border: 4px solid $yellow;
      border-radius: 16px;
      overflow: hidden;
      box-shadow: inset 0 1px 1px #00000040;
      box-sizing: border-box;

      &.is-new {
        margin-bottom: 20px;
        border-color: $red;
      }
    }

    &-cover {
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: 0;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      background-image: linear-gradient(#2d2d32 0%, #2d2d3299 100%), url($cdn + '/fezbet/bonuses/casino.jpg');
      background-position: center;
      background-size: cover;
      filter: blur(4px);

      &.is-sport {
        background-image: linear-gradient(#2d2d32 0%, #2d2d3299 100%), url($cdn + '/fezbet/bonuses/sport.jpg');
      }
    }

    &-label {
      position: absolute;
      top: 14px;
      left: -34px;
      width: 120px;
      padding: 0 30px;
      transform: rotate(-45deg);
      background-color: $yellow;
      color: $black;
      font-family: Fez, Arial, Helvetica, sans-serif;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      line-height: 32px;
      text-overflow: ellipsis;
      overflow: hidden;

      &:lang(ar) {
        transform: rotate(45deg);
        right: -32px;
        left: unset;
      }

      &.is-new {
        background-color: $red;
        color: $white;
      }
    }

    &-icon {
      position: relative;
      top: 16px;
      width: 32px;
      height: 32px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 32px;

      &.icon-betting {
        background-image: var(--icon-betting);
      }

      &.icon-casino {
        background-image: var(--icon-casino);
      }
    }

    &-amount {
      position: relative;
      margin: 32px 0 8px;
      padding: 0 20px;
      color: $yellow;
      font-family: Fez, Arial, Helvetica, sans-serif;
      font-size: 36px;
      font-weight: bold;
      line-height: 44px;
      text-align: center;
    }

    &-name {
      position: relative;
      margin-bottom: 14px;
      color: $white;
      line-height: 24px;
      text-transform: capitalize;

      a {
        color: $yellow;
        text-decoration: underline;
      }
    }
  }

  &__arrow {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    background-image: var(--icon-arrow-down-y);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__btns {
    &-item {
      button {
        margin-bottom: 0;
      }
    }
  }
}
