$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-terms {
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  &.is-terms-open {
    .ferrari-terms__btn1 {
      display: none;
    }
    .ferrari-terms__btn2 {
      display: block;
    }
    .ferrari-info-page-terms .ferrari-terms-info {
      max-height: 3000px;
      overflow: scroll;
      &::before {
        z-index: -1;
      }
    }
  }
  &__show {
    margin-bottom: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
