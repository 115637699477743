$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-banner {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin: 0 8px;
	padding-bottom: 32px;
	background-color: #670633;
	background-size: auto 100%;
	background-position: center top;
	border-radius: 10px;

	&__link {
		min-width: 238px;
		height: 60px;
		text-decoration: none;
		line-height: 60px;
		white-space: nowrap;
		font-size: 24px;
	}

	@include breakpoint($bp1) {
		width: 484px;
		margin: 0;
		border-radius: 5px 0 0 5px;
	}

	@include breakpoint($bp2) {
		width: 460px;
	}

	@include breakpoint($bp3) {
		width: 635px;
	}
}