$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-tag {

  height: 48px;

  background: rgba(255, 220, 38, 0.1);
  border-radius: 22px/30px;

  border: 0 solid transparent;
  border-width: 8px 0;
  background-clip: padding-box;
  padding: 4px 16px;
  display: inline-block;

  font-family: var(--font-family-main);
  line-height: 24px;
  text-transform: uppercase;

  @include hover(){
    background-color: rgba(255, 220, 38, 0.3);
  }

}
