$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-tabs {
  display: flex;
  position: relative;

  &.is-active-categories {
    .game-tab.is-categories {
      color: $white;
      background: #2D2D32;
      border-radius: 12px 12px 0 0;
    }

    .game-category-list.is-categories {
      display: flex;
    }
  }

  &.is-active-providers {
    .game-tab.is-providers {
      color: $white;
      background: #2D2D32;
      border-radius: 12px 12px 0 0;
    }

    .game-category-list.is-providers {
      display: flex;

      .game-title {
        padding-left: 15px;

        .rtl-lang & {
          padding-left: 40px;
          padding-right: 56px;
        }

        h1 {
          padding-left: 42px;
        }

        img {
          display: block;
          position: absolute;
          width: 32px;
          height: 32px;
          padding: 6px;
          margin-right: 8px;
          background-color: rgba(65, 65, 65, 1);
          border-radius: 8px;
          box-shadow: 0 -4px 6px 0 rgba(34, 34, 34, 1) inset, 0 4px 4px 0 rgba(255, 255, 255, 0.23) inset;

          .rtl-lang & {
            right: 0;
          }
        }
      }
    }
  }

  &.is-active-feature {
    .game-tab.is-feature {
      color: $white;
      background: #2D2D32;
      border-radius: 12px 12px 0 0;
    }

    .game-category-list.is-feature {
      display: flex;
    }
  }

  body.linda-has-search-mode & {
    display: none;
  }
}
