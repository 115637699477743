$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-terms-title {
  text-align: center;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  background-image: none;
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.ferrari-terms-info {
  background-color: #19191d;
  color: #fff;
  max-height: 200px;
  margin: 0 0 20px;
  overflow: hidden;
  transition: max-height 0.8s ease;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 0px 20px #19191d;
    filter: blur(20px);
    box-shadow: 0 -5px 35px 35px #19191d;
    pointer-events: none;
  }
}

.ferrari-terms-info h2 {
  background-image: none;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.ferrari-terms-info section {
  max-width: 768px;
  padding: 0;
  background-color: #19191d;
}

.ferrari-terms-info section p {
  position: relative;
  text-align: start;
  color: #fff;
  font-size: 16px;
  margin-bottom: 12px;
  padding-inline-start: 24px;
}

.ferrari-terms-info span {
  position: absolute;
  inset-inline-start: 0;
}

.ferrari-terms-info h3 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}
