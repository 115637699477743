$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup__terms {
  &-title {
    margin-bottom: 20px;
    color: $black;
    text-align: center;
    font-size: 28px;
    font-family: var(--font-family-main);
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;

    @include breakpoint($bp1) {
      font-size: 32px;
    }
  }

  &-list {
    list-style: decimal;
    margin-bottom: 84px;
    padding-left: 24px;
  }

  &-item {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 140%;
    text-align: left;

    span {
      white-space: nowrap;
    }

    &::marker {
      font-weight: 700;
    }
  }

  &-btns {
    text-align: center;

    a {
      text-decoration: none;
    }
  }
}
