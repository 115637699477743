$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-horiz{
    position: relative;
    width: 100%;

    & > * {
        line-height: 0;

        position: relative;
        display: block;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        @include without-scrollbar();

        white-space: nowrap;

        & > * {
            display: inline-block;
        }
    }

    &.layout-horiz-promo > * > * {
        display: inline-flex;
    }

    &.is-cashbox{
        margin: 0 -20px;
        width: calc(100% + 40px);
        @include breakpoint($bp2){
            margin: 0 -87px;
            width: calc(100% + 174px);
        }
        position: relative;
            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 10px;
                height: 100%;
                background-image: linear-gradient(90deg, #F6F6F4 0%, rgba(246, 246, 244, 0) 100%);
                z-index: 3;
            }
            &:after {
                left: auto;
                right: 0;
                background-image: linear-gradient(90deg, rgba(246, 246, 244, 0) 0%, #F6F6F4 100%);
            }
        .cashbox-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 12px 8px 0;

            .cashbox-amount {
                margin: 0 4px 8px;
                width: 90px;

                @include breakpoint( $bp00){
                    width: 100px;
                }

                @include breakpoint( $bp0){
                    width: 132px;
                }
            }
        }

        .cashbox-amount {
            min-width: 132px;
            width: auto;
        }
    }

    &.tournament-page__layout-horiz  > * {
        display: flex;
    }
}
