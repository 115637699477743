$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-body {

  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  min-height: 100%;

  background: $lightGrey;
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  color: $black;

  @include breakpoint($bp1) {
    border-radius: 20px;
    max-width: 400px;
    margin: 40px auto;
    min-height: 200px;
    &__btn-bankid {
      margin-bottom: -84px;
    }
    &.has-padding {
      padding: 0 60px 40px;
    }
    .pay-n-play & {
      margin: 40px auto 104px;
    }
  }

  &_missed-data {
    justify-content: center;

    @include breakpoint($bp1) {
      border-radius: 0;
      max-width: none;
      margin: auto;
      min-height: 100%;
    }
  }

  &__btn-bankid {
    margin: 20px auto;
    width: fit-content;
  }

  &.has-padding {
    padding: 12px 20px 40px;
  }
}
