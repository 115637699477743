$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-contacts{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a{
        margin: 0 6px;
    }
    @include breakpoint($bp1){
        margin: 0 8px;
    }
}