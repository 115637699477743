$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-terms {
  color: $black;

  &__title {
    margin-top: 34px;
    margin-bottom: 16px;
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  p {
    position: relative;
    padding-inline-start: 36px;
    margin-bottom: 10px;
    text-align: justify;

    bdi {
      display: contents;
    }

    span,
    strong {
      position: absolute;
      inset-inline-start: 0;
      font-weight: 700;
    }
  }

  a {
    display: contents;
  }

  table {
    margin: 10px 0 20px 0;
    width: 100%;

    td,
    th {
      border: 1px solid #48526b;
      padding: 8px;
      text-align: center;
    }

    tr:first-child {
      td {
        color: #000;
      }
    }
  }

  ul,
  ol {
    font-size: 16px;
    padding-inline-start: 48px;
    padding-bottom: 10px;

    li {
      padding-bottom: 6px;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }
}
