$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
// exchange card
.shop-exchange-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 152px;
  height: 100%;
  min-height: 186px;
  padding: 20px 12px 12px;
  transition: background .2s ease;
  background: $white;
  border-radius: 20px;
  color: $darkGrey;

  &__icon {
    flex-grow: 1;
    width: 100%;
    height: 115px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    direction: ltr;
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
    transition: transform .2s ease;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 32px;
    margin-top: 12px;
    box-shadow:
      0 10px 12px 0 rgba($white, 0.8) inset,
      0 -10px 12px 0 #E88E0F inset,
      0 0 0 4px rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: none;
      transition: none;
      transform: none;
    }

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__btn-icon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
