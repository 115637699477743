$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.world-cup-sidebar-item {
    display: flex;
    align-items: center;
    padding-right: 20px;

    &::before {
        display: none;
    }

    & .is-summer-icon {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 24px 24px;
        left: 24px;
        position: absolute;
        animation: rotateIcon 3s linear infinite;
    }

    &.active {
        color: $yellow;
    }

    &:after {
        align-items: center;
        background: #E12D2D;
        box-shadow: inset 0 -2px 3px rgba(60, 18, 5, .4), inset 0 2px 3px rgba(255, 255, 255, .3);
        color: #fff;
        content: "NEW";
        display: flex;
        font-size: 10px;
        height: 18px;
        justify-content: center;
        width: 32px;
    }

    .season-name {
        margin-right: auto;
    }
}

.easter-slider {
    height: calc(100vh - 160px);

    .swiper-slide {
        height: auto;
    }
}

.popup {
    &.popup-easter {
        display: flex;
        height: 100%;
        padding: 22px 0;

        .easter-popup {
            margin: auto;
        }
    }
}

@keyframes rotateIcon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
