$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.lang-btn {
  @include ellipsis;

  display: flex;
  align-items: center;
  padding: 0 4px;
  height: 48px;
  font-family: var(--font-family-main);
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $black;
  background-size: 24px;
  background-position: 6px center;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: 0 solid $lightGrey;
  border-width: 6px 0;
  max-width: 100%;
  transition: background-color .3s;

  &:before {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    border: 2px solid $black;
    border-radius: 50%;
    margin-right: 4px;
    flex-shrink: 0;
  }

  .rtl-lang & {
    background-position: calc(100% - 10px) center;
  }

  &.is-active {
    background-color: rgba(255, 220, 38, 0.3);
  }

  @include hover(){
    background-color: rgba(255, 220, 38, 0.3);
  }
}
