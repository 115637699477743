$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-small {

  font-weight: normal;
  font-size: 14px;
  line-height: 48px;
  text-align: center;
  text-decoration: underline;
  color: $black;
  white-space: nowrap;
  @include hover(){
    color: $grey;
  }

  &.is-grey {
    color: $grey;
    @include hover(){
      color: $black;
    }
  }

}
