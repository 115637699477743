$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-title {
  padding-top: 36px;
  font-family: var(--font-family-main);
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: $black;
}

.normal-title {
  margin: 0 0 12px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
}
