$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-icon-category {
  height: 66px;
  display: inline-flex!important;
  align-items: center;
  background-color: rgba(255, 220, 38, 0.1);
  border-radius: 16px;
  margin-right: 16px;
  @include text(14px, 20px, normal, $white);
  padding: 0 12px 0 74px;
  
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 66px;
  overflow: hidden;
  margin-right: 8px;
  
  @include hover() {
    background-color: rgba(255, 220, 38, 0.3);
    color: $yellow;
  }
}