$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-seo {
    padding-bottom: 20px;
    background-size: 200%;
    background-position: top left;
    &__item {
        margin-bottom: 20px;
        padding: 14px 20px 0 53px;
        background-image: url( $cdn + '/fezbet/elements/small_circle.png');
        background-repeat: no-repeat;
        background-size: 45px;
        background-position: 8px 0;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-size: 100%;
            width: 21px;
            height: 21px;
            top: 12px;
            left: 20px;
            filter: invert(100%);
        }

        &:last-child {
            margin-bottom: 0;
        }

        .rtl-lang & {
            background-position: calc(100% - 8px) 0;
            padding: 14px 53px 0 20px;

            &::before {
                left: unset;
                right: 20px;
            }
        }
    }
    &__chat::before {
        background-image: var(--icon-contacts-live-chat);
    }
    &__card::before {
        background-image: var(--icon-card);
        filter: none;
    }
    &__promo::before {
        background-image: var(--icon-promotion);
    }
    &__limit::before {
        background-image: var(--icon-check);
    }
    &__title {
        @include text(16px, 20px, bold);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-bottom: 12px;
        font-family: var(--font-family-main);
        color: $white;
    }
    &__description {
        @include text(14px, 20px);
        color: $white;
    }

    @include breakpoint($bp1) {
        background-size: contain;
    }

    @include breakpoint($bp2) {
        padding: 0 84px 20px;
    }
    @include breakpoint($bp3) {
        padding: 0 212px 20px;
    }
}
