$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.search-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    filter: $filterGrey;
    background-size: cover;
    background-position: center;
    transform: translateX(150%);
    opacity: 0;
    z-index: -1;
    transition: 0.3s all;

    .rtl-lang & {
        right: unset;
        left: 0;
    }

    body.linda-has-search-mode & {
        transform: translateX(0);
        opacity: 1;
        z-index: 1;
    }

    @include hover() {
        opacity: .85;
    }

}
