$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.quickdeposit-item {

  display: flex;
  align-items: center;

  height: 40px;
  padding: 4px 0;
  border-radius: 35px;
  background-color: $white;
  background-clip: content-box;

  img {
    width: 66px;
    height: 18px;
    object-fit: contain;
    margin-left: 4px;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $black;
    margin-right: 4px;
    max-width: 80px;
    @include ellipsis;
  }

}
