$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-pre-title {
  
  @include text(14px, 20px, normal, $white);

  margin-bottom: 8px;

  @include breakpoint ($bp1) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
