$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.santa-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 20px;
  gap: 20px;

  @include breakpoint($bp1) {
    padding: 36px 60px;
  }

  &__title {
    color: #19191D;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
  }

  &__description {
    font-family: Arial, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.42px;
    text-align: center;
  }

  &__cta {
    margin-top: 16px;
  }

  &__image {
    max-width: 100%;
    filter: drop-shadow(0px 3px 1.5px rgba(0,0,0,0.25));
  }

  &__details {
    margin-top: 10px;
    color: rgba(134, 138, 146, 1);
  }
}
