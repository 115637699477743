$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.spin-filter {
  position: relative;
  display: flex;
  padding-top: 24px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 24px;
  max-width: 560px;

  &__item {
    text-transform: uppercase;
    z-index: 1;
    color: $grey;
    position: relative;
    display: flex;
    flex: 1 1;
    justify-content: center;
    padding: 12px 4px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    opacity: 0.8;
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $black;
      border: 1px solid $darkGrey;
      left: 50%;
      transform: translateX(-50%);
      bottom: 7px;
      z-index: 1;
      pointer-events: none;
    }

    &.is-active {
      color: $white;
      opacity: 1;

      &::after {
        width: 6px;
        height: 6px;
        background-color: $white;
        border-color: $grey;
        bottom: 6px;
      }
    }
  }

  &.is-profile {
    display: flex;
    width: 100%;
    padding-top: 4px;
    padding-left: 5px;
    gap: 4px;
  }

  .is-profile & {

    &__item {
      padding: 4px 12px;
      width: fit-content;

      &:hover {
      }
      &.is-active,
      &:active {
      }
    }
  }

  .gradient-lines {
    position: absolute;
    bottom: 8px;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 2px;

    .line1 {
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 50%, rgba(255, 255, 255, 0.00) 100%);
    }

    .line2 {
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 50%, rgba(0, 0, 0, 0.00) 100%);
    }
  }
}

.spin-filtered__grid {
  position: relative;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr;

  @include breakpoint($bp1) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint($bp3) {
    grid-template-columns: repeat(3, 1fr);
  }
}
