$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-description {

  @include text(12px, 20px, normal, $white);
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;

  @include breakpoint($bp2) {
    padding: 0 84px;
  }
  @include breakpoint($bp3) {
    padding: 0 212px;
  }

}
