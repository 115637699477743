$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.season-banner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  height: 222px;
  padding: 20px 32px;
  background-color: #f00;
  background-image: url( $cdn + '/fezbet/tennis-tournament/australian-open-banner-mobile.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;

  @include breakpoint ($bp1) {
    margin-left: 40px;
    border-radius: 15px 0 0 15px;
  }

  &__btn {
    min-width: 240px;
    height: 60px;
    font-size: 24px;
    font-weight: 700;
    text-decoration: none;
    line-height: 60px;
    z-index: 2;

    .btn-never-loged {
      display: block;
    }
  }
}
