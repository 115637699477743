$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-profile {
    &__item {
        height: 50px;
        padding: 0 40px 0 72px;
        position: relative;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
        background-position: left 12px bottom 1px, left 12px bottom;
        background-repeat: no-repeat;
        background-size: calc(100% - 32px) 1px;
        @include text(15px, 50px, bold, $grey);
        @include ellipsis;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-family: var(--font-family-main);
        transition: transform .2s;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 46px;
            width: 6px;
            height: 6px;
            border: 1px solid $grey;
            border-radius: 50%;
            transform: translateY(-50%);
        }

        .rtl-lang & {
            padding: 0 72px 0 40px;

            &::before {
                left: unset;
                right: 46px;
            }
        }

        &.has-bonuses {
            display: flex;
            align-items: center;
            span {
                @include ellipsis;
            }
            .menu-profile__bonus-count{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $yellow;
                @include text(14px, 20px, bold, $black);
                text-align: center;
                flex-shrink: 0;
                margin-left: 8px;
                white-space: nowrap;
                overflow: hidden;

                .rtl-lang & {
                    margin-left: unset;
                    margin-right: 8px;
                }
            }
        }

        &.active {
            color: $white;
            &:before {
                background-color: $white;
                width: 10px;
                height: 10px;
            }
        }

        @include hover() {
            width: calc(100% + 12px);
            background-position: left bottom 1px, left bottom;
            color: rgba(134, 138, 146, 0.5);
            transform: translateX(-12px);
            transition: transform .2s;       
        
            &:after {
                content: '';
                width: 12px;
                height: 50px;
                background-color: $black;
                position: absolute; 
                top: 0;   
                right: 0;        
            }
        }
    }
}
