$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.notification-a2hs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    &__info {
        padding: 4px 0;
        background-image: url($cdn + '/fezbet/mobile/app.svg');
        background-repeat: no-repeat;
        background-size: 48px;
        padding-left: 56px;
        margin-right: 8px;
    }
    &__title {
        @include text(14px, 16px, normal, $white);
        letter-spacing: 0.05em;
        margin-bottom: 8px;
    }
    &__description {
        @include text(12px, 16px, normal, $grey);
        letter-spacing: 0.05em;
    }
    &__btn {
        flex-shrink: 0;
        max-width: 40%;
    }
    .layout-homepage & {
        padding: 8px 16px;
        &__btn {
            margin: 6px 0;
        }
    }

    @include breakpoint($bp1) {
        .layout-homepage & {
            padding: 8px 20px;
        }
    }

}
