$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-jackpot {  
  height: 220px;
  background-color: #912527;
  background-image: url( $cdn + '/fezbet/main/jackpot.png' );
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;   
  border-radius: 12px; 
  padding: 24px 0 28px;  
  text-align: center;
  overflow: hidden;
  &__title {
    @include text(16px, 20px, bold, $white);
    @include ellipsis;
    font-family: var(--font-family-main);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 28px;
    padding: 0 12px;
  }
  &__ammount {
    @include text(36px, 64px, bold, $yellow);
    @include ellipsis;
    font-family: var(--font-family-main);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    background: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 26px;
  }
  .btn {max-width: calc(100% - 24px);}
  @include breakpoint($bp1) {
    &__ammount { font-size: 56px; }
  }
  @include breakpoint($bp2) {
    grid-column: 1 / 3;
    background-size: cover;
    &__ammount { font-size: 65px; }
  }
  @include breakpoint($bp3) {
    grid-column: 3 / 4;
    background-size: auto 100%;
    &__ammount { font-size: 36px; }
  }
}
