$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.table-title {

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0;
  padding: 0 9px;

  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: $grey;

  @include breakpoint( $bp1 ){
    padding: 0 17px;
  }

}
