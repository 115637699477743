$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-tabs{

    min-height: 100%;
    display: flex;
    flex-direction: column;

    &__tabs{
        width: 100%;
        flex-shrink: 0;
        height: 48px;
        margin-bottom: 12px;
        overflow-y: auto;
    }

    &__view{
        padding: 0 8px 20px;
        flex-grow: 1;
        overflow: hidden;
    }

    @include breakpoint($bp1){
        &__view{
            padding: 0 20px 12px;
        }
    }

    @include breakpoint($bp2) {
        flex-direction: row;
        padding: 12px 28px;

        &__view{
            padding: 0;
        }

        &__tabs {
            width: 136px;
            padding-right: 16px;
            height: 100%;
            margin-bottom: 0;

            .rtl-lang & {
                padding-right: unset;
                padding-left: 16px;
            }

            &._is-wide {
                &:lang(at),
                &:lang(hu) {
                    width: 230px;
                }
            }
        }
    }
    @include breakpoint($bp3){
        &.is-trustly {
           padding: 12px 44px; 
        }
        &__tabs {
            width: 176px;
        }
    }

}
