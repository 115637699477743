$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop-popup._how-to-earn {
  padding: 36px 0;

  @include breakpoint($bp1) {
    padding: 36px 40px;
  }
}

.shop-how-to-earn {
  display: grid;
  grid-template-columns: 1fr;
  color: $black;
  text-align: left;

  &:lang(ar) {
    text-align: right;
  }

  &__title {
    text-transform: uppercase;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  &__text {
    text-transform: none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
  }

  &__item {
    position: relative;
    display: flex;
    text-decoration: none;
    padding: 12px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 12px;

    .rtl-lang & {
      margin-right: unset;
      margin-left: 12px;
    }

    &._deposit {
      background-image: url($cdn + '/fezbet/shop/deposit-shop-icon.webp');
    }

    &._bets {
      background-image: url($cdn + '/fezbet/shop/bets-shop-icon.webp');
    }

    &._tournaments {
      background-image: url($cdn + '/fezbet/shop/tournament-shop-icon.webp');
    }
  }
}
