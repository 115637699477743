$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-list {
  width: 460px;
  height: 495px;
  margin: 0;
  position: relative;
  transform: translateX(calc(-50% - 90px));
  left: 50%;
  z-index: 1;

  .rtl-lang & {
    transform: translateX(calc(50% - 90px));
    left: unset;
    right: 50%;
  }
}
