$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.modal-amount-row {

  width: 100%;
  background-color: $white;
  border-radius: 8px;
  padding: 8px 12px 12px;

  span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $grey;
    margin-bottom: 4px;
  }

  strong {
    display: block;
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $black;
  }

}
