$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.season-promo{
    position: relative;
    overflow: hidden;

    .screen {
        display: contents;
    }
}
