$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-card {
  background-color: $white;
  box-shadow: 0 8px 16px rgba(25, 25, 29, 0.16);
  border-radius: 20px;
  color: $black;
  width: 200px;
  padding: 16px 0;
  vertical-align: top;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;

  &:nth-child(1) {
    z-index: 5;
    right: 40px;
    top: 0;
  }
  &:nth-child(2) {
    z-index: 4;
    right: 30px;
    top: 5px;
  }
  &:nth-child(3) {
    z-index: 3;
    right: 20px;
    top: 10px;
  }
  &:nth-child(4) {
    z-index: 2;
    right: 10px;
    top: 15px;
  }
  &:nth-child(5) {
    z-index: 1;
    right: 0;
    top: 20px;
  }

  &.is-active {
    background-color: $yellow;

    .vip-card__count {
      color: rgba(246, 246, 244, 0.5);
    }
  }

  &__top {
    padding-bottom: 19px;
    position: relative;
  }

  &__bottom {
    background: linear-gradient(180deg, rgba(85, 85, 85, 0.15) 0%, rgba(85, 85, 85, 0) 36.98%);
    padding: 19px 16px 0;
  }

  &__title {
    font-family: var(--font-family-main);
    @include text(24px, 28px, bold, #19191D);
    text-transform: uppercase;
    padding: 0 16px;
    letter-spacing: 0.03em;

    &:lang(el) {
      font-size: 16px;
    }
  }

  &__label {
    @include text(14px, 24px, normal, $grey);
    letter-spacing: 0.03em;
    padding: 0 16px;
    margin-bottom: 13px;
  }

  &__img {
    background-size: cover;
    width: 180px;
    height: 180px;
    position: absolute;
    right: 0;
    top: 30px;
    &:lang(ar) {
      right: 30px;
      transform: rotateY(180deg);
    }
  }

  &__count {
    width: 180px;
    margin: 0 auto;
    @include text(160px, 120px, bold, $lightGrey);
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    &:lang(ar) {
      letter-spacing: unset;
      margin: 0;
    }
  }

  &__number, &__acc, &__pm {
    font-family: var(--font-family-main);
    @include text(16px, 120%, bold, #19191D);
    margin-bottom: 4px;
    white-space: normal;
  }

  &__acc {
    text-transform: uppercase;
    line-height: 24px;
  }

  &__pm {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    .icon {
      width: 24px;
      height: 24px;
    }

    span {
      margin-right: 2px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%) no-repeat;
      background-position: right center;
      background-size: 1px 24px;
      padding-right: 4px;

      .rtl-lang & {
        background-position: left center;
        margin-right: unset;
        margin-left: 2px;
        padding-right: unset;
        padding-left: 4px;
      }
    }

    @include hover(){
      opacity: .85;
    }
  }

  &__text{
    @include text(12px, 16px, normal, #19191D);
    margin-bottom: 11px;
    &:last-child{
      margin-bottom: 0;
    }
  }

}
