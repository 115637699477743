$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-input {
    $root: &;
    --height: #{$inputHeight};
    -moz-appearance: none;
    -webkit-appearance: none;
    background: $inputBg;
    border-radius: $inputRadius;
    height: var(--height);
    width: 100%;
    padding: 8px 16px 8px;
    padding-inline-end: 34px;
    border: $inputBorder;
    @include text(16px, 24px, 400, $inputColor);
    transition: .2s all;

    &.is-bank-id {
        border-radius: 12px;
        @include text(24px, 24px, bold, $black);
        padding-inline-end: 40px;
        margin: 0 8px;
        text-align: center;
    }

    &::placeholder{ color: $inputColorPlaceholder;}
    &::-webkit-input-placeholder { color: $inputColorPlaceholder;}
    &.ng-invalid.ng-touched:not(.novalidate) {
        border-color: $colorError;
    }

    &.ng-invalid.ng-touched:not(.novalidate):focus, &:focus {
        border-color: $yellow;
        box-shadow: inset 0 0 0 1px $yellow;
    }

    &[disabled] {
      color: $colorDisabled;
      background-color: rgba(134, 138, 146, 0.3);
    }

    &[type="tel"] {
        .rtl-lang & {
            direction: ltr;
            text-align: right;
        }
    }

    &[type="date"] {
        .rtl-lang & {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            text-align: right;
        }
    }

    .is-form-promo &{
        --height: #{$inputPromoHeight};
        border-radius: $inputPromoRadius;
        @include text(20px, null, 700);
        text-align: center;
        @include hover(){
            border-color: $black;
            box-shadow: none;
        }
        &.ng-invalid.ng-touched:not(.novalidate):focus, &:focus {
            &::placeholder{  font-weight: 400;}
            &::-webkit-input-placeholder { font-weight: 400;}
            @include hover(){
                border-color: $black;
                box-shadow: none;
            }
        }
    }

    &.restore-password:lang(el) {
        font-size: 12px;
    }
}
