$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-bottom-item {
  margin-top: auto;
  background: $white;
  padding: 24px 20px;
  text-align: center;

  a {
    text-decoration: underline;
    margin-left: 5px;

    .rtl-lang & {
      margin-right: 5px;
      margin-left: unset;
    }

    @include hover() {
      opacity: .85;
    }
  }

  @include breakpoint( $bp1 ) {
    border-radius: 0 0 20px 20px;
    padding: 24px 60px;
  }
}
