$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-stage {
  display: flex;
  width: 100%;
  padding: 32px 16px 40px;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  &__level-stage {
    color: rgba(134, 138, 146, 0.3);
    text-align: center;
    font-size: 43px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    margin: 24px 0 8px;

    &.is-margin {
      margin: 0;

      &:lang(ar) {
        margin-bottom: 8px;
      }
    }
  }

  &__title {
    border-radius: 22px;
    background: #ffdc26;
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    color: #19191d;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
