$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-item-all {
  width: 100%;
  height: 100%;
  margin: 0;
  padding-bottom: 150%;
  position: relative;
  background: rgba(255, 220, 38, 0.1);
  border-radius: 12px;
  &__wrap{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
  }
  p {
    @include ellipsis;
    font-family: var(--font-family-main);
    max-width: 100%;
    &:nth-child(1) {
      @include text(14px, 20px, bold, $white);
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    &:nth-child(2) {
      @include text(44px, 44px, bold, $yellow);
    }
    &:nth-child(3) {
      @include text(24px, 32px, bold, $yellow);
    }
  }
  &__btn {
    width: 56px;
    height: 56px;
    background-color: $yellow;
    box-shadow: 0px 0px 16px rgba(255, 241, 171, 0.4), inset 0px 8px 6px rgba(255, 255, 255, 0.8), inset 0px -8px 10px #FF7A00;
    border-radius: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px;
    @include hover() {
      background-color: $orange;
      box-shadow: 0px 0px 20px rgba(255, 241, 171, 0.6), inset 0px -10px 12px rgba(255, 255, 255, 0.4), inset 0px 10px 12px rgba(232, 142, 15, 0.8);
    }
  }
}
