$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
html, body {
    height: 100%;
    overflow-x:hidden;
}

html {
    font-size: 14px;
    line-height: 20px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

body {
    min-height: 100%;
    overflow-x: hidden;
    font-family: $font;
    background-color: #19191D;
    color: $white;

    &.is-anim .halloween.is-slider {
        overflow-x: hidden;
    }
}

a {
    text-decoration: none;
}

button {
    border: 0;
}

linda-app {
    display: block;
    height: 100%;
    transition: .3s all;

    .has-open-left-sidebar & {
      transform: translateX(270px);
    }

    .has-open-right-sidebar & {
      transform: translateX(-270px);
    }
}

linda-view-layer-one {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
}

:root {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --font-family-main: 'Fez','Roboto Condensed', Arial, Helvetica, sans-serif;

    &:lang(cs) {
        --font-family-main: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    }
}

body.is-anim .halloween.is-slider {
    overflow-x: hidden;
}

.dvs-scroller .verification-popup {
    position: relative;
}

.is-summer {
    .popup {
        width: 100%;
        height: 100%;
        max-width: 100%;
        z-index: 999;
        max-height: 100%;
        box-sizing: border-box;
        position: fixed;
        inset: 0;
    }

    .popup.is-summer-reward {
        background: none;
        position: absolute;
        overflow: unset;
    }
}

.linda-has-popup .has-left-menu {
    .summer-welcome {
        transform: translate(-50%, -50%) !important;
    }
}
