$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.modal-body {
  @extend .popup-body;
  padding: 48px 20px;
  align-items: center;
  text-align: center;
  margin-bottom: 60px;

  .popup-title {
    padding: 0;
    margin-bottom: 20px;
  }

  .popup__text {
    margin-bottom: -15px;
  }

  .error-text {
    display: inline;
  }

  .error-link {
    line-height: 20px;
  }

  .btn:not(.activation-rules__btn) {
    margin-bottom: 16px;
  }

  @include breakpoint( $bp2 ){
    padding: 36px 60px;
  }
}
