$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-promo-link {
  display: flex;
  margin: 24px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  &__link-text-promo {
    cursor: pointer;
    color: #ffdc26;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
      margin-left: 5px;
    }
  }
}
