$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-home {
    &__item {
        width: 248px;
        height: 164px;
        border-radius: 12px;
        margin-right: 12px;

        background-color: $white;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        position: relative;
        padding: 16px 16px 20px;
        overflow: hidden;
        &:first-child {margin-left: 16px;}
        &:last-child {margin-right: 16px;}
    }
    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__title {
        @include text(20px, 24px, bold, #28272A);
        font-family: var(--font-family-main);
        text-transform: uppercase;

        max-width: 114px;
        max-height: 48px;
        white-space: pre-wrap;
        overflow: hidden;
        position: relative;
        z-index: 2;

        &:lang(fr) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__value {
        @include text(32px, 36px, bold, $yellow);

        font-family: var(--font-family-main);
        text-align: center;
        position: absolute;
        top: 8px;
        right: 16px;
        width: 98px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        white-space: pre-wrap;
        overflow: hidden;

        .rtl-lang & {
            right: unset;
            left: 16px;
        }

        &:lang(pt-BR),
        &:lang(no),
        &:lang(cs) {
            font-size: 24px;
            line-height: 30px;
        }

        &:lang(fi) {
            font-size: 22px;
            line-height: 26px;
        }

        &:lang(fr),
        &:lang(de-ch),
        &:lang(ch) {
            font-size: 28px;
            line-height: 32px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .is-h44.btn {
            min-width: 0;
            max-width: 50%;
        }
        .link-arrow {
            max-width: 50%;
            font-size: 14px;
            line-height: 20px;
        }

        &:lang(fr),
        &:lang(cs),
        &:lang(el) {
            .is-h36.btn {
                padding: 0 10px;
                font-size: 12px;
            }
        }
    }

    @include breakpoint($bp1) {
        display: grid!important;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 12px;
        padding-right: 16px;
        padding-left: 16px;
        &__item {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            &:first-child {
                margin-left: 0;
            }
        }
        &__value {
            font-size: 32px;
            line-height: 36px;
        }

    }
    @include breakpoint($bp2) {
        padding-right: 20px;

        .rtl-lang & {
            padding-right: unset;
            padding-left: 20px;
        }

        &__item {
            height: 220px;
            padding: 32px;
        }
        &__title {
            font-size: 24px;
            line-height: 28px;
            max-width: 143px;
            max-height: 84px;

            &:lang(fr) {
                font-size: 22px;
                line-height: 24px;
            }
        }
        &__value {
            top: 12px;
            right: 32px;
            width: 151px;
            height: 152px;
            font-size: 48px;
            line-height: 52px;

            .rtl-lang & {
                left: 32px;
            }
        }
    }
    @include breakpoint($bp3) {
        &__item {
            padding: 32px 60px;
        }
        &__title {
            font-size: 28px;
            line-height: 32px;
            max-width: 200px;
            max-height: 96px;

            &:lang(fr) {
                font-size: 24px;
                line-height: 28px;
            }
        }
        &__value {
            top: 8px;
            right: 60px;
            width: 165px;

            .rtl-lang & {
                left: 60px;
            }
        }
    }
}
