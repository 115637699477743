$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-dashboard{
    $root: &;
    display: flex;
    justify-content: space-between;
    --half-space: 8px;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 12px 16px;

    &__half {
        padding-top: 5px;
        flex: 0 0 calc(50% - var(--half-space) / 2);
        height: 120px;

        &.is-left {
            position: relative;
            background-color: $darkGrey;
            border-radius: 12px;
            padding: 12px 12px 0;
        }
    }
 
    &__title {
        max-width: 136px;
        font-family: var(--font-family-main);
        @include text(16px, 20px, 700, #fff);
        @include ellipsis;
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }

    &__lvl {
        margin-top: 2px;
        @include text(10px, 16px, 400, $grey);

        &.is-current {
            color: $yellow;
        }
    }

    &__lvls-progress {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        #{$root}__lvl {
            flex-basis: calc(50% - 4px);
            text-align: left;
            margin-bottom: 4px;

            &:last-child {
                text-align: right;

                .rtl-lang & {
                    text-align: left;
                }
            }

            .rtl-lang & {
                text-align: right;
            }
        }
    }

    &__num {
        font-family: var(--font-family-main);
        letter-spacing: normal;
        text-transform: uppercase;
        @include text(80px, 91px, 700, $black);
        margin: 0 0px -29px  -4px
    }

    &__img {
        position: absolute;
        top: 16px;
        right: -18px;
        width: 120px;
        height: 120px;
        object-fit: contain;

        .rtl-lang & {
            left: -18px;
            right: unset;
        }
    }

    @include breakpoint($bp1){
        --half-space: 20px;
        padding: 0 0 16px;
        &__img{
            right: 0;
        }
        &__title {
            max-width: 242px;
        }
    }
}
