$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-banner-stage {
  background-position-y: -100px;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
  width: 100%;
  max-width: 768px;
  border-radius: 24px;
  position: relative;
  padding: 16px;
  &__wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  &.is-active-banner {
    background-image: url('/joxi/fezbet/ferrari-promo/mobile-active.png');
    background-color: #26262b;
    border: 2px solid rgba(255, 255, 255, 0.1);
  }
  &.is-inactive-banner {
    background-image: url('/joxi/fezbet/ferrari-promo/mobile-inactive.png');
    background-color: #272727;
  }
  &__label {
    border-radius: 8px;
    padding: 0px 12px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    background: #868a92;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .is-transform {
    transform: translateY(203px);
    width: 100%;
    line-height: 1;
    max-width: 170px;
    display: flex;
    height: 100%;
    max-height: 44px;
    white-space: pre-wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .is-margin-btn {
    margin-top: -40px;
  }
  .is-active-label {
    background: #ffdc26;
    color: #19191d;
  }
}
