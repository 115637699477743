$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.lang-icon {

  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  margin-inline-end: 10px;

  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;

  &:after {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    border: 3px solid $black;
    border-radius: 50%;
    transition: border-color .3s;
  }

  @include hover(){
    &:after {
      border-color: $grey;
    }
  }

}
