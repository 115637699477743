$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.info-sitemap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 8px;

    &__item {
        height: 48px;
        background-repeat: no-repeat;
        background-size: 32px;
        background-position: left center;
        padding-left: 40px;
        display: flex;
        align-items: center;

        @include hover() { 
            .info-sitemap__title {color: $grey;}
        }

        .rtl-lang & {
            padding-left: unset;
            padding-right: 40px;
            background-position: right center;
        }

        &.is-providers {
            padding-left: 0;

            .rtl-lang & {
                position: relative;
            }

            .info-sitemap__title {
                padding-left: 42px;
            }

            img {
                display: block;
                position: absolute;
                width: 32px;
                height: 32px;
                padding: 6px;
                margin-right: 8px;
                background-color: rgba(65, 65, 65, 1);
                border-radius: 8px;
                box-shadow: 0 -4px 6px 0 rgba(34, 34, 34, 1) inset, 0 4px 4px 0 rgba(255, 255, 255, 0.23) inset;

                .rtl-lang & {
                    margin-right: unset;
                    margin-left: 8px;
                    right: 0;
                }
            }
        }
    }

    &__title {
        @include text(14px, 28px, bold, $black);
    }    

    @include breakpoint($bp1) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
    }

    @include breakpoint($bp3) {
        grid-template-columns: repeat(3, 1fr);
    }
}

