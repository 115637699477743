$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin hover() {
    &.ng-tap--start:not(.ng-tap--move) {
        @content;
    }
}

@mixin breakpoint($width) {
    @media ( min-width: #{$width} ) {
        @content;
    }
}

@mixin color-gradient($value) {
    background: #{$value};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin text-dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin without-scrollbar(){
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar  {
        display: none;
        height: 0;
        width: 0;
    }
}

@mixin text( $size: null, $lineHeight: null, $weight: null, $color: null ) {
    @if $size != null {
        font-size: $size;
    }

    @if $lineHeight != null {
        line-height: $lineHeight;
    }

    @if $weight != null {
        font-weight: $weight;
    }

    @if $color != null {
        color: $color;
    }
}



@mixin shadow-before-after( $width: 20px, $height: 100%, $color1: 45, $color2: 45, $color3: 50 ){

    &::before, &::after {
        z-index: 1;
        content: '';
        width: $width;
        height: $height;
        right: 0;
        top: 0;
        position: absolute;
        background: linear-gradient(to left , rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
        background: -webkit-linear-gradient(to left, rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
        opacity: 1;
      }
      &::before {
        right: auto;
        left: 0;
        background: linear-gradient(to right, rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
        background: -webkit-linear-gradient(to right, rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
      }

}
