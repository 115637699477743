$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-popup-default {

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 20px;

  @include breakpoint( $bp1 ){
    padding: 0 60px;  
  }

  .popup-title {
    margin-bottom: 16px;
  }

  form {
    width: 100%;
    max-width: 280px;

    .form-item {
      margin-bottom: 20px;
    }

    button {
      display: block;
      margin: 36px auto 16px;
    }
  }
}
