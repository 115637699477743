$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tabs {
    .layout-tabs &{
        display: flex;
        min-width: fit-content;
        justify-content: space-evenly;

        a {
            display: inline-flex;
        }
    }
    justify-content: space-evenly;
    text-align: center;
    background-image: var(--icon-tabs-line);
    background-size: 100% 2px;
    background-position: left bottom 9px;
    background-repeat: no-repeat;
    .is-trustly & {
        padding: 0 20px;
    }
    a {
        flex-shrink: 0;
        display: block;
        position: relative;
        height: 48px;
        overflow: hidden;
        align-items: center;
        font-family: var(--font-family-main);
        @include text(14px, 20px, 700,  rgba(134, 138, 146, 0.5));
        letter-spacing: 0.03em;
        text-transform: uppercase;
        padding-bottom: 4px;
        margin: 0 8px;
        transition: .2s ease;

        &.is-active{
            color: #fff;

            &::before{
                background-color: #fff;
                transform: scale(1.5);
                border-color:  #999999;
            }
        }
        &::before{
            content: '';
            position: absolute;
            bottom: 8px;
            left: 0;
            right: 0;
            margin: auto;
            width: 4px;
            height: 4px;
            background-color: $black;
            border: 1px solid #2D2D32;
            border-radius: 50%;
            z-index: 2;
            transition: .2s ease;
        }

        @include hover(){ color: $grey; }
    }
    @include breakpoint($bp2){
        background-position: left 4px top;
        background-image: var(--icon-tabs-line-ver);
        background-size: 2px 100%;
        .is-trustly & {
            margin-top: 100px;
            padding: 0;
            a {
                height: auto;
            }
        }
        a {
            padding: 0 0 0 14px;
            margin-right: 0;
            margin-left: 0;
            white-space: normal;
            text-align: left;
            &:not(:last-child){    margin-bottom: 20px; }

            &::before {
                left: 3px;
                top: 0;
                bottom: 0;
                right: auto;
            }

            .rtl-lang & {
                padding: 0 14px 0 0;
                text-align: right;

                &::before {
                    left: unset;
                    right: 3px;
                }
            }
        }
        .layout-tabs &{
            display: block;
            min-width: initial;
            a {  display: flex; }
        }
    }  
}
