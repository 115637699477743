$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-wgt {
    background-color: $white;
    border-radius: 16px;
    padding: 16px;
    color: $black;
    font-weight: 700;
    max-height: 360px;
    overflow: auto;

    &__title {
        position: relative;
        padding: 12px 20px 4px;
        @include text(16px, 20px, bold, $black);
        font-family: var(--font-family-main);
        letter-spacing: 0.02em;
        @include ellipsis;
        text-transform: uppercase;
        .is-live & {
            padding: 12px 20px 4px 42px;
            &:before {
                content: '';
                position: absolute;
                left: 20px;
                bottom: 6px;
                width: 16px;
                height: 16px;
                background-color: $red;
                background-image: var(--icon-sport-live-i);
                background-repeat: no-repeat;
                background-position: center;
                box-shadow: inset 0px 4.125px 5.5px rgba(255, 255, 255, 0.3), inset 0px -4.125px 5.5px rgba(60, 18, 5, 0.4);
                border-radius: 50%;
            }
        }

    }
    &__filters {
        display: flex;
        height: 38px;
        border-bottom: 1px solid $lightGrey;
        padding: 0 20px;
        margin-bottom: 6px;
    }
    &__filter {
        @include text(12px, 35px, bold, $grey);
        @include ellipsis;
        margin-right: 16px;
        @include hover() {
            color: $black;
        }
        
        &:last-child {
            margin-right: 0;
        }

        &.active {
            color: $black;
            border-bottom: 4px solid $yellow;
        }
        

    }
    &__list {
        padding: 0 4px;
    }   
    &__l{
        width: 83px;
        flex-shrink: 0;
    }
    &__r{
        width: calc(100% - 107px);
        position:relative;
        flex-grow: 1;
        margin-left: 8px;
    }
    &__item{
        display: flex;
        align-items: flex-end;
        padding: 8px;
        border-radius: 12px;
        grid-column: 1 / 2;  
        overflow: hidden;
        &:nth-of-type(1n + 3){ grid-column: 2 / 3;}
        &:nth-of-type(2n + 1){ background: $lightGrey;}
    }    
    
    &__date {
        @include text(14px, 16px, bold, $black);
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        @include ellipsis;   
        .is-live & {
            color: $red;
            font-size: 16px;
            &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $red;
                background-image: var(--icon-sport-live-i) ;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
                margin: 0 3px 0 0;
                box-shadow: inset 0px 2px 3px rgba(255, 255, 255, 0.3), inset 0px -2px 3px rgba(60, 18, 5, 0.4);
                flex-shrink: 0;
            }
        }
    }
    
    &__row {
        display: flex;
        @include text(12px, 16px, bold, $black);
        margin-top:4px;
        letter-spacing: 0.03em;
    }
    &__key {
        @include ellipsis;
    }
    &__val {
        display: none;
        margin-right: 4px;
        flex-shrink: 0;
    }
    
    &__champ {
        @include text(10px, 12px, normal, $grey);
        padding-right: 70px;
        height: 24px;
        overflow: hidden;
        span {
            display: block;
            @include ellipsis;
        }
    }
    
    &__rate {
        display: grid;
        grid-template-columns: repeat( 3 , 1fr );
        grid-gap: 4px;
        margin-top: 2px;
    }
    
    &__coef {
        @include text(11px, 20px, normal, $black);
        display: block;
        background: $middleGrey;        
        border-radius: 4px;
        padding:4px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        line-height: 20px;
        box-sizing: border-box;
        & strong{ margin-left:4px; }
        @include hover() {
            box-shadow: inset 0 0 0 1px $grey;
        }
    } 
    &__logos {
        position:absolute;
        top: -5px;
        right: 0;
        display: flex;
    }
    &__logo {
        width: 28px;
        height: 28px;
        border: 1px solid $grey;
        background-color: $lightGrey;
        border-radius: 50%;
        &:first-child{ margin-right:-3px;}
    
        padding: 4px;
        background-repeat: no-repeat;
        background-position: center;
    
        background-size: 17px;
    
        .is-live & {  border: 1px solid $red; }
    }
    &__logo-i {
        width: 100% ;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    @include breakpoint($bp1) { 
        &__l{
            width: 175px;
            flex-shrink: 0;
        }
        &__r{
            width: calc(100% - 222px);
            margin-left: 14px;
        }
        &__item{
            padding: 10px 16px 8px;
        }
        &__rate {
            grid-gap: 8px;
        }
        &__val {
            display: block;
        }
        &__champ {
            padding-top: 3px;
            span { display: inline;}
        }
        &__coef {
            height: 36px;
        }
    }
    @include breakpoint($bp2) {
        &__l{
            width: 121px;
            flex-shrink: 0;
        }
        &__r{
            width: calc(100% - 167px);
        }
        &__list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 80px;
            grid-column-gap: 4px;
            grid-auto-flow: dense; 
        }
    }
}
