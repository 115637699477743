$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: fit-content;
  padding: 48px 20px 36px;
  background: $white;
  font-family: 'Roboto Condensed', sans-serif;
  overflow: auto;
  text-align: center;

  @include breakpoint($bp1) {
    top: 40px;
    bottom: auto;
    max-width: 400px;
    margin: 0 auto;
    padding: 48px 60px 65px;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(25, 25, 29, 0.50);
  }

  &__content {
    display: flex;
    flex-direction: column;
    color: $black;
    animation: none;
  }

  &__title {
    margin-bottom: 20px;
    color: $black;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
    text-align: center;
  }

  &.shop-popup-scroll {
    position: relative;
    height: auto;
  }
}
