$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.search-field {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 16px;
    position: relative;

    input {
        @include text(15px, 40px, 400, #E7E7E4);

        position: relative;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        border: none;
        border-radius: 30px;
        background-color: #3B361E;
        background-size: 40px;
        background-position: right center;
        background-repeat: no-repeat;
        height: 40px;
        padding: 0 60px 0 18px;
        transition: .3s all;
        z-index: 10;

        .rtl-lang & {
            background-position: left center;
            padding: 0 18px 0 60px;
        }

        &:lang(cs) {
            padding-right: 85px;
        }

        &::placeholder,
        &::-webkit-input-placeholder {
            color: #E7E7E4;
        }

        &:focus {
            box-shadow: inset 0 0 0 1px $yellow;
            color: $yellow;

            &::placeholder,
            &::-webkit-input-placeholder {
                color: transparent;
            }
        }

        @include hover() {
            color: $yellow;
            box-shadow: inset 0 0 0 1px $yellow;

            &:not(.ng-not-empty) {
                background-image: var(--icon-search-yellow);
            }
        }
        &.ng-not-empty {
            background-image: none;
        }

        &.ng-not-empty + .search-clear {
            display: block;
        }

        body.linda-has-search-mode & {
            width: calc(100% - 44px);
        }
    }
}
