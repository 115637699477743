$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-bonus {
    margin-bottom: 16px;

    &--title {
        margin-bottom: 8px;
        @include text(16px, 150%, 700, $darkGrey);
        letter-spacing: 0.48px;
    }
    // if !promo.hasOptIn
    &--text {
        @include text(16px, 28px, 400, $grey);
    }
    // if promo.hasOptIn
    &--opted-in,
    &--opt-out {
        margin-bottom: 4px;
        line-height: 16px;
        color: $grey;
        letter-spacing: 0.48px;
    }
    // Make a deposit
    &--opted-in {
        font-size: 16px;
    }
    // Click to Opt out
    &--opt-out {
        font-size: 14px;
    }
    &--link {
        text-decoration: underline;
        color: inherit;
    }
    &--icon {
        width: 16px;
        height: 16px;
        vertical-align: text-top;
        color: $green;
    }
}
