$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-exchange-form {

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    margin: 4px 0 8px;
  }

  @include breakpoint( $bp1 ){

    align-items: flex-start;
    flex-direction: row;

    .profile-exchanger__amount {
      margin-top: 24px;
    }

    .icon {
      min-width: 24px;
      margin: 36px 8px 0;
      transform: rotate(-90deg);
    }

  }

}
