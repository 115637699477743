$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-category-list {

  display: none;

  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  height: 58px;
  overflow: hidden;
  padding: 6px 0;

  background-color: #2D2D32;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%);
  background-size: 100% 1px;
  background-position: 0 62px, 0 63px;
  background-repeat: no-repeat;
  border-radius: 12px 12px 0 0;

  margin-bottom: $toolbarHeight;

  & > * {
    order: 3;
    &.is-active {
      order: 1;
      width: 100% !important;
      margin-bottom: 8px;
    }
  }

  &.is-opened-category {
    height: auto !important;
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.3), 0 4px 20px #19191D;
    border-radius: 12px;
    z-index: 10;
  }

  @include breakpoint( $bp2 ){

    & > * {
      width: 50% !important;
    }

  }

}
