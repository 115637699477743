$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-homepage {
    &__widgets {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 16px;
        padding: 0 8px;
        position: relative;
        z-index: 2;
        margin-top: -30px;
        margin-bottom: 8px;
    }
    &__games  {
        padding: 0 16px;
        margin-bottom: 16px;
    }
    &__events {
        padding: 0 16px;
        margin-bottom: 16px;

        .link-title {
            margin-inline-start: -8px;
        }

        &-list {
            direction: ltr;
        }
    }
    &__top-league {
        margin: 20px 0;
    }
    &__sport {
        padding: 0 14px;
    }
    &__sport-category {
        margin-bottom: 16px;
    }
    &__categories {
        padding: 0 0 0 16px;
        margin-bottom: 16px;
    }
    &__tournament {
        padding: 0 16px;
        margin-bottom: 8px;
    }
    &__providers {
        margin-bottom: 8px;
        .link-title { margin-left: 16px;}
    }
    &__new-games {
        padding: 0 0 0 16px;
        margin-bottom: 8px;
    }
    &__promotions {
        margin-bottom: 16px;
    }
    &__registration {
        padding: 0 8px;
        margin-bottom: 18px;
        overflow: hidden;
    }
    @include breakpoint($bp1) {
        &__widgets {padding: 0 32px;}
        &__registration {padding: 0 16px;}
    }
    @include breakpoint($bp2) {
        &__widgets {
            padding: 0 20px;
            grid-template-columns: repeat(2, 1fr);
        }
        &__games  {
            padding: 0 20px;
            margin-bottom: 20px;
        }

        &__events {
            padding: 0 20px;
        }

        &__providers {
            .link-title { margin-left: 20px;}
        }
        &__tournament,
        &__new-games {
            padding: 0 0 0 20px;
        }
        &__categories,
        &__promotions {
            // padding: 0 0 0 20px;
            margin-bottom: 20px;
        }
        &__registration {
            padding: 0 20px;
            margin-bottom: 30px;
        }
    }
    @include breakpoint($bp3) {
        &__widgets {grid-template-columns: repeat(3, 1fr);}
    }

    // &__events {
    //     padding: 0 0 0 20px;
    //     margin-bottom: 16px;
    //     margin-top: -50px;
    //     position: relative;
    //     z-index: 9;
    //     .link-title {
    //         margin-bottom: -14px;
    //     }
    // }
    // &__top-league {
    //     padding: 0 0 0 20px;
    //     margin-bottom: 16px;
    // }
    // &__sport {
    //     padding: 0 20px 8px;
    // }
    // &__sport-category {
    //     padding: 0 0 0 20px;
    //     margin-bottom: 16px;
    // }
    // &__promotions {
    //     padding: 0 0 0 20px;
    //     margin-bottom: 16px;
    // }
    // &__registration {
    //     padding: 0 8px;
    // }
    // @include breakpoint($bp1) {
    //     &__registration {padding: 0 16px;}
    // }
    // @include breakpoint($bp2) {
    //     &__events,
    //     &__top-league,
    //     &__sport-category,
    //     &__promotions {
    //         margin-bottom: 20px;
    //     }
    //     &__registration {padding: 0 20px;}
    // }

}

.home-promo {
    &__text {
        max-width: 220px;

        @include breakpoint($bp2) {
            max-width: 340px;
        }

        &.type {
            @include text(16px, 20px, 700, $white);

            opacity: 0.5;
            text-transform: uppercase;
            margin-bottom: 4px;
            white-space: nowrap;
        }

        &.name {
            @include text(20px, 120%, 700, $white);

            text-transform: uppercase;

            @include breakpoint($bp2) {
                font-size: 32px;
                margin-bottom: 4px;
            }
        }

        &.amount {
            @include text(32px, 120%, 700, $yellow);

            text-transform: uppercase;
            margin-bottom: 16px;

            @include breakpoint($bp2) {
                font-size: 48px;
            }
        }
    }

    &__content {
        padding: 0 16px 0 22px;
        display: flex;
        justify-content: space-between;

        @include breakpoint($bp2) {
            align-items: center;
        }
    }

    &__wrapper-text {
        @include breakpoint($bp2) {
            text-align: center;
        }
    }

    &__img {
        display: block;
        transform: scale(-1, 1);
        position: absolute;
        right: -10px;
        height: 150px;
        object-fit: cover;
        margin-top: -10px;

        @include breakpoint($bp00) {
            position: relative;
            height: 190px;
            right: unset;
            object-fit: contain;
            margin-top: -40px;
        }

        @include breakpoint($bp1) {
            margin-top: -80px;
            height: 260px;
        }

        @include breakpoint($bp2) {
            transform: unset;
            margin-top: -30px;
        }

        @include breakpoint($bp3) {
            height: 320px;
        }
    }

    &__img-logo {
        display: none;
        height: 190px;
        object-fit: contain;

        @include breakpoint($bp2) {
            display: block;
        }

        @include breakpoint($bp3) {
            height: 230px;
        }
    }

    &__wrapper-img {
        @include breakpoint($bp2) {
            order: -1;
        }
    }
}

.page-btns {
    position:fixed;
    left: 14px;
    bottom: calc(68px + var(--safe-area-inset-bottom));
    display: grid;
    grid-row-gap: 8px;
    opacity: .7;
    z-index: 9999;

    .rtl-lang & {
        left: unset;
        right: 14px;
    }

    .is-icon {
        span {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-image: var(--icon-arr-up);
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
        }
    }

    .btn:first-child {
        opacity:1;
        transition:0.3s;

        &:not(.is-active){
            opacity:0;
            transform: scale(0);
        }
    }
}
