$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.search-game {
    width: 100%;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%),
                        linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%);
    background-size: 100% 1px;
    background-position: bottom center, bottom 1px center;
    background-repeat: no-repeat; 
    padding: 4px 10px;

    &:last-child {
        background-image: none;
    }

    &__wrap {
        display: flex;
        align-items: center;
        height: 76px;
        border-radius: 12px;
        padding: 8px 6px; 
        overflow: hidden;
    }

    img {
        @include text(10px, 12px, 400, #fff);

        width: 90px; 
        height: 60px;
        border-radius: 8px;
        background-size: cover; 
        background-position: center; 
        margin-right: 20px;

        .rtl-lang & {
            margin-right: unset;
            margin-left: 20px;
        }
    }

    &__name {
        @include text(16px, 28px, 700, #fff);
        @include ellipsis;

        text-transform: uppercase;
        max-width: 100%;
        font-family: var(--font-family-main);

        span {
            @include text(14px, 20px, 400, #fff);
            @include ellipsis;

            font-family: $font;
            text-transform: initial; 
            display: block;
            margin-top: 4px;
        }
    }

    @include hover() {
        background-image: none;

        .search-game__wrap {
            background: rgba(255, 220, 38, 0.1);
        }
    }
}
