$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-select {
    select {
        -moz-appearance: none;
        -webkit-appearance: none;
    
        width: 100%;
        padding: 8px 36px 8px 16px;
        border: $inputBorder;

        background-size: 16px;
        background-repeat: no-repeat;
        background-position: calc(100% - 12px) center;
        background-color: $inputBg;
        border-radius: $inputRadius;
        height: var(--height);
        @include text(16px, 24px, 400, $inputColor);
        transition: .2s all;

        &:lang(ar) {
            .long-name {
                direction: ltr;
            }
        }

        .rtl-lang & {
            background-position: 12px center;
            padding: 8px 16px 8px 36px;
        }

        &:focus {
            border-color: $yellow;
            box-shadow: inset 0 0 1px 0 $yellow;
        }
  
        @include hover() {
            border-color: $black;
            background-image: var(--icon-arrow-dark)
        }
      
        &[disabled] {
          color: $colorDisabled;
          background-color: $bgDisabled;
        }
    }
}
