$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-stage-final {
  background-image: url('/joxi/fezbet/ferrari-promo/Mobile-Active.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 180%;
  background-color: #111115;
  min-height: 500px;
  border-radius: 20px;
  padding: 32px 16px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  @include breakpoint($bp1) {
    background-position: center;
    background-size: cover;
  }
  &__leaderboard {
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    max-width: 768px;

    .is-3-column .tournament-top__item {
      grid-template-columns: 32px 1fr 1fr;
    }
    .is-3-column .tournament-top__head {
      grid-template-columns: 32px 1fr 1fr;
    }
    .is-current-user-ferrari .tournament-top__item-i {
      color: #000;
      font-weight: 700;
    }
  }
  &__header-text {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__header-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50%;
    flex-direction: column;
  }
  &__terms {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    border: none;
    outline: none;
    background: transparent;
  }
  &__title {
    color: #19191d;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 8px;
    background-color: #ffdc26;
    margin-bottom: 12px;
    padding: 0 15px;
  }
  &__name {
    color: #ffdc26;
    text-shadow: 0px 3.913043260574341px 14.673912048339844px rgba(40, 39, 42, 0.1);
    font-size: 48px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 12px;
    text-transform: uppercase;
    text-align: center;
  }
  &__subtitle {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
  }
  .is-margin-bottom {
    margin-bottom: 16px;
  }
}
