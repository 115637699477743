$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.world-cup-popup {
    background: none;
    max-width: 100%;
    margin: 0;
    & .wc-popup__info {
        & p {
            font-weight: 500;
            line-height: 130%;
            color: #cb7ba0;
        }
    }
    & .wc-popup-terms__content-inner {
        & p {
            color: #cb7ba0;
            font-size: 12px;
            line-height: 160%;
        }
    }
}