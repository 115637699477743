$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-error {
  @include text(12px, 14px, 400, $colorError);

  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 4px 8px 0 8px;
  text-align: left;

  .rtl-lang & {
    text-align: right;
  }

  &.is-bank-id {
    position: absolute;
    top: 100%;
  }

  & > * {
    margin-right: 5px;

    .rtl-lang & {
      margin-right: unset;
      margin-left: 5px;
    }
  }

  .is-form-promo & {
    padding-left: 0;
  }

  &, .ng-untouched ~ &, .ng-valid ~ & {
    display: none;
  }

  .ng-invalid:focus ~ & {
    display: none !important;
  }

  .ng-invalid.ng-touched ~ &:not(.is-only-required), .ng-invalid-required.ng-touched ~ &.is-only-required {
    display: block;
  }

  &__minlength {
    display: none;

    .ng-invalid-minlength ~ .form-error & {
      display: inline;
    }
  }

  &__maxlength {
    display: none;

    .ng-invalid-maxlength ~ .form-error & {
      display: inline;
    }
  }

  &__pattern {
    display: none;

    .ng-invalid-pattern ~ .form-error &,
    .ng-invalid-email ~ .form-error &,
    .ng-invalid-number ~ .form-error &,
    .ng-invalid-phone ~ .form-error & {
      display: inline;
    }
  }

  &__required {
    display: none;

    .ng-invalid-required ~ .form-error &,
    .ng-invalid-date ~ .form-error & {
      display: inline;
    }
  }

  &__patternLetter {
    display: none;

    .ng-invalid-letter ~ .form-error & {
      display: inline;
    }
  }

  &__min {
    display: none;

    .ng-invalid-min ~ .form-error & {
      display: inline;
    }
  }

  &__max {
    display: none;

    .ng-invalid-max ~ .form-error & {
      display: inline;
    }
  }

  &__amount {
    display: none;

    .ng-invalid-amount ~ .form-error & {
      display: inline;
    }
  }

  &__password-verify {
    display: none;

    .ng-invalid-password-verify ~ .form-error & {
      display: inline;
    }
  }

  &__low {
    display: none;

    .ng-invalid-low ~ .form-error & {
      display: inline;
    }
  }

  &__up {
    display: none;

    .ng-invalid-up ~ .form-error & {
      display: inline;
    }
  }

  &__number {
    display: none;

    .ng-invalid-number ~ .form-error & {
      display: inline;
    }
  }
}
