$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-label {
    position: relative;
    display: block;
    width: 100%;
    @include text(12px, 16px, 400, $black);
    margin-bottom: 4px;
    padding-left: 8px;
    .is-form-promo &{
        @include text(14px, 20px);
        padding-left: 0;
    }
    .is-light &{ color: $white; }
    &.is-bank-id {
        width: 256px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;
        margin-bottom: 0;
    }

    div:not(.form-item) &{
        @include hover(){
            .form-input{
                border-color: $black;
                &.ng-invalid.ng-touched:not(.novalidate) { 
                    border-color: $black;
                    box-shadow: none;
                }
            }
            .form-label{
                font-weight: 700;
            }
        }
    }
}