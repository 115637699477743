$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.modal-icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  &:before {
    content: '';
    display: block;
    width: 52px;
    height: 34px;
    margin-right: 12px;
    background: url('#{$cdn}/content-svg/fin-popup/mobile/fezbet/flag_finland.svg') center / contain no-repeat;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
  }

  &:after {
    content: '';
    display: block;
    width: 124px;
    height: 38px;
    background: url('#{$cdn}/content-svg/fin-popup/mobile/fezbet/paymsystem_zimpler.svg') center / contain no-repeat;
  }
}