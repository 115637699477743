$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-stroke-bold {

    height: 48px;
  
    display: inline-block;
    padding: 0 12px;
    background-color: $white;
    box-shadow: inset 0px 0px 0px 2px $middleGrey;
    border-radius: 12px;
    max-width: 100%;
  
    font-family: var(--font-family-main);
    @include text(24px, 48px, 700, $black);
    @include ellipsis;
    text-align: center;
    @include hover() {
        box-shadow: inset 0px 0px 0px 2px $yellow;
    }
    &.is-active {
        background-color: $yellow;
        box-shadow: inset 0px 12px 15px rgba(255, 255, 255, 0.8), inset 0px -8px 15px #E88E0F, inset 0px 1px 1px rgba(233, 145, 16, 0.2);
    }
}
