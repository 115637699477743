$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-flex-center {

  display: flex;
  flex-direction: column;
  align-items: center;

}
