$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sidemenu-balance {
    height: 48px;
    padding: 0 23px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
    background-position: left 23px top 1px, left 23px top;
    background-repeat: no-repeat;
    background-size: calc(100% - 32px) 1px;
    &.has-child {
        &:after {
            content: '';
            width: 16px;
            height: 16px;
            background-image: var(--icon-arrow-opened);
            background-size: 16px;
            transform: rotate(180deg);
            flex-shrink: 0;
        }
    }
    &.is-opened {
        &:after {transform: none;}
        & + .sidemenu-balance__list {
            display: block;
        }
    }
    &__list {
        display: none;
    }
    &__text {
        @include text(12px, 16px, normal, $white );
        @include ellipsis;
        max-width: 138px;
        margin-right: 4px;
    }
    &__value {
        @include text(14px, 16px, bold, $yellow);
        @include ellipsis;
        max-width: 82px;
    }
}