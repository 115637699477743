$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-block {
    margin: 0 auto 21px;
    position: relative;
    z-index: 30000;
    background-color: #19191D;
    background-clip: content-box;

    &::before {
        content: '';
        width: 100%;
        height: 180px;
        background: linear-gradient(180deg, rgba(25, 25, 29, 0) 0%, #19191D 100%);
        position: absolute;
        top: -180px;
        z-index: 3000;
        pointer-events: none;
    }

    &__button {
        display: block;
        width: 207px;
        margin: 12px auto 0;
    }

}
