$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-item {
  position: relative;
  height: 278px;
  margin-bottom: 24px;

  &.has-status-0 {
    .tournament-label {
      background-color: $white;
    }

    .tournament-item__content:before {
      background-position: left top;
    }
  }

  &.has-status-2 {
    .tournament-label {
      background-color: $grey;

      &__text {
        color: $white;
      }
    }

    .tournament-item__content:before {
      background-position: -80px top;
    }

    .tournament-item__img {
      filter: grayscale(100%);
    }

    .tournament-item__bg {
      background-image: linear-gradient(180deg, #B5B5B5 0%, #252525 100%) !important;
    }
  }

  &__wrap {
    width: 100%;
    height: 176px;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 128px;
    border-radius: 12px 12px 0 0;
    top: 48px;
    z-index: -2;

    &.euro {
      background-image: linear-gradient(180deg, #DA4453 0%, #89216B 100%);
    }
  }

  &__label {
    position: absolute;
    left: 48px;
    transform: translateX(-50%);
    top: 38px;
    z-index: 3;

    &:lang(ar) {
      left: unset;
      right: 18px;
      transform: unset;
    }
  }

  &__img {
    width: 240px;
    height: 290px;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: calc(25% - 100px);
    z-index: -1;

    @include breakpoint($bp0) {
      right: calc(25% - 120px);
    }

    .rtl-lang & {
      right: unset;
      left: calc(25% - 100px);

      @include breakpoint($bp0) {
        left: calc(25% - 120px);
      }
    }

    .is-world-cup & {
      top: 14px;
    }
  }

  &__user-cont {
    background-repeat: no-repeat;
    background-position: center 4px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding: 20px 5px 0;
    margin: 70px 0 10px 28px;

    @include text(12px, 16px, bold, $white);
    font-family: var(--font-family-main);
    @include ellipsis;

    position: relative;
    z-index: 2;
  }

  &__date {
    @include text(14px, 20px, bold, rgba(255, 255, 255, 0.5));
    @include ellipsis;
    font-family: var(--font-family-main);
    text-align: center;
    max-width: 76px;
    margin: 120px 0 32px;
    margin-inline-start: 10px;

    span {
      display: block;
    }
  }

  &__start-date {
    @include text(14px, 20px, bold, rgba(255, 255, 255, 0.5));
    font-family: var(--font-family-main);
    text-align: center;
  }

  &__time {
    max-width: 76px;
    margin: 120px 0 32px;
    margin-inline-start: 10px;

    span {
      display: block;
      text-align: center;
    }
  }

  &__content {
    position: relative;
    background-color: rgba(255, 220, 38, 0.1);
    border-radius: 0 0 12px 12px;
    padding: 16px 16px 14px;

    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      background-image: url($cdn + '/fezbet/tournaments/tournament.png');
      background-position: -40px top;
      background-repeat: no-repeat;
      background-size: cover;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
    }
  }

  &__type {
    display: none;
    @include text(14px, 28px, normal, $white);

    .tournament-sport & {
      display: block
    }
  }

  &__sport-type {
    @include text(14px, 28px, 400, $white);

    padding-top: 130px;

    .is-euro2024-open & {
      padding-top: 102px;
    }
  }

  &__title {
    @include text(20px, 28px, bold, $white);
    font-family: var(--font-family-main);
    @include ellipsis;
  }

  &__value {
    @include text(32px, 36px, bold, $black);
    @include ellipsis;

    margin-top: 8px;
    font-family: var(--font-family-main);
    text-shadow: 0 3px 10px rgba(40, 39, 42, 0.1);
    color: #ffdc26;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-image: var(--icon-arrow-right-y);
      filter: $filterYellow;
    }

    &.icon-arrow-right-y {
      background: unset;
    }

    &-icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-left: 4px;
      margin-right: auto;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .rtl-lang & {
      &::after {
        transform: rotate(180deg);
      }
      &-icon {
        margin-left: auto;
        margin-right: 4px;
      }
    }
  }

  &__btn {
    display: none;
    width: fit-content;
    margin-top: 35px;
  }

  &__top-users {
    display: none;
  }

  @include breakpoint($bp1) {
    height: 228px;
    margin-bottom: 20px;
    &__wrap {
      height: 100%;
      border-radius: 0 0 12px 12px;
    }
    &__bg {
      height: 100%;
    }
    &__img {
      width: 280px;
      height: 348px;
      right: auto;
      left: calc(25% - 140px);

      .rtl-lang & {
        left: unset;
        right: calc(25% - 140px);
      }
    }
    &__date {
      position: absolute;
      left: auto;
      right: 16px;
      top: 162px;
      width: 234px;
      max-width: none;
      text-align: left;
      margin: 0 0 32px 10px;
      z-index: 3;

      .rtl-lang & {
        direction: ltr;
      }

      span {
        display: inline-block;
      }
    }
    &__time {
      position: absolute;
      right: 16px;
      top: 132px;
      width: 234px;
      max-width: none;
      margin: 0 0 32px 10px;
      z-index: 3;

      span {
        display: inline;
      }
    }
    &__content {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      position: absolute;
      right: 16px;
      top: 60px;
      width: 234px;
      z-index: 3;

      .rtl-lang & {
        right: unset;
        left: 16px;
      }

      &::before {
        content: none;
      }
    }
    &__title {
      margin-bottom: 4px;
    }
    &__value {
      font-size: 36px;
      line-height: 40px;
      margin-top: 0;
      padding-right: 0;
      background-image: none !important;
    }
    &__btn {
      display: block;
    }
    &__sport-type {
      padding-top: 18px;

      .is-euro2024-open & {
        padding-top: 18px;
      }
    }
  }
  @include breakpoint($bp2) {
    height: 288px;
    display: flex;

    &__wrap {
      width: calc(100% - 252px);
    }
    &__bg {
      height: 232px;
      top: 56px;
    }
    &__date {
      top: 200px;
      width: 222px;
    }
    &__time {
      width: 222px;
      right: 16px;
      top: 174px;
      left: auto;
      margin-left: 0;
    }
    &__img {
      width: 312px;
      height: 400px;
      right: calc(25% - 156px);

      .rtl-lang & {
        left: unset;
        right: calc(25% - 156px);
      }
    }
    &__user-cont {
      margin-top: 78px;
    }
    &__content {
      width: 222px;
      top: 86px;
      left: auto;
      right: 268px;

      &::before {
        content: '';
        left: auto;
        right: -36px;
        top: 68px;
        transform: none;
      }

      .rtl-lang & {
        right: unset;
        left: 268px;

        &::before {
          right: unset;
          left: -36px;
        }
      }
    }
    &__label {
      top: 46px;
    }
    &__title {
      margin-bottom: 8px;
    }
    &__value {
      margin-bottom: 12px;
    }
    &__top-users {
      width: 240px;
      margin: 56px 0 0 12px;
      display: block;

      .rtl-lang & {
        margin: 56px 12px 0 0;
      }
    }
    &__btn {
      margin-top: 56px;
    }
    &__sport-type {
      padding-top: 28px;

      .is-euro2024-open & {
        padding-top: 28px;
      }
    }

  }
  @include breakpoint($bp3) {
    height: 360px;
    &__wrap {
      width: calc(100% - 292px);

    }
    &__bg {
      height: 286px;
      top: 74px;
    }
    &__label {
      top: 64px;
    }
    &__img {
      width: 400px;
      height: 512px;
      right: calc(25% - 200px);

      .rtl-lang & {
        left: unset;
        right: calc(25% - 200px);
      }
    }
    &__user-cont {
      margin-top: 96px;
    }
    &__content {
      width: 310px;
      top: 114px;
      left: auto;
      right: 310px;

      &:before {
        content: '';
        left: auto;
        right: -38px;
        top: 83px;
        transform: none;
        border-radius: 50%;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        background-position: left -41px top 2px;
      }

      .rtl-lang & {
        right: unset;
        left: 310px;

        &::before {
          right: unset;
          left: -38px;
        }
      }
    }
    &__date {
      top: 240px;
      width: 310px;
    }
    &__time {
      width: 310px;
      font-size: 18px;
      top: 218px;
    }
    &__value {
      margin-bottom: 16px;
    }
    &__top-users {
      width: 280px;
      margin-top: 74px;
    }
    &__title {
      font-size: 24px;
    }
    &__value {
      font-size: 48px;
      line-height: 52px;
    }
    &__btn {
      margin-top: 68px;
    }
    &.has-status-0 {
      .tournament-item__content:before {
        background-position: left -1px top 2px;
      }
    }
    &.has-status-2 {
      .tournament-item__content:before {
        background-position: left -81px top 2px;
      }
    }
  }
}
