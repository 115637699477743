$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-registration-2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $lightGrey;
  z-index: -1;
  animation: hideRegBox2 .3s;
  animation-fill-mode: forwards;

  &.is-step-1, &.is-step-3, &.is-step-4, &.is-step-6, &.is-step-7, &.is-step-8 {
    body:not(.linda-has-user) & {
      animation: showRegBox2 .3s;
      animation-fill-mode: forwards;
    }
  }

  form {
    width: 280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .has-margin-bottom-20 {
      width: 100%;
    }
  }

  .link-back {
    margin-left: 20px;
    &:lang(ar) {
      margin-right: 20px;
    }
  }

  &__title {
    width: 100%;
    background-color: $yellow;
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: $black;
  }

  &__country {
    display: flex;

    & > div:first-child {
      width: 168px;
      margin-right: 12px;

      .rtl-lang & {
        margin-right: unset;
        margin-left: 12px;
      }
    }

    & > div:last-child {
      width: 100px;
    }
  }

  &__name {
    display: flex;

    & > div {
      width: 134px;
    }

    & > div:first-child{
      margin-right: 12px;

      .rtl-lang & {
        margin-right: unset;
        margin-left: 12px;
      }
    }
  }

}

@keyframes showRegBox2 {
  0% {
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
  }
  1% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    z-index: 1;
    transform: translateX(0);
  }
}

@keyframes hideRegBox2 {
  0% {
    opacity: 1;
    z-index: 1;
    transform: translateX(0);
  }
  99% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    z-index: -1;
    transform: translateX(100%);
  }
}
