$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.lamborghini-giveaway {
  min-height: calc(100vh - 104px);
}

.header-logo.lambo {
  display: none;
}
