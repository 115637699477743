$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.table {

  & > * > span {
    width: 25%;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

}
