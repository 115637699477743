$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-profile-bonus-list {

  display: grid;
  grid-gap:  12px;
  grid-template-columns: 1fr;

  @include breakpoint( $bp1 ){
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint( $bp3 ){
    grid-template-columns: repeat(3, 1fr);
  }

}


.profile-bonus-list__item {
  order: 99;

  &.status-active {
    order: 1;
  }

  &.status-locked {
    order: 2;
  }

  &.status-unlocked {
    order: 3;
  }
}
