$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.title-page {

  font-family: var(--font-family-main);
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: rgba(134, 138, 146, 0.3);

  &.is-small {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: rgba(134, 138, 146, 0.6);
  }

}
