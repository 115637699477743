$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-sport {
    &>.tournament-item {
        height: 256px;
        margin-bottom: 60px;
    }
    & .tournament-item__time {
        top: 162px;
    }
    @include breakpoint ($bp1) {
        &>.tournament-item {
            margin-bottom: 20px;
        } 
    }
    @include breakpoint ($bp2) {
        &>.tournament-item {
            height: 290px;
        } 
        & .tournament-item__time {
            top: 200px;
        }
        & .tournament-item__btn {
            margin-top: 42px;
        }   
    }
    @include breakpoint ($bp3) {
        &>.tournament-item {
            height: 360px;
        } 
        & .tournament-item__time {
            top: 240px;
        }   
    }
}