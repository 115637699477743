$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-black {

  font-family: var(--font-family-main);

  font-weight: normal;
  font-size: 16px;
  line-height: 48px;

  text-transform: uppercase;

  color: $black;

  &.popup__link {
    margin: 0 5px;
  }

  @include hover(){
    color: $grey;
  }

}
