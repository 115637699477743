$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-howto {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 836px;
  width: 100%;
  padding: 0 8px;
  margin: 36px 0 0 auto;

  .is-euro2024-open & {
    max-width: 428px;
    margin: 20px auto 0;
  }

  &__wrap .is-euro2024-open & {
    width: 100%;
  }

  &__title {
    @include text(20px, 24px, 700, $white);

    margin-bottom: 12px;
    text-transform: uppercase;

    .is-euro2024-open & {
      margin-bottom: 36px;
    }
  }

  &__description {
    @include text(18px, 26px, 400, $white);

    margin-bottom: 24px;
    text-align: center;
  }

  &__item {
    @include text(17px, 27px, 400, $black);

    position: relative;
    display: flex;
    align-items: center;
    min-height: 80px;
    max-width: 444px;
    padding: 12px 14px 12px 72px;
    margin-bottom: 16px;
    background-color: $white;
    border-radius: 8px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto;
    letter-spacing: -0.2px;
    z-index: 1;

    .is-euro2024-open & {
      max-width: unset;
    }
  }

  @include breakpoint($bp2) {
    &__wrap {
      padding: 0 48px;

      .is-euro2024-open & {
        padding: 0;
      }
    }
  }
}
