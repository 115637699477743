$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-event {
    background-color: $black;
    border: 1px solid rgba(255, 220, 38, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(25, 25, 29, 0.3);
    border-radius: 12px;

    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.has-grid-pos {
        grid-row: 4/5;
        grid-column: 1/2;
    }
    &__logos {
        width: 66px;
        height: 36px;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        margin-bottom: 10px;
    }
    &__logo {
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;

        width: 36px;
        height: 36px;
        &:nth-child(2) {margin-left: -6px;}
    }
    &__date {
        @include text(10px, 12px, normal, $white);
        letter-spacing: 0.03em;
    }
    &__time {
        @include text(20px, 24px, bold, $yellow);
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }
    &__champ {
        @include text(9px, 16px, normal, $white);
        letter-spacing: 0.03em;
    }
    &__team-name {
        @include text(10px, 16px, bold, $yellow);
        letter-spacing: 0.03em;
    }
    &__rate {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 3px;     
    }
    &__coef {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        background: $darkGrey;
        border-radius: 12px;
        height: 36px;
        
        span{
            @include text(10px, 12px, normal, $grey);
            text-align: center;
        }
        strong {
            @include text(10px, 12px, bold, $white);
            text-align: center;
        }
        @include hover() {
            background-color: $grey;
            span{color:$white}
        }
    }
}
