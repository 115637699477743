$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.toolbar-icon {

  position: relative;
  width: 48px;
  height: 48px;
  background-color: $black;
  border-radius: 32px;
  overflow: hidden;
  transition: 0.2s ease background-color;
  padding: 8px;
  background-clip: content-box;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 48px;
    background-position: center;
    background-repeat: no-repeat;
    filter: $filterGrey;
    transition: 0.2s ease filter;
  }

  @include hover(){
    &::before{
      filter: $filterDarkGrey;
    }
  }

  &.is-burger {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    .rtl-lang & {
      left: unset;
      right: 12px;
    }

    &::before {
      background-image: var(--icon-burger);
    }

    body.has-left-menu & {
      background-color: $grey;
      &::before{
        background-image: var(--icon-burger-open);
        filter: $filterBlack;
      }
      @include hover(){
        background-color: rgba(134, 138, 146, 0.6);
      }
    }

  }

  &.is-profile {

    &::before{
      background-image: var(--icon-profile);
    }
    body.has-right-menu & {
      background-color: $grey;
      &::before{
        background-image: var(--icon-burger-open);
        filter: $filterBlack;
      }
      @include hover(){
        background-color: rgba(134, 138, 146, 0.6);
      }
    }

  }

}
