$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-tab {
    min-width: 140px;
    height: 48px;
    padding: 12px 14px;

    @include text(16px, 20px, bold, $grey);
    font-family: var(--font-family-main);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background-color: $black;    
    text-align: center;
    @include ellipsis;

    &:first-of-type {
        border-radius: 0 0 24px 0;

        .rtl-lang & {
            border-radius: 0 0 0 24px;
        }
    }

    &:last-of-type {
        border-radius: 0 0 0 24px;

        .rtl-lang & {
            border-radius: 0 0 24px 0;
        }
    }

    &__wrap {
        display: flex;
    }

    @include hover() {
        color: rgba(134, 138, 146, 0.5);
    }
}


