$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.header-logo {
  width: 96px;
  height: 48px;
  background: var(--icon-logo) center no-repeat;
  background-size: contain;

  @include hover(){
    opacity: .85;
  }

}

.header-lambo .header-logo {
  display: none;
}
