$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.icon-bg, .icon__before::before, .icon__after::after {
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon__before::before, .icon__after::after{
    content: ' .';
    display: inline-block;
    color: transparent;
    line-height: inherit;
}
