$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-last-dep{
    $root: &;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 193px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 16px 20px;
    border-radius: 12px;
    background-color: $lightGrey;  
    &::before{
        position: absolute;
        content: '';
        bottom: -32px;
        right: -20px;
        width: 150px;
        height: 120px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url($cdn + '/fezbet/profile/fastdep.png');
        background-position: center;
    }
    &.has-no-dep{
        background-color: $yellow;
        .quickdeposit-item, form{ display: none;}
        .profile-title{  @include text(20px, 28px);}
    }  
    .quickdeposit-item{
        background-clip: unset;
        margin-bottom: 8px;
        span{
            @include text(16px, 28px,700 );
            letter-spacing: 0.03em;
            min-width: 80px;
            text-align: center;
            margin-right: 12px;
        }
    }
    &:not(.has-no-dep){
        &::before{ opacity: 0.4; }
        #{$root}__btn{ display: none;} 
        .profile-title{ color: $grey;}
    } 
}