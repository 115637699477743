$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-promo-page {

  padding: 48px 12px 20px;
  position: relative;

  .link-back {
    position: absolute;
    top: 0;
    left: 20px;

    .rtl-lang & {
      left: unset;
      right: 20px;
    }
  }

  .promo-item {
    margin-bottom: 20px;
  }

  @include breakpoint( $bp1 ){
    padding: 48px 20px 20px;
  }

  @include breakpoint( $bp2 ){
    display: flex;
    padding: 48px 28px 20px;

    .promo-item {
      margin-bottom: 0;
      width: calc(100% - 216px);
      order: 2;
    }
  }
}
