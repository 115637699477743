$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-page {
    width: 100%;
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        height: 48px;
        position: relative;
    }
    .link-back {
        flex-shrink: 0;
        margin-right: 8px;
        @include ellipsis;
    }
    &__amount {
        @include text(18px, 84px, bold, $white);
        font-family: var(--font-family-main);
        text-align: right;
        width: 84px;
        @include ellipsis;
        flex-shrink: 0;
    }
    &__logo {
        margin: 0 auto 12px;
        width: 81px;
        height: 36px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    &__title {
        @include text(24px, 28px, bold, $yellow);
        font-family: var(--font-family-main);
        text-align: right;
        @include ellipsis;
        margin: 0 auto;
        &.is-page-1 {
            display: none;
        }
    }
    &__description {
        @include text(14px, 20px, normal, $black);
        text-align: center;
        letter-spacing: 0.03em;
        padding: 0 20px;
        margin-bottom: 12px;
    }
    &__wrap {
        background: $lightGrey;
        box-shadow: 0px 4px 8px rgba(25, 25, 29, 0.5); 
        padding: 12px 0 32px;
        margin-bottom: 12px;
        text-align: center;
    }  
    &__pay-block {
        margin-bottom: 24px;
        .btn-stroke-bold {
            width: 132px;
            margin: 0 8px;
        }
    }
    &__form {
        .form-label { margin: 0 auto 28px;}
    }

    &__terms {
        @include text(14px, 20px, normal, $white);
        text-align: center;
        padding: 0 20px;
        a {
            color: $yellow;
        }
    }

    &__frame {
        width: 320px;
        height: 485px;
        margin: 0 auto;
    }

    @include breakpoint($bp1) {
        &__title {
            font-size: 32px;            
            line-height: 40px;
            &.is-page-1 {
                display: block;
            }
        }
        &__wrap {
            padding: 20px 20px 40px;
            margin: 0 20px 12px;
            border-radius: 12px;
            &.is-page-1 {
                padding: 12px 0;
            }
        }
        &__pay-block {
            margin-bottom: 32px;
        }
        &__frame {
            width: 500px;
            height: 317px;            
        }

    }
    @include breakpoint($bp2) {
        margin-top: 48px;
        width: calc(100% - 136px);
        &__logo {
            margin: 0 auto 20px;
        }
        &__wrap {
            margin: 0 0 12px;
            &.is-page-1 {
                padding: 12px 0;
            }
        }
        &__frame {
            width: 576px;
            height: 365px;
        }
        &__head {
            padding: 0;
            margin-bottom: 4px;
        }
        &__form .form-label { margin: 0 auto 32px;}
    }
    @include breakpoint($bp3) {
        padding: 0 85px;
        width: calc(100% - 176px);
        &__frame {
            width: 590px;
            height: 374px;
        }
    }
    
}
