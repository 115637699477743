$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-body-cashbox {
  display: flex;
  flex-direction: column;  

  position: relative;
  padding: 0 20px;
  width: 100%;
  min-height: 100%;

  background: $lightGrey;
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  color: $black;
  &__body{
    width: 100%;
    flex-grow: 1;
    display: flex;
    & > * {
      width: 100%;
      flex-grow: 1;
    }
  }
  &__footer{
    background: rgba($yellow, 0.1);
    margin: 20px -20px 0;
    padding: 8px 20px 12px;
    flex-shrink: 0;
  }

  @include breakpoint( $bp1){
    max-width: 466px;  
    margin: 40px auto;
    min-height: 300px;
    margin-top: 40px;
    border-radius: 20px;

    &__footer {
      padding: 12px 20px 20px;
      border-radius: 0 0 20px 20px;
    }
  }

  @include breakpoint( $bp2){
    max-width: 600px;
    padding-left: 87px;
    padding-right: 87px;

    &__footer {
      margin: 20px -87px 0;
    }
  }
}
