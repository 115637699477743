$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.no-accordion {
    & > div > div:not(.change-cookies-link-wrapper) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 12px;
        margin-bottom: 16px;
        padding-left: 0;

        & > a {
            display: flex;
            align-items: center;
            background: rgba(255, 220, 38, 0.1);
            border-radius: 12px;
            flex-direction: column;
            justify-content: center;
            padding: 12px 0;
            text-decoration: none;
            @include text(12px, 20px, normal, $white);

            &:hover {
                background-color:  rgba(255, 220, 38, 0.3);
                background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 220, 38, 0.2) 55.73%, rgba(45, 45, 50, 0) 100%);
            }

            img {
                display: none;
            }

            & > div {
                text-align: center;

                & > div:first-child {
                    @include text(13px, normal, normal,  $yellow);
                    font-family: $font;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(45, 45, 50, 0) 100%);
                    background-size: 36px 36px;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    min-height: 36px;
                    padding-left: 40px;

                    &::before {
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-size: contain;
                        content: "";
                        filter: $filterYellow;
                        height: 24px;
                        margin: 0 4px 0 -30px;
                        width: 24px;
                    }

                    .rtl-lang & {
                        padding-left: unset;
                        padding-right: 40px;
                        background-position: right;

                        &::before {
                            margin: 0 -30px 0 4px;
                        }
                    }
                }
            }

            &:first-child {
                & > div {
                    & > div:first-child {
                        &:before {
                            background-image: var(--icon-live-chat);
                            filter: none;
                        }
                    }
                }
            }

            &:last-child {
                & > div {
                    & > div:first-child {
                        &:before {
                            background-image: var(--icon-contacts-email);
                        }
                    }
                }
            }
        }

        @include breakpoint($bp00) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .cookie_link {
        font-size: 14px;
        font-weight: 400;
        color: $yellow;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
