$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rtl-lang & {
        direction: ltr;
    }

    .is-big & {
        flex-direction: row;
        align-items: flex-start;

        .rtl-lang & {
            justify-content: flex-end;
        }

        .tournament-countdown__days {
            margin-inline-end: 4px;
        }

        .tournament-countdown__item {
            width: 40px;
            margin: 0 4px;
            flex-direction: column;
            justify-content: center;
            opacity: 1;
        }

        .tournament-countdown__item_accent {
            margin: 0 4px 0 0;

            &._grey {
                opacity: .5;
            }
        }

        .tournament-countdown__time {
            @include text(28px, 32px, bold, $white);
            font-family: var(--font-family-main);
            text-shadow: 0px 4px 15px rgba(40, 39, 42, 0.1);
            margin: 0;
            text-align: center;
            &:after {
                left: -8px;
                bottom: 4px;
            }
        }

        .tournament-countdown__label {
            display: block;
            @include text(12px, 12px, normal, $white);
            text-align: center;
        }
    }

    &__item {
        display: flex;
        @include text(14px, 20px, bold, $white);
        font-family: var(--font-family-main);
        opacity: .5;

        &:nth-child(1) {
            .tournament-countdown__time::after {
                content: '';
            }
        }
    }

    &__timer {
        display: flex;
        align-items: baseline;

        .tournament-countdown__time::after {
            content: ':';
            position: absolute;
            left: -5px;
            bottom: 0;
        }
    }

    &__time {
        position: relative;
        margin: 0 3px;
    }

    &__label {
        display: none;
        .tournament-countdown__days & {
            display: block;
        }
    }

    @include breakpoint($bp1) {
        flex-direction: row;
        justify-content: flex-start;
    }
}
