$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.notification-item {

  position:relative;
  background-color: $lightGrey;
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  border-radius: 20px;
  padding: 12px 56px 12px 60px;
  box-sizing: border-box;
  opacity: 1;
  width: 100%;

  margin-bottom: 8px;

  transition: 0.3s;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 32px;

  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $black;

  &:before {
    content: '';
    width: 32px;
    height: 32px;
    position: absolute;
    left: 16px;
    top: calc(50% - 16px);
    background-color: #FFFFFF;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }

  &:after {
    content: '';
    width: 48px;
    height: 48px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: $filterGrey;
  }

  @include hover(){
    opacity: .9;
  }

  &.ng-move,
  &.ng-enter {
    transition: 0.5s;
  }
  &.ng-leave{
    transition: 0.001s !important;
  }
  &.ng-leave.ng-leave-active,
  &.ng-move,
  &.ng-enter {
    margin-top: -70px;
  }
  &.ng-leave,
  &.ng-move.ng-move-active,
  &.ng-enter.ng-enter-active {
    margin-top: 10px;
  }

}
