$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.crab {
  &__banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 326px;
    padding: 152px 24px 36px;
    border-radius: 20px;
    background-image: linear-gradient(90deg, rgba(146, 66, 21, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%);
    text-align: center;
    overflow: hidden;

    @include breakpoint($bp2) {
      align-items: flex-start;
      justify-content: center;
      height: 250px;
      padding: 24px 124px;
    }

    @include breakpoint($bp3) {
      padding: 24px 170px;
    }
  }

  &__spheres {
    position: absolute;
    inset: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-image: url($cdn + '/fezbet/shop/crab-bg-1024.webp');
    background-repeat: no-repeat;
    background-position: 85% 50%;
    background-size: cover;

    @include breakpoint($bp2) {
      background-image: url($cdn + '/fezbet/shop/crab-bg-1024.webp'), url($cdn + '/fezbet/shop/crab-bg-1024.webp');
      background-repeat: no-repeat, no-repeat;
      background-position: left center, 100%;
    }
  }

  &__border {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 20px;
    border: 4px solid rgba(255, 220, 38, 0.50);
    pointer-events: none;
  }

  &__toys {
    position: absolute;
    inset: 0;
    z-index: 2;
    pointer-events: none;

    &-owl {
      position: absolute;
      left: 3%;
      top: 77%;
      width: 82px;
      height: 82px;
      background: url($cdn + '/fezbet/shop/crab-toy-owl.webp') no-repeat center;
      background-size: contain;
      transform: rotate(-20deg);

      @include breakpoint($bp2) {
        left: 43%;
        top: 5%;
        width: 130px;
        height: 130px;
        transform: rotate(10deg);
      }
    }

    &-bear {
      position: absolute;
      left: -27%;
      top: 25%;
      width: 108px;
      height: 108px;
      background: url($cdn + '/fezbet/shop/crab-toy-bear.webp') no-repeat center;
      background-size: contain;
      transform: rotate(36deg);

      @include breakpoint($bp1) {
        left: 6%;
      }

      @include breakpoint($bp2) {
        left: 37%;
        top: 60%;
        transform: rotate(-15deg);
      }
    }

    &-octopus {
      position: absolute;
      left: 6%;
      top: 5%;
      width: 70px;
      height: 70px;
      background: url($cdn + '/fezbet/shop/crab-toy-octopus.webp') no-repeat center;
      background-size: contain;
      transform: rotate(15deg);

      @include breakpoint($bp1) {
        left: 28%;
      }

      @include breakpoint($bp2) {
        left: -3%;
        top: 27%;
        width: 140px;
        height: 140px;
      }
    }

    &-chicken {
      position: absolute;
      top: 7%;
      right: 7%;
      width: 42px;
      height: 42px;
      background: url($cdn + '/fezbet/shop/crab-toy-chicken.webp') no-repeat center;
      background-size: contain;
      transform: rotate(-10deg);

      @include breakpoint($bp1) {
        right: 23%;
      }

      @include breakpoint($bp2) {
        top: 85%;
        right: 21%;
        width: 70px;
        height: 70px;
      }
    }

    &-elephant {
      position: absolute;
      right: -60%;
      top: 45%;
      width: 130px;
      height: 130px;
      background: url($cdn + '/fezbet/shop/crab-toy-elephant.webp') no-repeat center;
      background-size: contain;
      transform: rotate(-15deg);

      @include breakpoint($bp1) {
        right: -7%;
      }

      @include breakpoint($bp2) {
        width: 120px;
        height: 120px;
        right: -3%;
        top: 70%;
      }
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    z-index: 5;
    width: 300px;
    height: 200px;
    background-image: url($cdn + '/fezbet/shop/shop-crab-logo-mobile.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;

    @include breakpoint($bp2) {
      width: 470px;
      height: 320px;
      right: 0;
    }

    @include breakpoint($bp3) {
      width: 420px;
      height: 300px;
      top: -10px;
    }

    .rtl-lang & {
      @include breakpoint($bp2) {
        right: unset;
        left: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 5;

    @include breakpoint($bp2) {
      align-items: unset;
    }
  }

  &__chance {
    max-width: 210px;
    margin: 0 auto 16px;
    color: $white;
    font-size: 32px;
    font-weight: 700;
    line-height: 112%;
    text-transform: uppercase;
    text-align: center;

    &:lang(fi),
    &:lang(pl),
    &:lang(el),
    &:lang(de),
    &:lang(hu),
    &:lang(at),
    &:lang(it),
    &:lang(pt),
    &:lang(es) {
      font-size: 24px;
      max-width: 250px;

      @include breakpoint($bp2) {
        max-width: 210px;
      }
    }
    
    &:lang(fr),
    &:lang(pl) {
      font-size: 26px;
    }

    @include breakpoint($bp2) {
      text-align: start;
    }

    &-icon {
      position: relative;
      top: 5px;
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: 4px;
      margin-left: 4px;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      .rtl-lang & {
        margin: 0 4px;
      }
    }
  }

  &__btn {
    a {
      align-items: center;
      display: flex;
      justify-content: center;
      min-width: 150px;
      width: fit-content;
      height: 44px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-decoration: none;

      .linda-has-remembered-login & {
        &.wasnt-logged {
          display: none;
        }

        &.was-logged {
          display: flex;
        }
      }

      &.was-logged {
        display: none;
      }
    }
  }
}

.animation-float {
  animation: float 4s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scaleY(0.95) scaleX(1.05);
  }

  48% {
    transform: scaleY(1.1) scaleX(0.9);
  }

  68% {
    transform: scaleY(0.98) scaleX(1.02);
  }

  80% {
    transform: scaleY(1.02) scaleX(0.98);
  }

  90%, 100% {
    transform: scale(1);
  }
}
