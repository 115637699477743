$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.toolbar-btns {
  display: flex;
  align-items: center;
  max-width: calc(100% - 48px);

  & > * {
    margin-left: 8px;

    @include ellipsis;

    .rtl-lang & {
      margin-left: unset;
      margin-right: 8px;
    }

    &:last-child {
      flex-shrink: 0;
    }
  }

  .icon-plus-white__before {
    &.is-h32.btn-orange {
      position: relative;
      padding: 0 16px;
      margin-left: 0;

      &::before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-size: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
