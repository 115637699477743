$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-btn {
    width: 40px;
    height: 40px;
    background-color: rgba(45, 45, 50, 0.8);
    border-radius: 50%;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;

    &.icon-plus,
    &.icon-minus {
        flex-shrink: 0;
    }

}