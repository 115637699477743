$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.euro-tournament {
  position: relative;
  font-family: $font-roboto;

  &.has-status-0 {
    .tournament-label {
      background-color: $white;
    }

    .tournament-item__content:before {
      background-position: left top;
    }
  }

  &.has-status-2 {
    .tournament-label {
      background-color: $grey;

      &__text {
        color: $white;
      }
    }

    .tournament-item__content:before {
      background-position: -80px top;
    }

    .tournament-item__img {
      filter: grayscale(100%);
    }

    .tournament-item__bg {
      background-image: linear-gradient(180deg, #B5B5B5 0%, #252525 100%) !important;
    }
  }

  &__wrap {
    width: 100%;
    height: 176px;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 128px;
    border-radius: 12px 12px 0 0;
    top: 48px;
    z-index: -2;

    &.euro {
      background-image: linear-gradient(180deg, #DA4453 0%, #89216B 100%);
    }
  }

  &__label {
    position: absolute;
    left: 48px;
    transform: translateX(-50%);
    top: 38px;
    z-index: 3;

    &:lang(ar) {
      left: unset;
      right: 18px;
      transform: unset;
    }
  }

  &__img {
    width: 240px;
    height: 290px;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: calc(25% - 120px);
    z-index: -1;

    .rtl-lang & {
      right: unset;
      left: calc(25% - 120px);
    }

    .is-world-cup & {
      top: 14px;
    }
  }

  &__date {
    @include text(14px, 20px, bold, rgba(255, 255, 255, 0.5));
    @include ellipsis;
    text-align: center;
    max-width: 76px;
    margin: 120px 0 32px;
    margin-inline-start: 10px;

    span {
      display: block;
    }
  }

  &__time-inline {
    @include text(14px, 20px, bold, rgba(255, 255, 255, 0.5));

    display: none;
  }

  &__time {
    max-width: 76px;
    margin: 120px 0 32px;
    margin-inline-start: 10px;

    span {
      display: block;
      text-align: center;
    }
  }

  &__content {
    position: relative;
    background-color: rgba(255, 220, 38, 0.1);
    border-radius: 0 0 12px 12px;
    padding: 16px 16px 14px;

    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      background-image: url($cdn + '/fezbet/tournaments/tournament.png');
      background-position: -40px top;
      background-repeat: no-repeat;
      background-size: cover;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
    }
  }

  &__type {
    @include text(14px, 28px, normal, $white);

    .tournament-sport & {
      display: block
    }
  }

  &__title {
    @include text(20px, 28px, bold, $white);
    @include ellipsis;
  }

  &__value {
    @include text(32px, 36px, bold, $black);
    @include ellipsis;

    margin-top: 8px;
    text-shadow: 0 3px 10px rgba(40, 39, 42, 0.1);
    filter: $filterYellow;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-image: var(--icon-arrow-right-y);
      background-size: 24px 24px;
    }

    &.icon-arrow-right-y {
      background: unset;
    }

    .rtl-lang & {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__btn {
    display: none;
    width: fit-content;
    margin-top: 16px;
  }

  @include breakpoint($bp1) {
    height: 246px;
    margin-top: 20px;
    margin-bottom: 20px;

    &__wrap {
      height: 100%;
      border-radius: 0 0 12px 12px;
      position: absolute;
    }

    &__bg {
      height: 208px;
      border-radius: 12px;
      top: unset;
      bottom: 0;
    }

    &__img {
      top: -10px;
      width: 280px;
      height: 348px;
      inset-block-end: auto;
      inset-inline-start: calc(25% - 140px);
    }

    &__time,
    &__date {
      display: none;
    }

    &__time-inline {
      display: flex;
    }

    &__content {
      position: absolute;
      right: 0;
      top: 38px;
      background-color: transparent;
      border-radius: 0;
      width: 50%;
      z-index: 3;

      &::before {
        content: none;
      }
    }

    &__title {
      margin-bottom: 4px;
    }

    &__value {
      font-size: 36px;
      line-height: 40px;
      margin-top: 0;
      padding-right: 0;
      background-image: none !important;

      &::after {
        content: none;
      }
    }

    &__label {
      top: 30px;
    }

    &__btn {
      display: block;
    }
  }

  @include breakpoint($bp2) {
    &__img {
      inset-inline-end: calc(25% - 156px);
    }
  }
}
