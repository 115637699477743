$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-fade {

  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(25, 25, 29, 0.8), rgba(25, 25, 29, 0.8));
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: -102;

  &.ng-enter {
    transition: all .3s;
    transform: translateY(50px);
    opacity: 0;
    z-index: 9999;
  }
  &.ng-enter.ng-enter-active {
    opacity: 1;
    transform: none;
  }
  &.ng-leave {
    transition: all .3s;
    z-index: 9999;
    opacity: 1;
    transform: none;
  }
  &.ng-leave-active {
    z-index: 9999;
    opacity: 0;
    transform: translateY(50px);
  }

}

.linda-has-popup .popup-fade {
  z-index: 9999;
}
