$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;

@mixin transition {
    transition: all .3s $regDelay;
}
.popup-steps {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%);
    background-repeat: no-repeat;
    background-size: 1px 100%;
    background-position: 8px top, 7px top;
    width: 280px;
    margin: 0 auto;

    &:lang(ar) {
        background-position: right 8px top, right 7px top;
    }

    &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #2B2B30;
        border: 1px solid #6B6B6E;
        border-radius: 50%;
        margin-top: -1px;
        bottom: 120px;
        inset-inline-start: 5px;
        transform: translateY(-50%);
        z-index: 3;
        @include transition;
    }
    &:lang(el) {
        height: 250px;
    }
    &__item-w {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 3;
        transition: all .3s .3s;
        &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: #2B2B30;
            border: 1px solid #6B6B6E;
            border-radius: 50%;
            margin-top: -1px;
            top: 50%;
            inset-inline-start: -21px;
            transform: translateY(-50%);
            z-index: 3;
            @include transition;
        }
    }
    &__item-title {
        @include text(18px, 24px, bold, $grey);
        font-family: var(--font-family-main);
        letter-spacing: 0.02em;
        text-transform: uppercase;
        @include ellipsis;
        opacity: .5;
        @include transition;

        &:lang(el) {
            white-space: unset;
            text-overflow: unset;
            overflow: unset;
        }

    }
    &__item-text {
        @include text(12px, 16px, normal, $black);
        @include ellipsis;
        letter-spacing: 0.02em;
        opacity: 0;
        transform: translateY(20px);
        transition: all .3s;
        position: relative;
        z-index: 4;
    }
    &__item-ico {
        background-image: var(--icon-arrow-right);
        width: 16px;
        height: 16px;
        margin-left: 8px;
        margin-top: -2px;
        flex-shrink: 0;
        opacity: .5;

        .rtl-lang & {
            transform: rotate(180deg);
            margin-left: unset;
            margin-right: 8px;
        }
    }
    &__item {
        height: 48px;
        padding: 12px 0;
        padding-inline-start: 26px;
        padding-inline-end: 8px;
        margin-bottom: 24px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: calc(100% + 24px);
            height: calc(100% + 16px);
            inset-inline-start: -12px;
            top: -8px;
            background-color: transparent;
            border-radius: 12px;
            transform: scale(.7);
            @include transition;
        }

        &:lang(el) {
            &:after {
                height: calc(100% + 36px);
            }
        }

        &.is-done {
            .popup-steps__item-w:before {
                width: 24px;
                height: 24px;
                background-image: var(--icon-checked);
                background-position: center;
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                inset-inline-start: -30px;
                opacity: 1;
                transition: opacity .3s $regDelay;
            }
            @include hover() {
                .popup-steps__item-title {
                    opacity: 1;
                    transition: none;
                }
                .popup-steps__item-ico {
                    opacity: 1;
                }
            }
        }
        &.is-active {
           .popup-steps__item-w {
                width: calc(100% + 18px);
                animation:  fadeUpText .3s 1.3s;
                animation-fill-mode: forwards;
                transform: translateX(-18px);

               .rtl-lang & {
                   width: 100%;
               }

               &::before {
                   opacity: 0;
               }
            }
            &:after {
                background-color: $yellow;
                transform: scale(1);
                @include transition;
            }
            .popup-steps__item-ico {
                background-image: var(--icon-arrow-right-black);
                opacity: 1;
                animation: arrow 3s 1.8s;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

                .rtl-lang & {
                    transform: rotate(180deg);
                    animation: unset;
                }
            }
            .popup-steps__item-title {
                color: $black;
                opacity: 1;
            }
            .popup-steps__item-text {
                opacity: 1;
                animation: fadeUpText .3s 1.4s;
                animation-fill-mode: forwards;
                transition: opacity .3s 1.4s;
            }
            @include hover() {
                &:after {
                    background-color: #ffcc26;
                    transition: none;
                }
            }
        }

        &.is-active:first-child:lang(el) {
            &::after {
                height: calc(100% + 16px);
            }
        }
    }
}

@keyframes fadeUpText {
    0% {
        transform: translate(-18px, 0);
    }
    100% {
        transform: translate(-18px, -8px);
    }
}
@keyframes arrow {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    15%, 45%  {
        transform: translateX(6px);
        opacity: .5;
    }
    30%, 60% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
