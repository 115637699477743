$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-amount{
    font-family: var(--font-family-main);
    width: 132px;
    height: 48px;
    text-align: center;
    @include text(24px, 48px, 700, $black);
    background: #FFFFFF;
    box-shadow: inset 0px 0px 0px 2px #E7E7E4;
    border-radius: 12px;
    transition: .2s ease;
    margin: 12px 4px 20px;
    transition: .2s ease background-color;
    @include hover(){
        box-shadow: inset 0px 0px 0px 2px  $yellow;
    }
    &.is-active{
        background-color: $yellow; 
        border-color: transparent;
        box-shadow: inset 0px 12px 15px rgba(255, 255, 255, 0.8), inset 0px -8px 15px #E88E0F, inset 0px 1px 1px rgba(233, 145, 16, 0.2);
        @include hover(){
           box-shadow: inset 0px 12px 15px rgba(255, 255, 255, 0.8), inset 0px -8px 15px #E88E0F, inset 0px 1px 1px rgba(233, 145, 16, 0.2);
        }
    }
}
