$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-tabs {
    position: relative;
    z-index: 10;
    width: calc(100% + 20px);
    display: flex;
    text-align: center;
    margin: 0 -10px 12px;
    max-height: 80px;
    overflow: hidden;

    a {
        @include text(18px, 40px, 700, $grey);
        position: relative;
        display: block;
        font-family: var(--font-family-main);
        flex-basis: 50%;
        text-transform: uppercase;
        text-decoration: underline;
        transition: 0.2s ease;             
        @include ellipsis;

        .tab-label {
            @include text(12px, 100%, 700, $black);
            padding: 2px 8px 1px;
            background-color: $yellow;
            border-radius: 18px;
            text-decoration: none;
            position: absolute;
            top: 0;
            right: 0;

            .rtl-lang & {
                right: unset;
                left: 0;
            }
        }

        @include hover() {
            color: $black;
        }

        &.is-active {
            font-size: 24px;
            color: $black;
            text-decoration: none;
        }
    }

    &.is-hidden{
        margin: 0;
        max-height: 0;
        opacity: 0;
    }
   
    @include breakpoint($bp1) {
        margin-top: -36px;
        margin-bottom: 16px;
    }

    &.triple-tabs {
        a {
            font-size: 16px;
            flex-basis: 33.3333%;
            padding: 4px 4px 0;

            @include breakpoint($bp0) {
                font-size: 18px;
                padding-top: 8px;
            }

            .tab-label {
                font-size: 10px;

                @include breakpoint($bp0) {
                    font-size: 12px;
                }
            }

            &.is-active {
                font-size: 20px;

                @include breakpoint($bp0) {
                    font-size: 24px;
                }
            }
        }
    }
}
