$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider-nav {
    height: 48px;
    background-image: linear-gradient(to left,rgba(0,0,0,0) 0,rgba(0,0,0,.6) 51.04%,rgba(0,0,0,0) 100%),linear-gradient(to left,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 51.04%,rgba(255,255,255,0) 100%);
    background-repeat: no-repeat;
    background-position: left bottom 9px, left bottom 10px;
    background-size: 100% 1px;
    .slick-current {
        .slider-nav__item {
            color: $white;
            &:after {
                background-color: $white;
                border-color: #999999;
                transform: scale(1) translateX(-50%);
            }
        }
    }
    &__item {
        padding: 12px 4px 8px;
        text-align: center;
        @include text(14px, 20px, bold, rgba(134, 138, 146, 0.5));
        @include ellipsis;
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: scale(0.67) translateX(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $black;
            border: 1px solid $darkGrey;
        }
    }
    @include breakpoint($bp1) {
        margin: 0 8px;
    }
    @include breakpoint($bp2) {
        margin: 0 12px;
    }
    @include breakpoint($bp3) {
        margin: 0 32px;
    }
}
