$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-big-win {
  background: rgba(225, 45, 45, 0.3);
  border-radius: 12px;  
  box-shadow: 0px 4px 8px rgba(25, 25, 29, 0.5);
  .is-grid & {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-bottom: 150%;
    position: relative;
    .widget-big-win__content{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &__content {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    border-radius: 12px;
    margin-bottom: 8px;
  }
  &__title {
    @include text(12px, 16px, bold, $white);
    @include ellipsis;
    font-family: var(--font-family-main);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    max-width: 100%;
  }
  &__amount {
    @include text(20px, 32px, bold, $yellow);
    @include ellipsis;
    font-family: var(--font-family-main);
    max-width: 100%;
  }
  &__name {
    @include text(12px, 16px, bold, $white);
    @include ellipsis;
    margin-bottom: 8px;
    max-width: 100%;
  }
  .show-month {
    display: none;
    .is-month & {
      display: block;
    }
  }
  .hide-month {
    display: block;
    .is-month & {
      display: none;
    }
  }
}
