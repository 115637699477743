$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-random{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 48px 20px;

    &__title{
        @include text(28px, 32px, 700, $black);
        font-family: var(--font-family-main);
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &__box{
        position: relative;
        margin-bottom: 24px;
        margin: 0 -20px;
        height: 300px;
        perspective: 1000px;
        margin-bottom: 24px;
        &.has-preloader{
            > * {
                visibility: hidden;
            }
        }

    }
    &__text{
        @include text(18px, 20px, 400, $black);
        letter-spacing: 0.03em;
    }

  

    @include breakpoint($bp1){
        padding-top: 36px;
        padding: 36px 60px 48px;
        &__box{
            padding: 0 -60px;
        }
    }
}
