$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-category {
    .rtl-lang & {
        padding-left: 14px;
    }

    &__item-wrap {
        width: 100px;
        height: 100px; 
        position: relative;    
        margin-right: 12px; 
        background-color: $yellow;
        box-shadow: inset 0px 6px 7px rgba(255, 255, 255, 0.8), inset 0px -6px 7px #FF7A00;
        border-radius: 20px;
        @include hover() {
            background-color: #EEA315;
            box-shadow: inset 0px -8px 10px rgba(255, 255, 255, 0.4), inset 0px 8px 10px rgba(232, 142, 15, 0.8);
        }
        &:before {
            content: '';            
            padding-top: 100%;                
            display: block; 
        }
        &:first-child {
            margin-left: 14px;
        }
        .rtl-lang & {
            &:first-child {
                margin-left: unset;
            }
        }
        &.is-last {         
            background-color: transparent;            
            border: 1px solid #000;
            border-image-source: var(--icon-border);
            border-image-slice: 22;
            border-image-width: 22px;
            box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.75);
            @include hover() {
                background-color: transparent;
                box-shadow: none;
                .icon-arr { background-image: var(--icon-arr-tap); }
            }
            .sport-category__title {
                color: $yellow;
                font-size: 12px;
                max-height: 24px;
                white-space: pre-wrap;
            }
        }
        .icon-arr {
            width: 32px;
            height: 32px;
            margin: 0 auto;
            background-size: contain;

            .rtl-lang & {
                transform: rotate(180deg);
            }
        }
    }
    &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;   
        padding: 20px 8px 16px;
        .is-last & {
            padding: 14px 8px 16px;
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 9px;
    }
    &__img {
        width: 36px;
        height: 36px;  
        margin-bottom: 1px;   
    }
    &__shadow {
        width: 48px;
        height: 3px;
        background-image: radial-gradient(50% 50% at 50% 50%, rgba(165, 77, 14, 0.4) 0%, rgba(171, 72, 16, 0) 100%);
        background-repeat: no-repeat;
        margin: 0 auto;
    }
    &__title {
        max-width: 100%;
        @include text(14px, 14px, bold, $black);
        @include ellipsis;
        font-family: var(--font-family-main);
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;
        z-index: 2;
    }
    &__text {
        max-width: 100%;
        height: 28px;
        @include text(12px, 14px, bold, $yellow);
        white-space: pre-wrap;
        letter-spacing: 0.05em;
        font-family: var(--font-family-main);
        text-transform: uppercase;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .link-next {
        flex-shrink: 0;
    }

    @include breakpoint($bp2) {
        &__item-wrap {
            width: 112px;
            height: 112px;
        }
    }
}
