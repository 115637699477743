$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-bonus-cancel {
  @include text(16px, 48px, 400, $black);

  position: relative;
  display: inline-block;
  height: 48px;
  font-family: var(--font-family-main);
  text-transform: uppercase;
  padding-left: 20px;
  background-image: unset;
  background-color: transparent;

  .rtl-lang & {
    padding-left: unset;
    padding-right: 20px;
  }

  @include hover() {
    color: #000000;
    filter: $filterGrey;
  }

  &.is-not-black {
    filter: $filterWhite;

    @include hover() {
      filter: $filterYellow;
    }
  }
}
