$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-league {
    background-image: linear-gradient(90deg, rgba(146, 66, 21, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
    border: 2px solid rgba(255, 220, 38, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(25, 25, 29, 0.3);
    border-radius: 12px;
    padding: 0 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    

    &.has-grid-pos {
        grid-row: 2/3;
        grid-column: 2/3;
    }
    &__img {
        background-size: 54px;
        background-repeat: no-repeat;
        background-position: center;

        border: 13px solid rgba(25, 25, 29, 0.4);
        border-radius: 50%;
        width: 80px;
        height: 80px;
    }

    &__title {
        @include text(14px, 20px, bold, $yellow);
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        text-align: center;

        height: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    @include breakpoint($bp1){
        &.has-grid-pos {
            grid-column: 3/4;
        }
    }
    @include breakpoint($bp2){
        &.has-grid-pos {
            grid-column: 4/5;
        }
    }
    @include breakpoint($bp3){
        &.has-grid-pos {
            grid-column: 6/7;
        }
    }
}
