$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.footer-payments {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 7px 0 20px;

  &:last-child {
    padding: 0 0 20px;
  }

  .icon-item {
    height: 18px;
    object-fit: contain;
    margin: 9px;
    max-width: 88px;
  }

  a {
    @include hover(){
      opacity: .85;
    }
    &.is-hidden {
      display: none;
    }
  }

  .icon-box {
    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: inline-block;
      }
    }

    &.was-logged {
      display: none;
    }
  }
}
