$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-paysystem {
    display: block;
    margin: 12px auto 16px;
    width: 140px;
    height: 38px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;

    @include text(10px, 30px, 700, $black);
    @include ellipsis;

    .cashbox-cancel & {
        margin: 0 auto 13px;
    }

    @include breakpoint($bp1) {
        width: 148px;
        height: 40px;
    }

    &._crypto {
        height: 64px;
    }
}
