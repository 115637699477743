$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-close {
  background: var(--icon-close) center no-repeat;
  background-size: contain;
  filter: $filterGrey;
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;

  @include hover() {
    opacity: .85;
  }

  .rtl-lang & {
    right: unset;
    left: 0;
  }

  @include breakpoint( $bp1 ) {
    top: 12px;
    right: 12px;
  }
}
