$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-icon {

  width: 40px;
  height: 40px;

  background-color: $orange;
  box-shadow: inset 0 4px 6px rgba(255, 255, 255, 0.8), inset 0px -4px 6px #CA4D06;
  border-radius: 50%;

  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;

  color: transparent;

  transition: box-shadow .3s;

  @include hover(){
    box-shadow: inset 0px -8px 10px rgba(255, 255, 255, 0.4), inset 0px 8px 10px rgba(232, 142, 15, 0.8);
  }

}
