$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-title {

  max-width: 645px;
  margin: 0 20px 24px 20px;
  text-shadow: 0 3.91304px 14.6739px rgba(40, 39, 42, 0.1);
  @include text(24px, 28px, bold, $yellow);
  font-family: var(--font-family-main);

  @include breakpoint($bp1) {
    margin: 0 60px 32px 60px;
    @include text(32px, 40px);
  }
  @include breakpoint($bp2) {
    margin: 0 100px 52px 100px;
    @include text(40px, 48px);
  }
  @include breakpoint($bp3) {
    font-size: 48px;
    margin: 0 200px 52px;
  }


}
