$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-bank-id {
    padding: 0 24px 0 44px!important;
    position: relative;
    @extend .btn;
    
    &:before {
        content: '';
        position: absolute;
        width: 32px;
        height: 32px;
        left: 6px;
        top: 6px;
        background-image: var(--icon-zimpler);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px;
        background-color: $white;
        box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 18px;
    }
}