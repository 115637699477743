$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-exchanger {

  display: flex;
  flex-direction: column;

  & > * {
    width: 100%;
  }

  .title-page {
    padding-left: 50px;
    margin-bottom: 14px;
  }

  .seo {
    padding: 0;

    h1 {
      min-height: 20px;
    }

    section {
      margin-bottom: 8px;
      &:last-child {
          margin-bottom: 0;
      }
    }
  }

  @include breakpoint($bp1) {
    .seo {
      padding: 0 50px;
    }
  }

  @include breakpoint($bp2) {
    .seo {
      padding: 0 82px;
    }
  }

  @include breakpoint( $bp3 ){

    flex-direction: row;

    & > *:first-child {
      min-width: 500px;
      height: fit-content;
    }

    .seo {
      padding: 0;
    }

  }

}
