$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-fade {

  position: absolute;
  top: 0;
  left: 0;
  z-index: 97;

  width: 100%;
  height: 100%;

  background: linear-gradient(0deg, rgba(25, 25, 29, 0.5), rgba(25, 25, 29, 0.5));

  display: none;
  body.has-left-menu &, body.has-right-menu & {
    display: block;
  }
}
