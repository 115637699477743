$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.show-guest {
    body.linda-has-user & {
        display: none !important;
    }
}
.show-user {
    body:not(.linda-has-user) & {
        display: none !important;
    }
}

.has-text-centered {
    text-align: center !important;
}

$margin-list: 0 15 25 24 8 20 28 22 10 16 18 4 12 36 40 48 19;

@for $i from 1 through length( $margin-list ) {
    .has-margin-bottom-#{nth( $margin-list, $i )} {
        margin-bottom: #{nth( $margin-list, $i )}px !important;
    }
    .has-margin-top-#{nth( $margin-list, $i )} {
        margin-top: #{nth( $margin-list, $i )}px !important;
    }
}

.has-padding-0 {
    padding: 0 !important;
}
.has-padding-bottom-2 {
    padding-bottom: 2px !important;
}
.has-padding-right-0 {
    padding-right: 0 !important;
}
.has-pb-4{
    padding-bottom: 4px !important;
}
.has-bg {
    background-image: url($cdn + '/fezbet/elements/pattern.jpg');
    background-repeat: repeat;
    background-position: left top;
    background-size: 200% auto;
    @include breakpoint($bp1) {
        background-size: 120% auto;
        background-position: right -120px top;
    }
    @include breakpoint($bp2) {
        background-size: 100% auto;
        background-position: center top;
    }
    @include breakpoint($bp3) {
        background-size: 75% auto;
        background-position: left top;
    }
}

.has-line-bg {
    background-image: linear-gradient(to left,rgba(0,0,0,0) 0,rgba(0,0,0,.6) 51.04%,rgba(0,0,0,0) 100%),linear-gradient(to left,rgba(255,255,255,0) 0,rgba(255,255,255,.2) 51.04%,rgba(255,255,255,0) 100%);
    background-position: left bottom 1px,left bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px,100% 1px;
}

.rtl-lang {
    .direction-ltr {
        display: inline-flex;
        direction: ltr;
    }
}

.promo-offer {
    max-width: 100%;

    &__amount {
        white-space: nowrap;
        display: inline-block;
    }
}
