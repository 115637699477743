$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-tgl {

  display: inline-block;

  label {
    display: block;

    background: #FFFFFF;
    border: 2px solid #E7E7E4;
    border-radius: 12px;

    line-height: 40px;
    padding: 0 20px;

    font-weight: bold;
    font-size: 16px;
    color: $grey;

    @include hover(){
      border-color: $yellow;
    }
  }

  input {
    display: none;
  }

  input:checked ~ label {
    color: $black;
    border-color: $yellow;
    background-color: $yellow;
  }

}
