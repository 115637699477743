$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-base{
  --height: 40px;
  --shadow: none;

  display: inline-block;
  padding: 0 24px;
  background-color: var(--bgc);
  box-shadow: var(--shadow);
  border-radius: 50px;
  height: var(--height);
  max-width: 100%;

  font-family: var(--font-family-main);
  @include text(18px, var(--height), 700, $black);
  @include ellipsis;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  transition: 0.2s ease all;

  &.is-h60{
    @include text(24px, var(--height), 700, $black);
    --height: 60px;
    min-width: 270px;
    padding: 0 28px;
  }

  &.is-h44{
    --height: 44px;
    min-width: 200px;
    padding: 0 28px;
  }

  &.is-h36{
    --height: 36px;
    font-size: 14px;
    padding: 0 20px;
  }

  &.is-h32{
    --height: 32px;
    font-size: 14px;
    padding: 0 20px;
  }
  &.is-ferrari-disabled, &[disabled]{
    opacity: 0.5!important;
  }

  &.is-disabled, &[disabled]{
    text-shadow: inset 0px 10px 12px rgba(255, 255, 255, 0.8), inset 0px -10px 12px #E88E0F;
    opacity: 0.2;
  }
}
