$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-balance {
    height: 48px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
    background-position: left 23px top 1px, left 23px top;
    background-repeat: no-repeat;
    background-size: calc(100% - 32px) 1px;

    &.has-child {
        &:after {
            content: '';
            width: 16px;
            height: 16px;
            background-image: var(--icon-arrow-opened);
            background-size: 16px;
            transform: rotate(180deg);
            flex-shrink: 0;
            filter: $filterYellow;
        }
    }
    &.is-opened {
        &:after {transform: none;}
        & + .menu-balance__list {
            display: block;
        }
    }
    @include hover() {
        .menu-balance__text {color: rgba(255, 255, 255, 0.5);}
        .menu-balance__value {color: rgba(255, 220, 38, 0.5);}
        &:after {
            opacity: .5;
        }
    }
    &__list {
        display: none;
    }
    &__text {
        @include text(12px, 16px, normal, $white );
        @include ellipsis;
        max-width: 138px;
        margin-right: 4px;
    }
    &__value {
        @include text(14px, 16px, bold, $yellow);
        @include ellipsis;
        min-width: 82px;
        text-align: right;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        .rtl-lang & {
            margin-right: unset;
            margin-left: 4px;
        }

        &._coin {
            background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
        }

        &._crab {
            background-image: var(--icon-bonus-crab);
        }
    }
}
