$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-tooltip {
  opacity: 0;
  display: none;
  width: 213px;
  background-color: #2e2e36;
  position: relative;
  padding: 12px;
  &::after {
    content: '';
    position: absolute;
    left: 99px;
    bottom: -20px;
    border: 10px solid transparent;
    border-top: 10px solid #2e2e36;
  }

  &.is-right::after {
    left: 142px;
  }

  &:lang(ar) {
    &.is-right::after {
      left: 51px;
    }
  }

  &__title {
    margin-bottom: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  &__name {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    font-weight: 500;
    line-height: 120%;
  }
}
