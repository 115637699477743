$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-registration {

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 500px;
  background: url($cdn + '/fezbet/elements/pattern.jpg') repeat top;
  background-size: contain;

  @include breakpoint( $bp2 ){
    align-items: center;
  }

}
