$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
h1 {
    display: none;
}

section {
    background-color: $white;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 8px;

    &:first-of-type {
        margin-top: 8px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    p {
        @include text(14px, 20px, normal, #28272A);
        letter-spacing: 0.03em;
    }
}

h2 {
    @include text(24px, 28px, bold, $black);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    margin-bottom: 16px;
}

a {
    color: $black;
    text-decoration-line: underline;
}

ul {
    display: block;
    list-style: disc;
    padding-left: 24px;

    .rtl-lang & {
        padding-left: unset;
        padding-right: 24px;
    }

    &.onfido-sdk-ui-DocumentSelector-DocumentList-list {
        .rtl-lang & {
            padding-right: 0;
            padding-left: 0;
        }
    }

    li {
        @include text(14px, 20px, normal, #28272A);
        letter-spacing: 0.03em;
        padding-bottom: 4px;
    }
}

@include breakpoint($bp1) {
    section {
        padding: 24px;
        margin-bottom: 16px;
    }
}
