$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.pnp {
    display: block;
    padding: 30px 10px 40px;
    background-image:
        url( $cdn + '/fezbet/main/sport.png' ),
        url( $cdn + '/fezbet/elements/circle.png'),
        url( $cdn + '/fezbet/elements/circle.png'),
        url( $cdn + '/fezbet/elements/circle.png'),
        url( $cdn + '/fezbet/elements/circle.png'),
        url( $cdn + '/fezbet/mobile/bg.jpg');

    background-position: right 17px top, left -33px top 206px, right 15px top 180px, left 110px top 60px, right 17px top 15px, right -55px top -20px;
    background-repeat: no-repeat;
    background-size:  190px, 60px, 30px, 16px, 130px, 402px auto;
    overflow: hidden;

    &--sport {
        background-image:
            url( $cdn + '/fezbet/main/sport.png' ),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/mobile/bg.jpg');
    }
    &--casino {
        padding-bottom: 44px;
        background-image:
            url( $cdn + '/fezbet/bankid/casino-mainpage.png' ),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/elements/circle.png'),
            url( $cdn + '/fezbet/mobile/bg.jpg');

        @include breakpoint($bp3) {
            padding-bottom: 80px;
        }
    }

    @include breakpoint($bp1) {
        padding-left: 20px;
        padding-right: 20px;
        background-position:
            right 20px top 35px,
            left -28px top 288px,
            right 15px top 210px,
            left 220px top 66px,
            right 32px top 66px,
            right -16px top 31px;
        background-size:
            308px,
            60px,
            30px,
            16px,
            210px,
            402px auto;
    }

    @include breakpoint($bp2) {
        background-position:
            right 27px top 38px,
            left -28px top 322px,
            right 15px top 227px,
            left 334px top 66px,
            right 47px top 67px,
            right -46px top 15px;
        background-size:
            420px,
            60px,
            30px,
            16px,
            285px,
            541px auto;
    }

    @include breakpoint($bp3) {
        padding-left: 80px;
        padding-right: 80px;
        background-position:
            right 98px top 36px,
            left -28px top 362px,
            right 15px top 247px,
            left 398px top 66px,
            right 111px top 67px,
            right 18px top 15px;
    }

    &__title {
        margin-bottom: 4px;
        font-family: var(--font-family-main);
        font-weight: bold;
        font-size: 20px;
        line-height: 1.2;
        text-align: center;
        color: white;

        @include breakpoint($bp1) {
            margin-bottom: 8px;
            font-size: 28px;
            text-align: left;
            max-width: 280px;
        }

        @include breakpoint($bp2) {
            max-width: 440px;
            font-size: 36px;
        }

        @include breakpoint($bp3) {
            margin-bottom: 12px;
        }
    }

    &__form-wrap {
        position: relative;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint($bp1) {
            margin-left: 0;
        }
    }

    &__form-decor {
        position: absolute;
        top: 28px;
        left: calc(100% - 22px);
        width: 124px;
        height: 124px;
        background-image: url( $cdn + '/fezbet/elements/circle.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        z-index: 10;
        pointer-events: none;

        @include breakpoint($bp1) {
            display: none;
        }

        @include breakpoint($bp2) {
            display: block;
        }
    }

    &__form {
        position: relative;
        width: 100%;
        padding: 12px 23px 16px;
        background-color: white;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        overflow: hidden;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url( $cdn + '/fezbet/elements/circle.png'), url( $cdn + '/fezbet/elements/circle.png');
            background-repeat: no-repeat;
            background-size: 39px auto, 67px auto;
            background-position: 122px -18px, right -4px bottom -35px;
            filter: blur(3px);
            transform: scale(1.03);
            z-index: -1;
        }
    }

    &__form-title {
        margin-bottom: 8px;
        font-family: var(--font-family-main);
        font-size: 16px;
        line-height: 18px;
        color: black;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }

    &__buttons-item {
        flex: 1 1 0;
        padding: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-family-main);
        font-size: 18px;
        line-height: 1;
        color: #8c8e90;
        background-color: #e7e7e7;
        border: none;

        &:first-child {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
        }

        &:last-child {
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
        }

        &:not(:first-child) {
            border-left: 1px solid #c4c4c4;
            margin-left: -1px;
        }

        &:hover:not(.active) {
            color: #19191d;
            background-image: linear-gradient(180deg, #fff0a2 0%, #f0aa17 100%);
        }

        &.active {
            pointer-events: none;
            background-color: $yellow;
            color: #555;
        }
    }

    &__input {
        position: relative;
        display: block;
        width: 100%;
    }

    &__input-field {
        display: block;
        width: 100%;
        height: 52px;
        min-width: 0;
        padding: 0 100px 0 42px;
        border: 1px solid #dfdfdf;
        border-radius: 26px;
        background-color: white;
        background-image: var(--icon-paymsystem-zimpler-b);
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 76px auto;
        font-family: var(--font-family-main);
        font-size: 22px;
        line-height: 1;
        color: #19191d;

        // remove arrow buttons
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        -moz-appearance: textfield;

        &.ng-invalid {
            border-color: #e12d2d;
        }
    }

    &__input-icon {
        position: absolute;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-family-main);
        font-size: 22px;
        line-height: 1;
        transform: translateY(-50%);
        color: #19191d;

        &--left {
            left: 21px;
        }
    }

    &__error {
        min-height: 8px;

        &:not(:empty) {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    &__error-item {
        font-family: var(--font-family-main);
        font-size: 10px;
        line-height: 13px;
        color: #e12d2d;
    }

    &__submit {
        display: block;
        margin-bottom: 8px;
        padding: 14px 45px;
        border: none;
        border-radius: 4px;
        width: 100%;
        background-color: $yellow;
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 16'%3E%3Cpath d='M7.788 7.465L1.254.72a.72.72 0 00-1.039 0 .773.773 0 000 1.07L6.231 8 .216 14.208a.774.774 0 000 1.07.72.72 0 001.038 0l6.534-6.743a.782.782 0 000-1.07z' fill='%23080F21'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: calc(100% - 30px) 50%;
        background-size: 8px auto;
        font-family: var(--font-family-main);
        font-weight: bold;
        font-size: 24px;
        line-height: 1;
        text-align: center;
        letter-spacing: 0.02em;
        color: #2d2d32;
        outline: none;
        cursor: pointer;

        @include hover() {
            background-image:
                url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 16'%3E%3Cpath d='M7.788 7.465L1.254.72a.72.72 0 00-1.039 0 .773.773 0 000 1.07L6.231 8 .216 14.208a.774.774 0 000 1.07.72.72 0 001.038 0l6.534-6.743a.782.782 0 000-1.07z' fill='%23080F21'/%3E%3C/svg%3E"),
                linear-gradient(180deg, #fff0a2 0%, #f0aa17 100%);
            background-position: calc(100% - 30px) 50%, 0 0;
            background-size: 8px auto, 100%;
        }
    }

    &__text {
        font-family: var(--font-family-main);
        font-size: 10px;
        line-height: 13px;
        text-align: center;
        color: #868a92;
    }

    &__link {
        color: black;
        text-decoration: underline;

        @include hover() {
            text-decoration: none;
        }
    }
}
