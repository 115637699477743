$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-settings{
    background: $lightGrey;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;   
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    overflow: hidden;
    &__block{
        margin-bottom: 11px;
        padding-bottom: 2px;
        width: 100%;    
    }
    &__item{
        background-color: $white;
        border-radius: 12px;
        color: $black;
        padding: 10px 12px 12px;
        margin-bottom: 12px;
        text-align: center;
        width: 100%;
        height: 100%;
        &:last-child{
            margin-left: auto;
            margin-right: auto;
        }
        &.has-btn{
            padding: 0;
            background: none;
            margin: 0 auto;
        }
    }
    &__label{
        @include text(13px, 20px, normal, $black);
        @include ellipsis;
        margin-bottom: 6px;
    }
    &__info{
        @include text(18px, 24px, bold, $black);
        @include ellipsis;
        font-family: var(--font-family-main);
    }
    &__text{
        background-color: $white;
        @include text(14px, 20px, normal, $black);
        position: relative;
        padding: 16px 14px;
        width: calc(100% + 32px);
        left: -16px;
        margin-right: -32px;
        text-align: center;
        a{            
            @include hover(){
                opacity: .85;
            }
        }
    }
    @include breakpoint( $bp1 ){
        &__block{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &__item{
            width: calc(50% - 6px);
            max-width: 100%;
        }
        .btn-base{
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include breakpoint( $bp3 ){
        &__item{
            width: calc(100%/3 - 8px);
            &.has-btn{
                margin: 0;
            }
            &:last-child{
                margin: 0;
            }
        }
    }
}
