$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-cancel{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title{
        font-family: var(--font-family-main);
        margin-top: 12px;
        @include text(24px, 40px, 700, $black); 
        text-transform: uppercase;
    }
    &__item{
        width: 280px;
        padding: 20px 47px;
        font-weight: 700;
        border-radius: 8px;
        &:not(.has-no-item):nth-child(2n){
            background-color: #fff;
        }
        &.has-no-item{
            margin: auto;
        }
    }

    &__text{
        @include text(14px, 20px);
        margin-bottom: 13px;
    }
    
    &__amount{
        font-size: 18px;
        margin-bottom: 13px;
    }
}
