$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.search-result {

    display: none;

    body.linda-has-search-mode & {
        display: block;
    }

    &__not-found{
        @include text(14px, 20px, 400, #fff);
        padding: 0 20px;
        margin-bottom: 12px;
    }
    @include breakpoint($bp1){
        max-width: 388px;
        margin: 0 auto;
    }

    @include breakpoint($bp1){
        &__not-found{
            padding: 0 16px;
        }
    }
}
