$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-registration-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 420px;
  position: relative;
  z-index: 1;

  &:lang(el) {
    height: 485px;
  }

  &__title {
    width: 100%;
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    color: $yellow;
    margin-bottom: 4px;
  }

  &__text {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    margin-bottom: 20px;

    a {
      color: $yellow;
      @include hover() {
        opacity: .85;
      }
    }
  }

  &__btn-bankid {
    position: absolute;
    bottom: -64px;
  }

  .pay-n-play & {
    margin: 84px 0;
  }
}

