$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.car-banner {
  margin: 8px 14px;
  font-family: 'Roboto Condensed', sans-serif;
  min-height: 325px;
  padding: 16px 16px 24px;
  background-color: #121115;
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    position: absolute;
    width: 600px;
    height: 100%;
    background-position: center;
    bottom: -20px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__label {
    min-width: 111px;
    background-color: #FFDC26;
    border-radius: 8px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    padding: 0 12px;
    @include text(14px, 20px, 700, #19191D);
    margin-bottom: 12px;
    position: relative;
    z-index: 2;
  }

  &__title {
    @include text(30px, 32px, 700, $white);
    text-align: center;
    max-width: 565px;
    margin-bottom: 32px;
    position: relative;
    z-index: 2;

    &.is-yellow {
      color: #FFDC26;
    }

    @include breakpoint($bp1){
      font-size: 32px;
    }
  }

  &__text {
    position: relative;
    text-align: center;
    z-index: 2;
    @include text(24px, 24px, 700, $white);
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    @include text(18px, 24px, 700, $white);
    letter-spacing: .03px;
    background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
    box-shadow: inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
    padding: 0 40px;
    transition: 0.3s;
    min-height: 43px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    border-radius: 50px;
    text-transform: uppercase;

    &-wrap {
      margin-top: auto;
    }

    &:hover {
      background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
      box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
      transform: translateY(-2px);
      transition: 0.3s;
    }

    &:active {
      background: linear-gradient(180deg, #E64E24 0%, #F9A93A 100%);
      box-shadow: none;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}