$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-completion {
  display: flex;
  align-items: center;
  position: relative;
  height: 100px;
  justify-content: center;
  margin: 24px 0;
  &::before {
    content: '';
    position: absolute;
    height: 40px;
    width: 27px;
    background-image: url('/joxi/fezbet/ferrari-promo/union.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-12px);
    top: 0;
    left: 40%;
  }
  &__arrow {
    background-image: url('/joxi/fezbet/ferrari-promo/arrows.svg ');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 76px;
    width: 76px;
  }
  &__text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 24px;
    width: 100%;
    max-width: 172px;
    transform: translateY(40px);
  }
}
