$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-profile-grid{
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr;

  & > * {
    min-width: 0;
  }

  @include breakpoint($bp1){
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "bet bet"
      "deposit win"
      "promo win";

    & > .gr-bet {
      grid-area: bet;
    }

    & > .gr-deposit {
      grid-area: deposit;

      .profile-last-dep {
        height: 100%;
      }
    }

    & > .gr-win {
      grid-area: win;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    & > .gr-promo {
      grid-area: promo;
    }
  }
}
