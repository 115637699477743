$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-balance{ 
    padding: 10px 12px;
    background: #FFFFFF;
    border-radius: 12px;
    text-align: center;
    width: 280px;
    margin: 12px auto 16px;
    &__text{
        @include text(13px, 20px, 400, $black);
        margin-bottom: 6px;
        .is-red & {color: $red;}
    }
    &__amount{
        @include text(18px, 24px, 700, $black);
        .is-red & {color: $red;}
    }
}