$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-dashboard-progress{
    background-color: $grey;
    border-radius: 3px;
    position: relative;
    width: 100%;
    height: 6px;
    margin-bottom: 4px;
    &::before{
        display: block;
        content: '';
        width: 100%;
        height: 6px;
        background-color: $yellow;
        border-radius: 3px;
    }
}