$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider-bnr {
  &__wrap {
    position: relative;

    body.pay-n-play & {
      padding-top: 17px;
    }
  }

  &__img {
    position: absolute;
    width: 190px;
    height: 177px;
    right: 0;
    top: -9px;
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;

    .rtl-lang & {
      right: unset;
      left: -50px;
    }
  }

  &__content {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .rtl-lang & {
      max-height: unset;
      height: 190px;
    }
  }

  &__title {
    @include text(18px, 24px, bold, $white);
    font-family: var(--font-family-main);
    margin-bottom: 5px;

    max-width: 180px;
    max-height: 48px;
    overflow: hidden;
  }

  &__text {
    @include text(32px, 32px, bold, $yellow);
    font-family: var(--font-family-main);
    text-shadow: 0 1px 15px rgba(40, 39, 42, 0.1);

    max-width: 200px;
    max-height: 64px;
    overflow: hidden;

    &:lang(no, ch, de-at, de, pl, es-PE, tr) {
      font-size: 28px;
    }

    &:lang(es-cl, hu) {
      font-size: 26px;
    }

    &:lang(cs) {
      font-size: 24px;
    }

    &:lang(el) {
      font-size: 22px;
    }

    &:lang(it, pt-br) {
      font-size: 21px;
    }

    .rtl-lang & {
      font-size: 28px;
      direction: ltr;
      text-align: right;
      max-height: unset;
    }
  }

  .btn-orange {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  @include breakpoint($bp1) {
    &__wrap {
      body.pay-n-play & {
        padding-top: 21px;
      }
    }
    &__content {
      height: 168px;
    }
    &__img {
      width: 308px;
      height: 287px;
    }
    &__title {
      font-size: 28px;
      line-height: 120%;
      margin-bottom: 4px;

      max-width: 280px;
      max-height: none;
      @include ellipsis;
    }
    &__text {
      font-size: 40px;
      line-height: 44px;

      max-width: 280px;
      max-height: 88px;

      &:lang(no, ch, de-at, de, pl, hu, es-PE, tr) {
        font-size: 40px;
      }

      &:lang(es-cl, cs) {
        font-size: 36px;
      }

      &:lang(el, it, pt-br) {
        font-size: 30px;
      }
    }
    .btn-orange {
      margin-left: 0;
      transform: none;
    }
  }
  @include breakpoint($bp2) {
    &__content {
      height: 218px;
    }
    &__img {
      width: 420px;
      height: 388px;
    }
    &__title {
      font-size: 36px;
      max-width: 440px;
    }
    &__text {
      font-size: 64px;
      line-height: 72px;
      max-width: 440px;
      max-height: 144px;

      .rtl-lang & {
        font-size: 40px;
        line-height: 44px;
      }

      &:lang(no, de-at, de, es-PE) {
        font-size: 64px;
      }

      &:lang(pl, hu, tr) {
        font-size: 60px;
      }

      &:lang(es-cl, ch, cs) {
        font-size: 56px;
      }

      &:lang(it, pt-br) {
        font-size: 46px;
      }
    }
  }
  @include breakpoint($bp3) {
    &__wrap {
      body.pay-n-play & {
        padding-top: 35px;
      }
    }
  }
}
