$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-contacts{
    font-family: var(--font-family-main);
    display: inline-block;
    height: 40px;
    padding: 0 2px;
    @include ellipsis;
    @include text(14px, 24px, 400, $black);
    &::before{
        content: '.';
        color: transparent;
        width: 24px;
        height: 40px;
        line-height: 40px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 4px;
        filter: $filterBlack;
    }

    @include hover(){
        color: $grey;
        &::before{
            filter: $filterGrey;
        }
    }
}
