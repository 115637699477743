$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.table-filter {

  &__date {

    display: inline-block;
    margin: 12px 12px 0 0;

    input {
      -moz-appearance: none;
      -webkit-appearance: none;
      display: block;

      background: #FFFFFF;
      border: 2px solid #E7E7E4;
      border-radius: 12px;

      line-height: 40px;
      padding: 0 20px;

      font-weight: bold;
      font-size: 16px;
      color: $grey;

      min-width: 168px;
    }
  }

}
