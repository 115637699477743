$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.footer {
  background-color: $darkGrey;
  padding: 0 16px;

  &__wrap {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 30px;
  }

  &__live-chat {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
    background-position: left bottom 1px, left bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px, 100% 1px;
    padding: 8px 0 10px;
  }

  .notification-a2hs {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
    background-position: left top 1px, left top;
    background-repeat: no-repeat;
    background-size: 100% 1px, 100% 1px;
    padding: 16px 0;
    margin-top: -4px;
  }

  .footerLicense-icons {
    padding-left: 0;
    gap: 20px;

    li {
      padding-bottom: 0;
      height: 28px;
    }

    &__license {
      width: 34px;
      height: 34px;
    }
  }
  .footerLicense-hiddenText__inner{
    padding-top: 10px;
  }
  .footerLicense-hiddenText{
    margin-bottom: 20px;
  }

  .footerLicense-text p,
  .footerLicense-hiddenText {
    @include text(12px, 16px, normal, $white);

    position: relative;
    direction: ltr;
  }

  .footerLicense-text {
    text-align: center;

    p {
      &:not(&:last-child) {
        margin-bottom: 14px;
      }

      &:last-child {
        letter-spacing: 0.05em;
        color: $grey;
      }
    }
  }

  @include breakpoint($bp1) {
    padding: 0 20px;
    &__icons {
      max-width: 290px;
      margin: 0 auto;
    }
    &__icon-item {
      &_est {
        margin: 12px 0;
      }
    }

  }
  @include breakpoint($bp2) {
    padding: 0 20px;
    &__payments {
      padding: 20px 0 24px;
    }
    &__aff {
      margin-bottom: 24px;
    }
    &__icons {
      margin-bottom: 20px;
    }
    .notification-a2hs {
      padding: 20px 0;
      margin-top: 4px;
    }
  }
}
