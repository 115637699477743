$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-child-item {
    width: 100%;
    height: 50px;
    padding: 14px 44px 16px 60px;
    display: block;

    @include text(16px, 20px, normal, $grey);
    font-family: var(--font-family-main);
    @include ellipsis;

    transition: transform .2s;
    
    &.has-gradient {        
        position: relative;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%), linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 51.04%, rgba(255, 255, 255, 0) 100%);
        background-position: left 16px bottom 1px, left 16px bottom;
        background-repeat: no-repeat;
        background-size: calc(100% - 32px) 1px; 
        @include hover() {
            width: calc(100% + 12px);
            padding: 14px 36px 16px 60px;
            background-size: calc(100% - 48px) 1px;
            transform: translateX(-12px);
            transition: transform .2s;     

            &:after {
                content: '';
                width: 12px;
                height: 50px;
                background-color: $black;
                position: absolute; 
                top: 0;   
                right: 0;        
            }
        }
    }
    &.is-active {
        color: $white;
    }
    @include hover() {
        color: rgba(134, 138, 146, 0.5);
    }
}
