$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-hall {

    background-color: $darkGrey;
    border-radius: 12px;
    padding-bottom: 12px;
    margin-bottom: 8px;

    &.is-game-category{
        padding-top: 60px;
        margin-bottom: 50px;
    }

    body.linda-has-search-mode & {
        display: none;
    }

    &.is-continue-playing {
        border-radius: 0 0 12px 12px;
        padding: 0 12px 12px 12px;
    }

    .btn-icon-category {
        margin-right: 0;
        margin-left: 16px;
        &:last-of-type {
            margin-right: 16px;
        }
    }
}
