$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.search {

    position: relative;
    min-height: 72px;
    width: 100%;
    padding: 20px 0 0;
    transition: 0.3s ease all;
    overflow-x: hidden;
    body.linda-has-search-mode & {
        transform: translateY(-$headerHeight);
        height: calc(100% + #{$headerHeight});
    }
}
