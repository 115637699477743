$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-callback {
    height: 100vh;
    padding-top: 24px;

    @include breakpoint($bp1) {
        padding-top: 40px;
    }

    @include breakpoint($bp2) {
        padding-top: 80px;
    }

    &__logo {
        display: block;
        width: 120px;
        height: 40px;
        background: var(--icon-logo);
        background-size: 100%;
        background-position: center;
        margin: 0 auto;
        flex-shrink: 0;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 24px;
        padding: 0 20px 40px 20px;

        @include breakpoint($bp1) {
            margin-top: 40px;
            padding: 0 130px;
        }

        @include breakpoint($bp2) {
            padding: 0 244px 132px 244px;
            margin-top: 60px;
        }
    }

    &__title {
        @include text(24px, 30px, bold);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        text-align: center;
        font-family: var(--font-family-main);
        margin-bottom: 28px;
    }

    &__subtitle {
        @include text(16px, 20px);
        text-align: center;
        margin-bottom: 12px;
        letter-spacing: 0.03em;
    }

    &__text {
        @include text(14px, 20px);
        margin: 28px 0 10px;
        text-align: center;
        letter-spacing: 0.03em;
    }

    &__ptc {
        display: flex;
        width: 95px;
        justify-content: center;
        @include text(36px, 44px, bold, $white);
        text-shadow: 0 3.91304px 14.6739px rgba(40, 39, 42, 0.1);
        font-family: var(--font-family-main);
        margin: 0 auto 28px auto;
    }

    &__homepage {
        margin: 0 auto;
        text-decoration: none;
    }

    &__minutes,
    &__seconds {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__dots {
        width: 16px;
        text-align: center;
    }

    &__minutes-description,
    &__seconds-description {
        @include text(10px, 16px, normal, $white);
        @include ellipsis;
        max-width: 39px;
    }

    &__bottom {
        @include text(14px, 24px, normal, $white);
        text-align: center;
        margin-top: 30px;

        @include breakpoint($bp2){
            margin-top: 40px;
        }
    }

    &__link {
        @include text(14px, 24px, normal, $white);
        text-decoration: underline;
        display: inline-block;
        vertical-align: baseline;
        padding: 0 4px;
        background-color: transparent;
        border: 0;

        &:first-letter {
            text-transform: uppercase;
        }

        &:hover,
        &:active {
            color: $grey;
        }
    }
}
