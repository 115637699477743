$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-promo-banner {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: calc(100% + 24px);
    min-height: 128px;
    padding: 8px 0 32px;
    padding-inline-start: 32px;
    padding-inline-end: 0;
    margin-left: -12px;
    box-shadow: inset 0 0 0 2px rgba(255, 220, 38, 0.4);
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;  
    opacity: .4;

    &__title {
        width: 100%;
        background-color: $yellow;
        border-radius: 20px 20px 0px 0px;
        @include text(24px, 32px, bold, transparent);
        @include ellipsis;
        font-family: var(--font-family-main);
        text-align: center;
        padding: 8px 28px;

        height: 0;
        opacity: 0;

        position: absolute;
        left: 0;
        top: -2px;
    }  
    &__desc-container {  
        align-items: center;
        display: flex;
        width: calc(100% - 4px);    
        background-color: rgba(255, 220, 38, 0.4);
        
        @include text(14px, 16px, bold, $white);
        font-family: var(--font-family-main);
        text-transform: uppercase;
        text-align: center;
        padding: 10px 12px 6px;  
        margin-left: 2px;  

        max-height: 80px;
        height: 0;
        opacity: 0;

        position: absolute;
        left: 0;
        top: 46px;
        z-index: 2;

        .rtl-lang & {
            text-align: right;
        }
    }
    &__desc {
        overflow: hidden;
        max-height: 56px;
    }
    
    &__text {
        max-width: 168px;
        @include text(14px, 20px, bold, $middleGrey);
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-shadow: 0 4px 15px rgba(40, 39, 42, 0.1);
        margin-bottom: 4px;
    }
    &__text2 {
        max-width: 168px;
        @include text(24px, 26px, bold, $white);
        font-family: var(--font-family-main);
        position: relative;
        text-shadow: 0 1px 15px rgba(40, 39, 42, 0.1);
        z-index: 2;

        &:lang(hu),
        &:lang(cl) {
            font-size: 20px;
        }
    }
    .link-arrow {
        max-width: 168px;
        line-height: 22px;
        @include ellipsis;
        position: absolute;
        bottom: 10px;
        inset-inline-start: 30px;
        background-image: unset;

        &::before {
            background-image: var(--icon-arrow-right-y);
        }
    }
    .btn {
        height: 0;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    &__img {
        width: 168px;
        height: 168px;
        background-image: url($cdn + '/fezbet/popups/registration.png');
        background-size: contain;
        position: absolute;
        bottom: -16px;
        inset-inline-end: -12px;

        .rtl-lang & {
            scale: -1 1;
        }
    }
}
