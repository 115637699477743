$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-page {
    background-image: url($cdn + '/fezbet/vip/bg-vip.png');
    padding-top: 20px;
    background-size: contain;
    background-position: 100px 0;
    background-repeat: no-repeat;
    overflow: hidden;

    @include breakpoint($bp1) {
        padding-top: 28px;
    }
    @include breakpoint($bp2) {
        padding-top: 40px;
    }
}
