$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-banner-second {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    border-top: 1px solid rgba(255, 255, 255, 0.35);
    width: 100%;
    transform: translateY(-150px);
    position: absolute;
  }

  &__text {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    max-width: 202px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      height: 32px;
      width: 32px;
      background-image: url('/joxi/fezbet/ferrari-promo/fire.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      top: -40px;
    }

    &:lang(ar)::before {
      scale: -1 1;
    }

    &.is-reboot::before {
      background-image: url('/joxi/fezbet/ferrari-promo/reboot.svg');
    }
  }

  &__terms {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    padding: 16px 8px;
    width: 100%;
  }
}
