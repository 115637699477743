$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-load{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background-color: $yellow;
    box-shadow: inset 0 6px 8px rgba(255, 255, 255, 0.8), inset 0 -6px 8px #E88E0F;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    font-family: var(--font-family-main);
    @include text(12px, 16px, bold, $black);
    letter-spacing: 0.03em;
    text-align: center;
    padding: 7px 4px 4px;
    text-transform: uppercase;
    text-shadow: 0 0 5px $yellow;
    transition: .2s ease;

    &:lang(ru),
    &:lang(pt),
    &:lang(el) {
        font-size: 10px;
    }
   
    @include hover() {
        background: #EEA315;
        box-shadow: inset 0px -8px 10px rgba(255, 255, 255, 0.4), inset 0px 8px 10px rgba(232, 142, 15, 0.8);
    }

    &.is-loading{
        background-image: var(--icon-btn-load);
        background-position: center top -2px;
        background-color: $black;
        @include text(12px, 10px, normal, $yellow);
        box-shadow: none;
        padding-top: 17px;
        span {
            display: none;
        }
        &::after{
            content: '';
            position: absolute;
            margin: auto;
            top: 30px;
            left: 0px;
            right: 0;
            width: 20px;
            height: 20px;
            background-image: var(--icon-preloader);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            filter: $filterYellow;
        }
    }

    &.is-hidden{
        height: 0;
        opacity: 0;
    }
}
