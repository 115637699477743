$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-promotions {
  display: inline-block;
  column-count: 1;
  margin: 12px 12px 4px;

  .promo-item {
    display: inline-table;
    width: 100%;
    margin: 0 0 16px;

    &.livecasino-none {
      display: none;
    }
  }

  @include breakpoint( $bp1 ){
    margin: 12px 0 4px;
  }

  @include breakpoint( $bp2 ){
    margin: 0 0 4px;
    padding-right: 28px;
    column-count: 2;
    column-gap: 16px;
  }

}
