$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sidemenu-user {
    display: flex;
    padding: 0 23px;

    &__w {
        max-width: calc(100% - 75px);
        margin-top: 12px;
    }
    &__img {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        border-radius: 5px;
        width: 64px;
        height: 64px;
        margin-right: 11px;
        flex-shrink: 0;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 64px;
            height: 44px;
            background-color: $yellow;
            border-radius: 5px;
            top: 12px;
            z-index: -1;

        }
    }
    &__lvl {
        @include text(12px, 16px, normal, $grey);
        @include ellipsis;
        letter-spacing: 0.03em;
        margin-bottom: 4px;
    }
    &__name {
        @include text(16px, 24px, bold, $white);
        @include ellipsis;        
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
    }
}
