$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-game-week {
  height: 220px;
  background: rgba(225, 45, 45, 0.3);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  position: relative; 
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 147px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__container{
    width: calc(100% - 147px);
    padding: 16px 0;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  &__title {
    @include text(16px, 20px, bold, $white);
    font-family: var(--font-family-main);
    @include ellipsis;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 8px;
  }
  &__video {
    width: 172px;
    height: 112px;
    display: block;
    margin: 0 auto 8px;
    border-radius: 8px;
  }
}
