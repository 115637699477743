$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-control {
    width: 28px;
    height: $inputHeight;
    position: absolute;
    top: 20px;
    right: 0;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    transition: .2s background-image;

    .rtl-lang & {
        right: unset;
        left: 0;
    }

    .form-label & {
        top: 0;
        line-height: $inputPromoHeight;
    }

    &.is-currency {
        @include text(16px, 16px, bold);

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $middleGrey;
        width: 32px;
        height: 38px;
        position: absolute;
        right: 49px;
        top: 1px;
        border-radius: 0px 12px 12px 0px;
        padding: 12px 8px;
        font-family: var(--font-family-main);
    }

    &.icon-password-on,
    &.icon-password-off {
        width: 40px;
        background-position: center;
    }
}
