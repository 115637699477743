$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-body-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 0 20px 70px;

  background: $lightGrey;
  box-shadow: 0px 4px 8px rgba(25, 25, 29, 0.5);
  @include text(14px, 20px, normal, $black);

  &:after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(180deg, rgba(246, 246, 244, 0) 0%, $lightGrey 50%, $lightGrey 100%);
    background-size: 100%;
    background-position: left top;
    z-index: 7;
  }
  .ferrari-terms-title {
    font-family: var(--font-family-main);
    padding-top: 48px;
  }
  .ferrari-terms-info {
    color: #000;
    max-height: 100%;
    background: none;
    &::before {
      z-index: -1;
    }
  }
  .ferrari-terms-info h2 {
    color: #000;
  }
  .ferrari-terms-info section {
    padding: 0;
    background: none;
  }
  .ferrari-terms-info section p {
    color: #000;
  }

  .popup-title {
    padding-top: 48px;
  }

  p {
    margin-bottom: 8px;
  }
  p + :not(p) {
    padding-top: 9px;
    padding-bottom: 8px;
  }
  strong {
    margin-right: 4px;
  }
  a {
    text-decoration: underline;
    color: $black;
  }
  tr {
    border-bottom: 1px solid #868a92;
    &:nth-child(1) td:nth-child(2) {
      line-height: 16px;
      padding-bottom: 5px;
    }
  }
  td {
    padding-bottom: 9px;
    line-height: 28px;
    &:nth-child(1) {
      font-weight: bold;
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
  .btn {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 8;
    width: fit-content;
  }
  @include breakpoint($bp1) {
    table {
      width: 400px;
      margin: 0 auto;
    }
  }
}

.holiday-terms {
  display: flex;
  flex-flow: column;

  ol {
    counter-reset: index;
    list-style-position: inside;

    ::marker {
      display: none;
      font-size: 0;
    }

    li {
      margin-bottom: 0.5em;

      &::before {
        font-weight: bold;
        counter-increment: index;
        content: counters(index, '.') '. ';
      }
    }

    ol {
      margin: 0.5em 0 0 2em;
    }
  }
}
