$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-item {
    width: 100%;
    position: relative;

    &.is-form-promo {
        text-align: center;
    }

    @include hover() {
        .form-input {
            border-color: $black;
            box-shadow: none;

            &.ng-invalid.ng-touched:not(.novalidate) {
                border-color: $black;
                box-shadow: none;
            }
        }

        .form-label {
            font-weight: 700;
        }
    }
}
