$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-page {
  overflow-x: hidden;

  &.is-status-0 {
    .tournament-label {
      background-color: $white;
    }

    .tournament-page__info:before {
      background-position: left top;
    }
  }

  &.is-status-2 {
    .tournament-page__wrap {
      background-image: linear-gradient(180deg, #B5B5B5 0%, #252525 100%);
    }

    &.is-euro2024-open {
      .tournament-page__wrap {
        background-image: none;
      }

      .tournament-page__wrap-bg {
        background-image: linear-gradient(180deg, #B5B5B5 0%, #252525 100%);
      }
    }

    .tournament-page__img {
      filter: grayscale(100%);
    }

    .tournament-label {
      background-color: $grey;

      &__text {
        color: $white;
      }
    }

    .tournament-page__info:before {
      background-position: -80px top;
    }
  }

  &__wrap {
    height: 100%;
    position: relative;
    padding: 0 8px 56px;
    margin: 8px 0 20px;
    background-image: linear-gradient(180deg, var(--color1), var(--color2));
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(180deg, rgba(25, 25, 29, 0) 0%, #19191D 100%);
    }

    .is-euro2024-open & {
      background-image: none;

      &:after {
        content: none;
      }
    }
  }

  &__wrap-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 538px;
    background-image: linear-gradient(180deg, var(--color1), var(--color2));
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(180deg, rgba(25, 25, 29, 0) 0%, #19191D 100%);
    }

    &.euro {
      background-image: linear-gradient(180deg, #DA4453 0%, #89216B 100%);
    }
  }

  &__promo-slider {
    padding: 0 14px;
  }

  &__events-list {
    padding: 0 14px;
    direction: ltr;
  }

  &__img {
    width: 260px;
    height: 348px;
    position: absolute;
    top: -48px;
    inset-inline-end: -4px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

    &:lang(ar) {
      scale: -1 1;
    }
  }

  &__label {
    position: absolute;
    inset-inline-start: 48px;
    top: -10px;
    transform: translateX(-50%);
    z-index: 3;

    &:lang(ar) {
      transform: translateX(50%);
    }
  }

  &__date {
    @include text(14px, 20px, bold, rgba(255, 255, 255, 0.5));
    font-family: var(--font-family-main);
    text-align: center;
    position: absolute;
    inset-inline-start: -10px;
    top: 70px;

    &:lang(ar) {
      inset-inline-start: 0;
    }

    span {
      display: block;
    }
  }

  &__user-cont {
    background-repeat: no-repeat;
    background-position: center 4px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding: 20px 5px 0;

    @include text(12px, 16px, bold, $white);
    font-family: var(--font-family-main);
    @include ellipsis;

    position: absolute;
    inset-inline-start: 28px;
    top: 22px;
  }

  &__info {
    width: 284px;
    margin: 0 8px;
    position: relative;
    z-index: 3;
  }

  &__banner {
    width: 100%;
    height: 222px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    @include text(20px, 28px, bold, $white);
    display: block;
    font-family: var(--font-family-main);
    @include ellipsis;
    padding-top: 130px;
    margin-bottom: 12px;
  }

  &__sport-title {
    @include text(20px, 28px, bold, $white);
    display: block;
    font-family: var(--font-family-main);
    @include ellipsis;
    margin-bottom: 12px;
  }

  &__value {
    @include text(32px, 36px, bold, $black);
    font-family: var(--font-family-main);
    @include ellipsis;
    text-shadow: 0px 3px 10px rgba(40, 39, 42, 0.1);
    filter: $filterYellow;
    margin-bottom: 8px;
  }

  &__link,
  &__link:lang(eu) {
    max-width: 100%;
  }

  .link-back {
    margin-inline-start: 20px;
    width: 100%;
    position: relative;
    z-index: 8;
  }

  .tournament-top {
    margin: 0 20px;
  }

  &__info-secondary {
    @include text(18px, 130%, 700, $white);

    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 0 8px;
    margin: 12px 0 36px;
  }

  &__info-label {
    color: rgba(255, 255, 255, 0.5);
  }

  &__info-block {
    display: grid;
    margin-bottom: 8px;
  }

  &__btn {
    display: block;
    width: fit-content;
    text-decoration: none;
    margin: 10px 0;
  }

  &__events {
    margin: 0 -8px;

    &.layout-horiz {
      display: flex;
    }
  }

  &__events-title.link-title {
    line-height: 125%;
    padding: 14px 20px 14px 24px;

    &::before {
      background-position: right center, left 13px center, left 12px center;
    }
  }

  @include breakpoint($bp1) {
    padding-right: 16px;

    &__promo-slider,
    &__events-list {
      padding: 0 24px;
    }

    &__wrap {
      border-radius: 0px 12px 0px 0px;
      padding: 0 0 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__wrap-bg {
      height: 466px;
      border-radius: 0px 12px 0px 0px;
    }

    &__img {
      width: 280px;
      height: 348px;
      left: -9px;
    }

    &__date {
      position: relative;
      left: 0;
      top: auto;
      text-align: left;
      margin-bottom: 8px;

      span {
        display: inline-block;
      }
    }

    &__info {
      width: 240px;
    }

    &__banner {
      justify-content: flex-end;
    }

    &__title {
      padding-top: 12px;
      margin-bottom: 4px;
    }

    &__value {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 0;
    }

    .tournament-top {
      width: 400px;
      margin: 0 auto;
    }

    &__info-secondary {
      width: 100%;
      padding: 0 24px;
    }

    &__events {
      width: calc(100% + 16px);
      margin: 0 -16px 0 0;
    }

    &__events-title.link-title {
      padding: 14px 20px 14px 34px;

      &::before {
        background-position: right center, left 23px center, left 22px center;
      }
    }
  }

  @include breakpoint($bp2) {
    display: flex;
    flex-wrap: wrap;

    &__wrap {
      width: calc(100% - 252px);
    }

    .tournament-top {
      width: 240px;
      margin: 0;
      margin-left: 12px;
    }

    &__info {
      width: 230px;

      &:before {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        background-image: url($cdn + '/fezbet/tournaments/tournament.png');
        background-position: -40px top;
        background-repeat: no-repeat;
        background-size: cover;
        top: 96px;
        right: -28px;
        z-index: 9;
      }
    }

    &__title {
      padding-top: 28px;
      margin-bottom: 8px;
    }

    &__date {
      margin-top: 12px;
      margin-bottom: 20px;
    }

    &__link {
      margin-bottom: 12px;
    }
  }

  @include breakpoint($bp3) {
    &__wrap {
      width: calc(100% - 292px);
      margin-top: 26px;
    }

    &__info {
      width: 330px;

      &:before {
        top: 123px;
      }
    }

    &__title {
      font-size: 24px;
      line-height: 28px;
      padding-top: 40px;
      margin-bottom: 8px;
    }

    &__value {
      font-size: 48px;
      line-height: 52px;
    }

    &__date {
      font-size: 18px;
      margin-top: 16px;
    }

    &__user-cont {
      left: 40px;
    }

    &__img {
      width: 400px;
      height: 512px;
      top: -74px;
      left: -25px;
    }

    .tournament-top {
      width: 280px;
    }
  }
}
