$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-top-league {
    height: 64px;
    text-align: center;

    .rtl-lang & {
        padding-left: 16px;
    }

    &__item {
        display: inline-block;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: $white;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 16px;

        &:first-child {
            margin-left: 16px;
        }

        .rtl-lang & {
            &:first-child {
                margin-left: unset;
                margin-right: 16px;
            }
        }

        @include hover() {
            opacity: .5;
        }
    }
}
