$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-radio {
    display: flex;
    width: 100%;
    height: 36px;

    &__item {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 8px;

      @include hover() {
        .form-radio__input {
          border-color: $black;
        }
      }

      .rtl-lang & {
        padding-left: unset;
        padding-right: 8px;
      }
    }

    &__input {
        width: 20px;
        height: 20px;
        background: #ffffff;
        margin-right: 8px;
        border-radius: 50%;
        transition: .2s all;
        position: relative;
        border: 1px solid $grey;
        &::after {
            opacity: 0;
            content: '';
            background-image: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            left: 3px;
            transition: 0.2s ease opacity;
        }
        input:checked ~ & {
            &::after {  opacity: 1; }
        }

      .rtl-lang & {
        margin-right: unset;
        margin-left: 8px;
      }
    }

    &__text {
        @include ellipsis;
        @include text(16px, 20px, 400, $black);
    }

    input {
      width: 0;
      height: 0;
      position: absolute;
      opacity: 0;
    }
}
