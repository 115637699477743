$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-back{
    display: flex;
    align-items: center;
    height: 48px;
    background-color: #ffffff;
    margin: 0 -20px;
    width: calc(100% + 40px);
    padding: 0 20px;
    opacity: 0;
    @include breakpoint($bp1){
        height: 72px;
        border-radius: 20px 20px  0 0;
    }
    @include breakpoint($bp2){
        width: calc(100% + 174px);
        margin: 0 -87px;
    }

    &.is-shown{
        opacity: 1;
    }
}