$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-tournament {
  position: relative;  
  height: 278px;
  &.has-status-0 {
    .tournament-label {
      background-color: $white;
    }
    .widget-tournament__content:before {
      background-position: left top;
    }
  }
  &.has-status-2 {
    .tournament-label {
      background-color: $grey;
      &__text {color: $white;}
    }
    .widget-tournament__content:before {
      background-position: -80px top;
    }
    .widget-tournament__img {
      filter: grayscale(100%);
    }
    .widget-tournament__bg {
      background-image: linear-gradient(180deg, #B5B5B5 0%, #252525 100%)!important;
    }
  }
  &__wrap {  
    width: 100%;
    height: 176px;   
    position: relative;   
    z-index: 2;
    overflow: hidden;          
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 128px;
    border-radius: 12px 12px 0 0;
    top: 48px;
    z-index: -2;
  }

  &__label {
    position: absolute;
    left: 48px;
    transform: translateX(-50%);
    top: 38px;
    z-index: 3;
  }
  &__img {
    width: 240px;
    height: 290px;
    background-size: contain;   
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute; 
    top:0;
    right: calc(25% - 120px);
    z-index: -1;
      
  }
  &__user-cont {
    background-repeat: no-repeat;
    background-position: center 4px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding: 20px 5px 0;
    margin: 70px 0 10px 28px;

    @include text(12px, 16px, bold, $white);
    font-family: var(--font-family-main);
    @include ellipsis;

    position: relative;
    z-index: 2;
  }
  &__date {
    @include text(14px, 20px, bold, rgba(255, 255, 255, 0.5));
    @include ellipsis;
    font-family: var(--font-family-main);
    text-align: center;
    max-width: 76px;
    margin: 120px 0 32px 10px;   
    span {display: block;}
  }
  &__time {        
    max-width: 76px;
    margin: 120px 0 32px 10px;
      
    span {
      display: block;
      text-align: center;
    }
  }  
  &__content {
    position: relative;        
    background-color: rgba(255, 220, 38, 0.1);
    border-radius: 0 0 12px 12px;
    padding: 16px 16px 14px;
    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      background-image: url( $cdn + '/fezbet/tournaments/tournament.png');
      background-position: -40px top;
      background-repeat: no-repeat;
      background-size: cover;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
    }
  }  
  &__title {
    @include text(20px, 28px, bold, $white);
    font-family: var(--font-family-main);
    @include ellipsis;
  }
  &__value {        
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: right center;        
    padding-right: 32px;
    margin-top: 8px;
    @include text(32px, 36px, bold, $black);
    font-family: var(--font-family-main);
    @include ellipsis;
    text-shadow: 0px 3px 10px rgba(40, 39, 42, 0.1);
    filter: $filterYellow;
  }
  &__btn {
    display: none;
    width: fit-content;
    margin-top: 35px;
}

  &__top-users {
    display: none;
  }
  
  &__games { display: none; }
  @include breakpoint ($bp1) {
    height: 228px;
    &__wrap {
      height: 100%;            
      border-radius: 0 0 12px 12px;
    }
    &__bg {
      height: 100%;
    }
    &__img {
      width: 280px;
      height: 348px;
      right: auto;
      left: calc(25% - 140px);        
    }
    &__date {
      position: absolute;
      left: auto;
      right: 16px;
      top: 132px;
      width: 234px;
      max-width: none;
      text-align: left;
      margin: 0 0 32px 0;
      z-index: 3;
      span {display: inline-block;}
    }
    &__time { 
      position: absolute;
      right: 16px;
      top: 132px;
      width: 234px;
      max-width: none;
      margin: 0 0 32px 0;
      z-index: 3;
      span {
        display: inline;
      }
    }
    &__content {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      position: absolute;
      right: 16px;
      top: 60px;
      width: 234px;
      z-index: 3;
      &:before {
        content: none;            
      }
    }
    &__title {
      margin-bottom: 4px;
    }
    &__value {
      font-size: 36px;
      line-height: 40px;
      margin-top: 0;
      padding-right: 0;
      background-image: none!important;
    }
    &__btn {
      display: block;
    }
  }
  @include breakpoint ($bp2) {
    align-items: flex-end;
    height: 228px;    
    &.has-status-2 {
      .widget-tournament__content:before {
        background-position: -64px top;
      }
    }
    &__wrap {
      width: 480px;
      flex-shrink: 0;
    }
    &__bg {
      top: 48px;
    }
    &__date,
    &__time {
      width: 222px;
      top: 131px;
      left: 244px;
      right: auto;
    }
    &__img {
      width: 280px;
      height: 348px;
      right: calc(25% - 140px);
    }
    &__content {
      max-width: 220px;
      top: 60px;
      left: 244px;
      right: auto;
      &:before {
        content: '';
        width: 32px;
        height: 32px;
        background-position: -32px top;
        left: auto;
        right: -32px;
        top: 50%;
        transform: translateY(-50%); 
      }      
    }
    &__games {
      display: flex;
      align-items: center;
      height: 180px;
      overflow: hidden;
      background: $darkGrey;
      margin-left: -11px;
      padding-left: 23px;
      position: relative;
    }
    &__games-bg {
      position: absolute;
      width: 160px;
      height: 100%;
      right: 0;
      top: 0;
      background-image: linear-gradient(270deg, #2D2D32 0%, rgba(45, 45, 50, 0) 100%);
      z-index: 2;
      @include hover() {
        .link-arrow {opacity: .5;}
      }
    }
    .link-arrow {
      position: absolute;
      right: 18px;
      bottom: 14px;
      line-height: 20px;
    }
  }
  @include breakpoint ($bp3) { 
    &__games-bg {width: 417px;}
  }
}
