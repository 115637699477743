$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-not-empty {
    background-color: $lightGrey;
    min-height: 193px;
    border-radius: 12px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    padding: 4px 0 8px 0;
    width: 100%;
    height: 100%;

    &.is-biggest-win {
        height: auto;
        margin-bottom: 12px;
    }

    &.is-latest-win {
        flex-direction: column;
        height: auto;
        max-height: 380px;
        margin-top: 12px;
        padding: 0 16px 20px 16px;
        
        @include breakpoint($bp1) {
            max-height: 398px;
        }
    }
    &__content {
        width: calc(100% - 113px);
        margin-left: auto;
        padding-right: 8px;
        overflow: hidden;
    }
    &__title {
        @include ellipsis;
    }
    &__sum {
        @include text(20px, 32px, bold, $yellow);
        @include ellipsis;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-family: var(--font-family-main);
    }
    &__text {
        @include text(12px, 20px, normal, $black);
        margin-bottom: 20px;

        a {
            text-decoration: underline;
        }
    }
    &__image {
        width: 101px;
        height: 153px;
        box-shadow: 0 2px 4px rgba(25, 25, 29, 0.3);
        border-radius: 12px;
        margin: 16px 8px 0 12px;
        background-size: cover;
        background-position: center;
    }
}
