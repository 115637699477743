$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-live-casino {
  height: 220px;
  background-color: rgba(225, 45, 45, 0.3);
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  &__content {
    width: 128px;
    height: 100%;
    padding: 16px 0 4px;
    display: flex;
    flex-direction: column;
  }
  &__title {
    @include text(16px, 20px, bold, $white);
    font-family: var(--font-family-main);
    @include ellipsis;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding: 0 8px 0 16px;
    margin-bottom: 24px;
    @include hover() {
      opacity: .8;
    }
  }
  &__provider {
    @include text(14px, 52px, normal, $white);
    @include ellipsis;
    height: 52px;
    padding: 0 8px 0 56px;
    background-position: left 16px center;
    background-size: 32px;
    background-repeat: no-repeat;
    @include hover() {
      color: $yellow;
    }
  }
  &__img {
    width: calc(100% - 128px);
    height: 100%;
    background-image: url($cdn + '/fezbet/main/live-casino.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
