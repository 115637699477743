$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-bet-item{
    box-shadow: 0px 2px 4px rgba(25, 25, 29, 0.2);
    border-radius: 12px;
    background-color: #fff;
    margin: 0 4px;
    padding: 12px 0 0 0 ;
    width: 200px;
    height: 208px;
    .profile-bet & {
        display: inline-flex;
        flex-direction: column;
    }
    &__title{
        padding: 0 12px;
        display: grid;
        grid-auto-rows: 20px; 
        grid-template-columns: 76px auto;
        margin-bottom: 4px;
    }

    &__mark{
        position: relative;
        display: inline-block;
        border-radius: 8px;
        background: $lightGrey;
        padding: 0 8px 0 20px;
        @include text(14px);
        font-family: var(--font-family-main);
        @include ellipsis;
        max-width: 100%;
        &::before{
            content: '';
            position: absolute;
            left: 8px;
            top: 6px;
            width: 8px;
            height: 8px;
            border: 2px solid $middleGrey;
            background-color: $yellow;
            border-radius: 50%; 
        }

        &.is-multiple{
            &::before{
                background-color: $red;
            }
        }

        &.is-system{
            &::before{
                background-color: $orange;
            }
        }
    }

    &__grid{
        padding: 0 12px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 4px;
        &:nth-child(2){
            grid-auto-rows: 24px;
            flex-grow: 1; 
            margin-bottom: 8px;
        }
        &:last-child{
            background-color: $yellow;
            padding-top: 4px;
            border-radius: 0px 0px 12px 12px;
            .has-lost &{
                background-color: $middleGrey;
            }
        }
    }

    &__cell{
        @include text(12px, 20px, 700, $black);
        overflow: hidden;
        text-align: right;
        &:nth-child(2n + 1){
            text-align: left;
            font-weight: 400;
            color: $grey;
        }

        &.is-strong{
            font-weight: 700;
            color: $black;
        }

        &.is-date{
            color: $black;
            line-height: 16px;
        }

        &.is-time{
            @include text(18px, 20px, 700, $black);
            font-family: var(--font-family-main);
        }
    }

    &__link{
        grid-column-start: -1;
        grid-column-end: 1;
        .link-arrow{
            max-width: 100%;
        }
    }
    
    // по дефолту показується win 
    .is-lost{
        display: none;
    }

    &.has-lost{
        .is-win{ display: none;}
        .is-lost{ display: block;}
    }
}
