$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-tooltip {
    @include text(12px, 20px, 400, $black);

    display: none;
    width: 100%;
    border-radius: 10px; 
    background: $inputBg;
    text-align: left;
    padding: 8px 8px 4px;
    margin-top: 8px;
    filter: drop-shadow(0px 2px 4px rgba(25, 25, 29, 0.2));

    &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 0;
        border-style: solid;
        border-width: 0 12px 12px 12px;
        border-color: transparent transparent #ffffff transparent;
    }

    .rtl-lang & {
        text-align: right;
    }

    & > * {
      padding-left: 20px;

        .rtl-lang & {
            padding-left: unset;
            padding-right: 20px;
        }
    }
  
    .has-tooltip.ng-invalid:focus ~ & {
      display:block;
    }
  
    &__min,
    &__number,
    &__low,
    &__up {
      background-image: var(--icon-crossed-red);
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: left center;

      .rtl-lang & {
          background-position: right center;
      }
    }
  
    .ng-valid-min ~ & .form-tooltip__min,
    .ng-valid-number ~ & .form-tooltip__number,
    .ng-valid-low ~ & .form-tooltip__low,
    .ng-valid-up ~ & .form-tooltip__up{
        background-image: var(--icon-checked)
    }
}
