$build: 1739274406094;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-balance-filter {
  padding: 8px 20px 0;

  display: flex;
  flex-direction: column;

  .btn:first-child {
    align-self: flex-end;
  }

  .is-status .profile-balance-history__title {
    display: none;
    position: absolute;
    top: -32px;
    left: 0;
    margin-top: 0;
  }
  .linda-first-in-group .profile-balance-history__title {
    display: block;
  }

  .linda-first-in-group {
    position: relative;
    margin-top: 52px !important;
  }

  .is-status {
    display: none;
    &.linda-is-active {
      display: inline-block;
    }
  }

  .btn-tgl {
    margin: 12px 12px 0 0;
  }

}
